import React, { useState } from 'react'
import { Button, Text, TextInput } from 'components'
import { Box, useToast, Flex, useTheme } from '@chakra-ui/react'
import { DeleteServiceModal, Layout, SelectServiceMethod, UpdateInfos, UpdateValueModal } from '../Components'
import { useMutation, useQuery } from 'react-query'
import { api } from 'services/api/config'
import { formatPrice, WEEK_DAYS } from 'utils'
import { t } from 'services/translation'
import { useBarbershop } from 'services/hooks'
import { EDIT_SERVICE } from 'services/api/endpoints'

export const ServicesManagement = () => {
  const [barbershopId, setBarbershopId] = useState(null)
  const [enabled, setEnabled] = useState(false)
  const toast = useToast()
  const theme = useTheme()
  const { getServices } = useBarbershop()

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-services-internal'],
    () => getServices({ id: barbershopId }),
    {
      enabled,
    }
  )

  async function deleteService(serviceId) {
    const res = await api
      .delete(EDIT_SERVICE(serviceId, barbershopId))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation('delete-service', (serviceId) => deleteService(serviceId), {
    onSuccess: async (value) => {
      toast({
        title: t('SERVICE_DELETED'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      refetch()
    },
    onError: (error) => {
      toast({
        title: 'Erro',
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  return (
    <Layout title="Gerenciar serviços de assinatura">
      <Flex align="center" justify="center" flexDir="column" w="100%" h="100%" p="60px">
        <Flex flexDir="column" mr={theme.pxToRem(16)}>
          <Flex flexDir="row" mb={theme.pxToRem(12)}>
            <TextInput
              type="number"
              noBorder
              name="id"
              label="Id da barbearia"
              value={barbershopId}
              w={theme.pxToRem(370)}
              onChange={(event) => setBarbershopId(event?.target?.value)}
            />
            <Button
              isLoading={isLoading || isRefetching}
              mt={theme.pxToRem(26)}
              ml={theme.pxToRem(8)}
              w={theme.pxToRem(60)}
              onClick={() => {
                setEnabled(true)
              }}
              icon="Search"
              iconColor={'cardBackground'}
            />
          </Flex>
        </Flex>
        {data
          ?.filter((i) => i?.type === 'signature')
          ?.map((item) => {
            return (
              <Flex
                flexDir="column"
                p={4}
                align="flex-start"
                borderRadius={8}
                mt={2}
                borderColor={'border'}
                borderWidth={1}
                w="600px"
              >
                <Flex flexDir="column">
                  <Text>{item?.name}</Text>
                  <Text fontSize={12}>
                    ID do serviço: {item?.id} | Nome: {item?.name} | Valor: {formatPrice(item?.price)} | Visível:{' '}
                    {item?.visible_for_clients ? 'Sim' : 'Não'}
                  </Text>
                </Flex>
                <Flex mt={4}>
                  <Button
                    kind="danger"
                    onClick={() => {
                      mutateDelete.mutate(item?.id)
                    }}
                    size="x-small-auto"
                  >
                    Excluir serviço
                  </Button>
                </Flex>
              </Flex>
            )
          })}
      </Flex>
    </Layout>
  )
}

ServicesManagement.path = '/internal-adm/services'
ServicesManagement.title = 'Gerenciar serviços'
ServicesManagement.secured = true
