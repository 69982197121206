import React from 'react'
import { Text } from 'components/atoms'
import { Box, useTheme } from '@chakra-ui/react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { formatPrice } from 'utils/price'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles'

const cellColors = [...colors.pieChart]

export const MonthProductsChart = ({
  appointmentsByProduct,
  monthTotalSales,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      p={theme.pxToRem(16)}
      h="100%"
      w="100%"
      bg="cardBackground"
      borderRadius={theme.pxToRem(12)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Text kind="extraBold">{t('SERVICES_REPORT')}</Text>

      {appointmentsByProduct.length > 0 && (
        <ResponsiveContainer width="100%" h="100%">
          <PieChart width={420} height={420}>
            <text
              x="50%"
              y="48%"
              textAnchor="middle"
              dominantBaseline="middle"
              fill={colors.textLight}
              fontSize={18}
              fontFamily="Montserrat ExtraBold"
            >
              {formatPrice(monthTotalSales)}
            </text>

            <text
              x="50%"
              y="53%"
              textAnchor="middle"
              dominantBaseline="middle"
              fill={colors.textGrey}
              fontSize={14}
              fontFamily="Montserrat Medium"
            >
              {t('THIS_MONTH')}
            </text>
            <Pie
              dataKey="quantity"
              isAnimationActive={true}
              data={appointmentsByProduct}
              labelLine={false}
              cx="50%"
              cy="50%"
              innerRadius={90}
              outerRadius={140}
            >
              {appointmentsByProduct.map((item, index) => (
                <Cell
                  stroke="none"
                  fill={cellColors[index % cellColors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
    </Box>
  )
}
