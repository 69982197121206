import { t } from 'services/translation'
import * as Yup from 'yup'
import { Text } from 'components/atoms'
import { pxToRem } from 'styles/metrics'

export const PAYMENT_METHODS = [
  {
    id: 0,
    label: 'Crédito',
    icon: 'CreditCard',
    value: 'credit_card',
  },
  {
    id: 1,
    label: 'Débito',
    icon: 'CreditCard',
    value: 'debit_card',
  },
  {
    id: 2,
    label: 'Dinheiro',
    icon: 'Finances',
    value: 'cash',
  },
]

export const APPOINTMENT_DETAILS_STEPS = {
  initialStep: 'APPOINTMENT_DETAILS',
  addProducts: 'SELECT_PRODUCTS',
  selectPaymentMethod: 'SELECT_PAYMENT_METHOD',
  discount: 'ADD_DISCOUNT',
  service: 'ADD_SERVICE_BUTTON',
  editInvoice: 'EDIT_INVOICE',
  addSource: 'ADD_SOURCE',
}

export const data = [
  {
    id: 'discount_observation',
    name: 'discount_observation',
    type: 'text',
    noBorder: true,
    label: 'DISCOUNT_DESCRIPTION',
    placeholder: t('DISCOUNT_DESCRIPTION'),
  },
  {
    id: 'discount',
    name: 'discount',
    type: 'text',
    noBorder: true,
    label: 'VALUE',
    placeholder: '0,00',
    mask: 'CURRENCY_MASK',
    leftElement: (
      <Text color="textLight" kind="medium" mt={pxToRem(2)} width={pxToRem(40)}>
        {t('CURRENCY')}
      </Text>
    ),
  },
]

export const validation = Yup.object().shape({
  discount_observation: Yup.string().min(3, t('PRODUCT_NAME_SIZE')).required(t('PRODUCT_NAME_REQUIRED')),
  discount: Yup.string().required(t('PRODUCT_PRICE_REQUIRED')),
})

export const initial = {
  discount_observation: '',
  discount: '',
}
