import React, { useState, useEffect } from 'react'
import {
  Layout,
  Table,
  Text,
  FinanceResumeCard,
  MobileList,
  PaymentMethodResumeCard,
  FeatureBlockedMessage,
  PieChart,
  Icon,
  TextInput,
  Box,
  AppointmentsResumeCard,
  SelectDateButton,
} from 'components'
import { settings, settingsMobile } from './settings'
import { Center, Flex, Spinner, useToast, useTheme, Grid, HStack } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { useBreakpoint, usePremiumBlock } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import './hideScrollbar.css'

import { useQuery } from 'react-query'
import { UnitysModal } from 'components/organisms/Unitys'
import { financesStore } from 'services/stores/finances'
import { useBarbershopSubscription } from 'services/hooks'
import { columns } from './settings'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { useSignatureStore } from 'services/stores/signature'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const SignatureReport = (props) => {
  const { isDesktop } = useBreakpoint()
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const { t } = useTranslation()
  const [unityModalOpen, setUnityModalOpen] = useState(false)
  const { getSignatureResume } = useBarbershopSubscription()
  const { unity, setUnity } = financesStore()
  const cellColors = []
  const { onLogout } = useLogoutPaymentIssues()
  const { setSelectedSignature } = useSignatureStore()
  const history = useHistory()
  const [selectedDay, setSelectedDay] = useState(new Date())

  useEffect(() => {
    onLogout()
  }, [])
  const { data, isLoading, isError } = useQuery(
    ['get-signature-resume', selectedDay, userData?.barbershop?.id || userData?.id],
    getSignatureResume,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const dataPie = () => {
    let label = data?.data?.map((item) => item?.service_name)
    let d = data?.data?.map((item) => item?.active_quantity + item?.inactive_quantity)

    const values = {
      label,
      data: d,
    }

    return values
  }

  const isBlocked = usePremiumBlock()

  const onSelectRelease = (plan) => {
    setSelectedSignature(plan)
    history.push('/relatorio-detalhado-assinaturas')
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('SIGNATURE_REPORT')}
      widthTitle="100%"
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      contentPosition={isBlocked && 'relative'}
      secondActionClick={() => setUnityModalOpen(true)}
      iconSecondActionText={'Shop'}
      // subTitle={`Unidade: ${unity?.name}`}
      iconColor="black"
      secondActionText={t('CHANGE_UNITY')}
      headerCenterElement={() => (
        <SelectDateButton width="500px" h="42px" onlyMonth date={selectedDay} setDate={setSelectedDay} />
      )}
    >
      <>
        {isBlocked && <FeatureBlockedMessage title="BARBERS_REPORT_BLOCKED" section="PAYMENTS" />}

        {(!data || isLoading) && !isError ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : isDesktop ? (
          <Flex w="100%" flexDir="column" padding={isBlocked && theme.pxToRem(24)} pointerEvents={isBlocked && 'none'}>
            <Flex w="100%" flexDir="row" alignItems="stretch" justifyContent="space-between" pb={theme.pxToRem(48)}>
              <Grid templateColumns="repeat(2, 1fr)" gap={3} mr="16px">
                {settings(data)?.map((item) => (
                  <FinanceResumeCard
                    width="100%"
                    notCurrency={item?.notCurrency}
                    label={t(item?.label)}
                    description={t(item?.description)}
                    value={item?.value}
                    color={item?.color}
                    info={item?.info}
                    infoText={item?.infoText}
                  />
                ))}
                <HStack>
                  <FinanceResumeCard
                    width="100%"
                    notCurrency={true}
                    label="Nº de assinantes"
                    description="Total"
                    value={data?.subscribers_total}
                    color="white"
                    info={true}
                    infoText="Total de assinantes no mês"
                    last
                  />
                  <FinanceResumeCard
                    width="100%"
                    notCurrency={true}
                    label="Assinantes em dia"
                    description="Total"
                    value={data?.active_subscribers_total}
                    color="white"
                    info={true}
                    infoText="Total de assinantes em dia no mês"
                    last
                  />
                  <FinanceResumeCard
                    width="100%"
                    notCurrency={true}
                    label="Assinantes em atraso"
                    description="Total"
                    value={data?.inactive_subscribers_total}
                    color="white"
                    info={true}
                    infoText="Total de assinantes em atraso no mês"
                    last
                  />
                  <FinanceResumeCard
                    width="100%"
                    notCurrency={true}
                    label="Novos assinantes"
                    description="Total"
                    value={data?.new_subscribers_total}
                    color="white"
                    info={true}
                    infoText="Nº de novos assinantes no mês"
                    last
                  />
                </HStack>
              </Grid>
              <Flex h={theme.pxToRem(210)} w="43%" align="center" justify="center">
                {data?.payment_method_info?.length !== 0 ? (
                  <PieChart title="SIGNATURE_REPORT" h="100%" w="100%" data={dataPie()} />
                ) : (
                  <Flex flexDir="column" align="center">
                    <Icon name="Chart" size={40} />
                    <Text align="center">{t('NO_DATA_RESUME')}</Text>
                  </Flex>
                )}
              </Flex>
            </Flex>

            <Table
              loading={isLoading}
              data={
                data?.data?.sort((a, b) => {
                  return b?.value - a?.value
                }) || []
              }
              // isSelectableRow
              // onSelect={onSelectRelease}
              columns={columns(cellColors)}
            />
          </Flex>
        ) : (
          <Flex
            flexDirection="column"
            width="full"
            padding={isBlocked && theme.pxToRem(16)}
            pointerEvents={isBlocked && 'none'}
          >
            <Flex w="100%" flexWrap="wrap" flexDir="column" h={theme.pxToRem(116)}>
              {settingsMobile(data)?.map((item) => (
                <FinanceResumeCard
                  wMobile="50%"
                  last={item?.last}
                  notCurrency={item?.notCurrency}
                  label={t(item?.label)}
                  description={t(item?.description)}
                  value={item?.value}
                  color={item?.color}
                />
              ))}
            </Flex>

            <MobileList
              loading={isLoading}
              data={
                data?.payment_method_info?.data.sort((a, b) => {
                  return a?.quantity > b?.quantity ? -1 : a?.quantity < b?.quantity ? 1 : 0
                }) || []
              }
              renderItem={({ item, index }) => (
                <PaymentMethodResumeCard mb={16} full item={item} total={data?.payment_method_info?.total} />
              )}
            />
          </Flex>
        )}
      </>
      {unityModalOpen && (
        <UnitysModal
          isModalOpen={unityModalOpen}
          onClose={() => setUnityModalOpen(false)}
          selected={unity}
          setSelected={setUnity}
        />
      )}
    </Layout>
  )
}

SignatureReport.path = '/relatorio-assinaturas'
SignatureReport.title = 'Relatório - Assinaturas'
SignatureReport.secured = true
