import React, { useEffect, useState } from 'react'
import { Layout, Table, Box, Text, SelectFilter, Flex } from 'components'
import { BlockedTimeDetailsModal } from 'components-v2/organisms'
import { useBarbershopStore } from 'services/stores/barbershop'
import { usePermissionCollaborator } from 'services/hooks'
import { useQuery } from 'react-query'
import { formatBlockedDate } from 'utils/date'
import { t } from 'services/translation'
import { BlockedTimeModal } from 'components/organisms'
import { api } from 'services/api/config'
import { BLOCK_TIMES } from 'services/api/endpoints'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { format, parseISO } from 'date-fns'

const columns = [
  {
    key: 'id',
    label: 'ID',
    render: (item) => <Text>{item?.id}</Text>,
  },
  {
    key: 'blocked_date',
    label: 'BLOCKED_DATE',
    render: (item) => (
      <>
        <Text>{t(formatBlockedDate(item))}</Text>
      </>
    ),
  },
  {
    key: 'hour',
    label: 'PERIOD',
    render: (item) => (
      <Text>
        {item?.start_hour.substring(0, 5)} - {item?.finish_hour.substring(0, 5)}
      </Text>
    ),
  },
  {
    key: 'barber',
    label: 'BARBER',
    render: (item) => (
      <Text>
        {t('BARBER')}: {item?.barber?.name || t('EVERYBODY')}
      </Text>
    ),
  },
  {
    key: 'blocked_date',
    label: 'DESCRIPTION_',
    render: (item) => (
      <>
        <Text>{item?.title || '-'}</Text>
      </>
    ),
  },
  {
    key: 'created_at',
    label: 'DATA DE CRIAÇÃO',
    render: (item) => <Text>{format(parseISO(item?.createdAt), 'dd/MM/yyyy')}</Text>,
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => (
      <Text color={item?.inactive ? 'danger' : 'success'}>{item?.inactive ? t('Inativo') : t('Ativo')}</Text>
    ),
  },
]

export const BlockedTimesScreen = ({ ...props }) => {
  const [isModalAddOpen, setIsModalAddOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [status, setStatus] = useState('active')
  const permission = usePermissionCollaborator('blocked_hours')
  const { userData } = useBarbershopStore()

  async function getBlockedTimes() {
    const res = await api
      .get(BLOCK_TIMES('', userData?.barbershop?.id || userData?.id, status === 'active' ? false : true))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, isLoading, refetch, isRefetching } = useQuery(['get-blocked-times', status], getBlockedTimes)

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const onClose = () => {
    setIsModalAddOpen(false)
    refetch()
  }

  return (
    <Layout
      {...props}
      headerTitle={t('BLOCKED_HOURS')}
      modalFilter
      modalFilterWidth="12%"
      headerActionText={permission === 'edit' ? t('ADD') : null}
      headerActionClick={() => setIsModalAddOpen(true)}
    >
      <Flex mb={4}>
        <SelectFilter
          fullWidth
          selW={180}
          withoutPlaceholder
          label="Status do Bloqueio"
          options={[
            { value: 'active', name: 'Ativo' },
            { value: 'inactive', name: 'Inativo (Excluídos)' },
          ]}
          value={status}
          onChange={(e) => {
            setStatus(e)
          }}
        />
      </Flex>
      <Box flex={1}>
        <Table
          isSelectableRow
          data={data?.filter((i) => !i?.past)?.sort((a, b) => b?.id - a?.id) || []}
          loading={isLoading || isRefetching}
          columns={columns}
          onSelect={(item) => {
            setSelectedItem(item)
          }}
        />

        <BlockedTimeModal isModalOpen={isModalAddOpen} onClose={onClose} />

        {!!selectedItem && (
          <BlockedTimeDetailsModal
            selectedItem={selectedItem}
            onClose={(close_info) => {
              setSelectedItem(null)
              if (close_info?.refetch) {
                refetch()
              }
            }}
          />
        )}
      </Box>
    </Layout>
  )
}

BlockedTimesScreen.path = '/horarios-bloqueados'
BlockedTimesScreen.title = 'Horarios Bloqueados'
BlockedTimesScreen.secured = true
