import { useEffect, useState } from 'react'
import { useTheme, TabList, Tab, TabPanels, TabPanel, Tabs } from '@chakra-ui/react'
import { Layout, SubscriptionDetailModal } from 'components'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { GET_BARBERSHOP_SUBSCRIPTION } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { CreatePlanModal } from 'components-v2/organisms/CreatePlanModal'
import { AddNewSubscriber } from 'components-v2/organisms/AddNewSubscriber'
import { AllSubscribers } from './AllSubscribers'
import { NewSubscribers } from './NewSubscribers'

export const NewSubscribersScreen = ({ ...props }) => {
  const [modalDetailOpen, setModalDetailOpen] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [addSubscriber, setAddSubscriber] = useState(false)
  const [plan, setPlan] = useState(null)
  const [client, setClient] = useState()
  const [status, setStatus] = useState()
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  const { onLogout } = useLogoutPaymentIssues()
  const { userData } = useBarbershopStore()

  useEffect(() => {
    onLogout()
  }, [])

  async function getManagerSubscription({ queryKey }) {
    const res = await api
      .get(GET_BARBERSHOP_SUBSCRIPTION(userData?.id, queryKey[1]?.name))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { isLoading, refetch, data } = useQuery(['get-subscribers', status], getManagerSubscription, {
    // onSuccess: (value) => {
    //   setSubscribers({ data: value, filtered: value })
    // },
  })

  const onCloseModal = () => {
    refetch()
    setClient()
    setModalDetailOpen(false)
  }

  const handleSelectCustomer = (client) => {
    setClient(client)
    setModalDetailOpen(true)
  }

  return (
    <Layout
      {...props}
      headerTitle="Clube de assinatura"
      modalFilter
      modalFilterWidth="12%"
      secondActionClick={() => setAddSubscriber(true)}
      secondActionText="Cadastrar novo assinante"
      // thirtyClick={{
      //   icon: 'Excel',
      //   iconColor: 'black',
      //   onClick: handleExport,
      //   text: 'Exportar em excel',
      // }}
      // secondActionClick={handleExport}
      // secondActionText="Exportar em excel"
      // iconSecondActionText={'Excel'}
    >
      <>
        <Tabs w="100%" onChange={(index) => {}}>
          <TabList color="textGrey" borderColor="modalBorder" borderBottomWidth={4}>
            <Tab
              w="50%"
              fontSize={14}
              fontWeight={900}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              Todos os assinantes
            </Tab>
            <Tab
              w="50%"
              fontSize={14}
              fontWeight={900}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              Novos assinantes
            </Tab>
          </TabList>

          <TabPanels p={0}>
            <TabPanel p={0}>
              <AllSubscribers
                handleSelectCustomer={handleSelectCustomer}
                isLoading={isLoading}
                data={data}
                setStatus={setStatus}
                status={status}
              />
            </TabPanel>
            <TabPanel p={0}>
              <NewSubscribers handleSelectCustomer={handleSelectCustomer} isLoading={isLoading} data={data} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>

      <CreatePlanModal
        isOpen={planModalOpen}
        onClose={() => {
          setPlan(null)
          setPlanModalOpen(false)
        }}
        plan={plan}
      />
      <AddNewSubscriber isOpen={addSubscriber} onClose={() => setAddSubscriber(false)} />

      <SubscriptionDetailModal
        isOpen={modalDetailOpen}
        onClose={onCloseModal}
        signature_id={client?.id}
        barbershop_id={client?.barbershop_id}
        client_id={client?.client_id}
      />
    </Layout>
  )
}

NewSubscribersScreen.path = '/assinantes-novo'
NewSubscribersScreen.title = 'Assinantes'
NewSubscribersScreen.secured = true
