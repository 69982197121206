import { api } from 'services/api/config'

export const useAppointmentDetails = () => {
  const getAppointmentDetails = async (props) => {
    const response = await api({
      url: `v3/appointments/details/${props.id}`,
    })
    return response.data
  }

  const getRecurrentAppointmentDetails = async (props) => {
    const response = await api({
      url: `v3/appointments/recurrent-details/${props.id}`,
    })
    return response.data
  }

  return {
    getRecurrentAppointmentDetails,
    getAppointmentDetails,
  }
}
