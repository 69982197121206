import React from 'react'
import { Flex, Table, Text } from 'components'
import { useAppointmentDetailStore, useAppointmentStore } from 'services/stores'
import { useAppointment } from 'services/hooks'
import { useMutation, useQueryClient } from 'react-query'

const columns = [
  {
    key: 'source',
    label: 'SELECT_SOURCE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item.name}
      </Text>
    ),
  },
]

export const AppointmentSelectSource = ({ goBack, setEnabled }) => {
  const { sourcesList, loading, setSource } = useAppointmentStore()
  const { selectedAppointment } = useAppointmentDetailStore()
  const { updateAppointmentSource } = useAppointment()
  const queryClient = useQueryClient()

  const mutate = useMutation('add-service-appointment', (params) => updateAppointmentSource(params), {
    onSuccess: () => {
      goBack?.()
    },
    onSettled: () => {
      setEnabled(true)
      queryClient.invalidateQueries(['get-appointment-details', selectedAppointment?.id])
    },
  })

  const handleSelectSource = (source) => {
    setSource(source)
    const params = {
      appointment_id: selectedAppointment?.id,
      source: source?.value,
    }
    mutate.mutate({ params })
  }

  return (
    <Flex flexDirection="column">
      <Table
        isSelectableRow
        data={sourcesList || []}
        loading={loading}
        columns={columns}
        onSelect={handleSelectSource}
      />
    </Flex>
  )
}
