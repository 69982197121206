import { useFetch } from './use-fetch'
import { format } from 'date-fns'
import {
  GET_AVAILABLE_TIME,
  CREATE_APPOINTMENT,
  CREATE_RECURRENT_APPOINTMENT,
  UPDATE_APPOINTMENT,
  GET_ADD_ADDITIONAL_PRODUCTS_APPOINTMENT,
  REMOVE_ADDITIONAL_PRODUCT_APPOINTMENT,
  UPDATE_APPOINTMENT_STATUS,
  GET_APPOINTMENTS_BY_DATE,
  CANCEL_RECURRENT_APPOINTMENT,
  DELETE_RECURRENT,
  CONFIRM_RECURRENT,
  UPDATE_APPOINTMENT_HOUR_AND_BARBER,
  CREATE_PACKAGE_SERVICE,
  ADD_SERVICES_APPOINTMENT,
  DELETE_SERVICES_APPOINTMENT,
  GET_SERVICES_APPOINTMENT,
  UPDATE_SERVICES_APPOINTMENT,
  UPDATE_APPOINTMENT_SOURCE,
} from 'services/api/endpoints'

export const useAppointment = () => {
  const { onFetch } = useFetch()

  async function getAvailableTime(props) {
    const response = await onFetch({
      ...props,
      url: GET_AVAILABLE_TIME,
      method: 'post',
      message: {
        error: 'ERROR_LOADING_TIMES',
      },
    })
    return response
  }

  async function newAppointment(props) {
    const response = await onFetch({
      ...props,
      url: CREATE_APPOINTMENT,
      method: 'post',
      message: {
        error: 'APPOINTMENT_ERROR_HOUR',
        success: 'APPOINTMENT_CREATED_SUCCESSFULLY',
      },
    })
    return response
  }

  async function newPackageService(props) {
    const response = await onFetch({
      ...props,
      url: CREATE_PACKAGE_SERVICE,
      method: 'post',
      message: {
        error: 'Erro ao registrar venda do pacote de serviço',
        success: 'Venda cadastrada com sucesso',
      },
    })
    return response
  }

  async function newRecurrentAppointment(props) {
    const response = await onFetch({
      ...props,
      url: CREATE_RECURRENT_APPOINTMENT,
      method: 'post',
      message: {
        error: 'APPOINTMENT_ERROR_HOUR',
        success: 'APPOINTMENT_CREATED_SUCCESSFULLY',
      },
    })
    return response
  }
  async function editAppointment(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_APPOINTMENT,
      method: 'put',
      message: {
        error: 'EDIT_APPOINTMENT_ERROR',
        success: 'EDIT_APPOINTMENT_SUCCESS',
      },
    })
    return response
  }

  async function getAppointmentProducts(props) {
    if (!props?.appointment_id) {
      throw new Error('Parâmetro não informado')
    }
    const response = await onFetch({
      ...props,
      url: GET_ADD_ADDITIONAL_PRODUCTS_APPOINTMENT(props?.appointment_id),
      method: 'get',
      message: {
        error: 'ERROR_LOADING_PRODUCTS',
      },
    })
    return response
  }

  async function removeAppointmentProduct(props) {
    const response = await onFetch({
      ...props,
      url: REMOVE_ADDITIONAL_PRODUCT_APPOINTMENT(props?.appointment_product_id),
      method: 'delete',
      message: {
        error: 'Erro ao remover produto',
      },
    })
    return response
  }

  async function updateAppointmentStatus(props) {
    if (!props?.appointment_id) {
      throw new Error('Parâmetro não fornecido')
    }
    const response = await onFetch({
      ...props,
      url: UPDATE_APPOINTMENT_STATUS(props?.appointment_id, props?.status),
      method: 'put',
      message: {
        success: 'Status do agendamento atualizado com sucesso!',
        error: 'Erro ao atualizar status do agendamento',
      },
    })
    return response
  }

  async function getAppointments(props) {
    const response = await onFetch({
      ...props,
      url: GET_APPOINTMENTS_BY_DATE(format(props?.date, 'yyyy-MM-dd'), props?.showBlockedTimes, props?.barbershop_id),
      method: 'get',
      message: {
        error: 'APPOINTMENT_ERROR',
      },
    })
    return response
  }

  async function cancelRecurrent(props) {
    const response = await onFetch({
      url: CANCEL_RECURRENT_APPOINTMENT(props?.id),
      ...props,
      method: 'put',
      message: {
        success: 'Agendamenot cancelado com successo',
        error: 'Erro ao cancelar agendamento',
      },
    })
    return response
  }

  async function deleteRecurrent(props) {
    const response = await onFetch({
      url: DELETE_RECURRENT(props?.id),
      method: 'delete',
      message: {
        success: 'Recorrência apagada com successo',
        error: 'Erro ao apagar recorrência',
      },
    })
    return response
  }

  async function confirmRecurrent(props) {
    const response = await onFetch({
      url: CONFIRM_RECURRENT,
      ...props,
      method: 'post',
      message: {
        success: 'Agendamento confirmado com sucesso',
        error: 'Erro ao confirmar agendamento',
      },
    })
    return response
  }

  async function updateAppointmentHourAndBarber(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_APPOINTMENT_HOUR_AND_BARBER(props?.appointment_id),
      method: 'put',
      message: {
        success: 'Horário do agendamento atualizado com sucesso!',
        error: 'Erro ao atualizar horário do agendamento',
      },
    })
    return response
  }

  async function addServiceAppointment(props) {
    const response = await onFetch({
      ...props,
      url: ADD_SERVICES_APPOINTMENT,
      method: 'post',
      message: {
        error: 'Erro ao adicionar serviço',
        success: 'Serviço adicionado com sucesso',
      },
    })
    return response
  }

  async function deleteServiceAppointment(props) {
    const response = await onFetch({
      ...props,
      url: DELETE_SERVICES_APPOINTMENT(props?.id),
      method: 'delete',
      message: {
        error: 'Erro ao excluir serviço',
        success: 'Serviço excluido com sucesso',
      },
    })
    return response
  }

  async function getServiceAppointment(props) {
    if (!props?.appointment_id) {
      throw new Error('Parâmetro não informado')
    }
    const response = await onFetch({
      ...props,
      url: GET_SERVICES_APPOINTMENT(props?.appointment_id),
      method: 'get',
    })
    return response
  }

  async function updateServiceAppointment(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_SERVICES_APPOINTMENT,
      method: 'put',
      message: {
        error: 'Erro ao alterar valor',
        success: 'Valor alterado com sucesso',
      },
    })
    return response
  }

  async function updateAppointmentSource(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_APPOINTMENT_SOURCE(props?.params?.appointment_id),
      method: 'put',
      message: {
        error: 'Erro ao alterar fonte',
        success: 'Fonte alterada com sucesso',
      },
    })
    return response
  }

  async function deleteAppointmentSource(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_APPOINTMENT_SOURCE(props?.appointment_id),
      method: 'delete',
      message: {
        error: 'Erro ao alterar fonte',
        success: 'Fonte alterada com sucesso',
      },
    })
    return response
  }

  return {
    updateAppointmentSource,
    deleteAppointmentSource,
    updateServiceAppointment,
    addServiceAppointment,
    deleteServiceAppointment,
    getServiceAppointment,
    updateAppointmentHourAndBarber,
    newPackageService,
    confirmRecurrent,
    deleteRecurrent,
    cancelRecurrent,
    getAppointments,
    updateAppointmentStatus,
    getAvailableTime,
    newAppointment,
    newRecurrentAppointment,
    editAppointment,
    getAppointmentProducts,
    removeAppointmentProduct,
  }
}
