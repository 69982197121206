import React, { useMemo, useState } from 'react'
import { Box, Flex, Text, Button } from 'components/atoms'
import { ModalAlert, ModalMenu } from 'components/molecules'
import { t } from 'services/translation'
import { useBlockedTime } from 'services/hooks/use-blocked-time'
import { formatBlockedDate } from 'utils/date'
import { useQuery, useMutation } from 'react-query'
import { format, parseISO } from 'date-fns'
import { useToast } from '@chakra-ui/react'

export const BlockedTimeDetailsModal = ({ onClose, selectedItem }) => {
  const [isAlertOpen, setIsAlertOpen] = useState()
  const { getBlockedTimeDetails, deleteBlockedTimePermanent } = useBlockedTime()
  const toast = useToast()
  const { data, isLoading } = useQuery(['blocked-time-details'], () =>
    getBlockedTimeDetails({ blocked_id: selectedItem?.id })
  )
  const mutate = useMutation(
    'delete-blocked-time-permanent',
    () => deleteBlockedTimePermanent({ blocked_id: selectedItem?.id }),
    {
      onSuccess: () => {
        toast({
          title: 'Bloqueio excluído com sucesso',
          status: 'success',
        })
        onClose({ refetch: true })
      },
      onError: (error) => {
        toast({
          title: t('ERROR_DELETE_DATA'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const item = useMemo(() => {
    return data?.data
  }, [data])

  const getModifyTitle = (log) => {
    const modify = log?.modify
    if (modify === 'delete_block_times') {
      return 'Exclusão de bloqueio permanente'
    }
    if (modify === 'exception_block_times') {
      return 'Exclusão do bloqueio apenas em data específica'
    }
    if (!modify) {
      return 'Criação de bloqueio'
    }
    return ''
  }

  return (
    <ModalMenu size="lg" isOpen onClose={onClose} title={t('Detalhes do Bloqueio de Horário')}>
      {isLoading ? (
        'Carregando'
      ) : (
        <Box pb={24}>
          <Flex>
            <Flex alignItems="center" bg={'component'} borderRadius={16} pt={1} pb={1} pl={4} pr={4} ml={-1}>
              <Text fontSize={15} fontWeight="semiBold">
                Status do bloqueio:
              </Text>
              <Text fontSize={16} ml={1} fontWeight="bold" color={item?.inactive ? 'danger' : 'success'}>
                {item?.inactive ? t('Inativo') : t('Ativo')}
              </Text>
            </Flex>
          </Flex>
          <Flex alignItems="center" mt={2}>
            <Text fontSize={14} fontWeight="semiBold">
              ID do Bloqueio: {item?.id}
            </Text>
            <Text ml={3} fontSize={14} fontWeight="semiBold">
              Data de criação: {format(parseISO(item?.createdAt), 'dd/MM/yyyy')}
            </Text>
          </Flex>
          <Flex>
            <Box
              mr="2%"
              w="49%"
              mt={4}
              p={4}
              border="1px solid border"
              borderRadius={12}
              bg="background2"
              shadow="0px 0px 4px #4E535A"
            >
              <Text fontSize={14} fontWeight="semiBold">
                Barbeiro:
              </Text>
              <Text fontWeight="bold">{item?.all_barbers ? t('Todos') : item?.barber?.name}</Text>
            </Box>

            <Box
              w="49%"
              mt={4}
              p={4}
              border="1px solid border"
              borderRadius={12}
              bg="background2"
              shadow="0px 0px 4px #4E535A"
            >
              <Text fontSize={14} fontWeight="semiBold">
                Descrição:
              </Text>
              <Text fontWeight="bold"> {item?.title || 'Sem descrição'}</Text>
            </Box>
          </Flex>

          <Box mt={4} p={4} border="1px solid border" borderRadius={12} bg="background2" shadow="0px 0px 8px #4E535A">
            <Text fontSize={14} fontWeight="semiBold">
              Horário bloqueado:
            </Text>
            <Text fontWeight="bold">
              {t(formatBlockedDate(item))} de {item?.start_hour.substring(0, 5)} às {item?.finish_hour.substring(0, 5)}
            </Text>
          </Box>

          {data?.logs?.length > 0 && (
            <Text mt={4} fontWeight="bold">
              Modificações (Logs):
            </Text>
          )}

          {data?.logs
            ?.sort((a, b) => parseISO(b.date_time) - parseISO(a.date_time))
            ?.map((log) => (
              <Box
                mt={4}
                p={4}
                border="1px solid border"
                borderRadius={12}
                bg="background2"
                shadow="0px 0px 4px #4E535A"
              >
                <Text fontSize={14} fontWeight="semiBold">
                  Usuário que fez a alteração: {log?.user_email}
                </Text>
                <Text fontSize={14} fontWeight="semiBold">
                  Data da alteração: {format(parseISO(log?.date_time), 'dd/MM/yyyy')} às{' '}
                  {format(parseISO(log?.date_time), 'HH:mm')}
                </Text>
                <Text fontSize={14} fontWeight="semiBold">
                  Tipo de alteração: {getModifyTitle(log)}
                </Text>
                {log?.modify === 'exception_block_times' && (
                  <>
                    <Text fontSize={14} fontWeight="bold">
                      Exclusão específica para o dia {format(parseISO(log?.body?.date), 'dd/MM/yy')}
                    </Text>
                    <Text fontSize={14} fontWeight="semiBold">
                      Exclusão apenas para o barbeiro: {log?.modify_barber_name} (id: {log?.modify_barber_id})
                    </Text>
                  </>
                )}
              </Box>
            ))}

          {!item?.inactive && (
            <Flex
              borderTopColor="grey"
              borderTopWidth="1px"
              ml="-24px"
              position="absolute"
              bottom={0}
              bg="background"
              h="110px"
              w="100%"
              p="20px"
              py="24px"
            >
              <Button ml="12px" size="block" kind="danger" onClick={() => setIsAlertOpen(true)}>
                {t('Excluir bloqueio permanentemente')}
              </Button>
            </Flex>
          )}
          <ModalAlert
            isOpen={isAlertOpen}
            onClose={() => {
              setIsAlertOpen(false)
            }}
            primaryButtonLabel="Confirmar"
            modalTitle={t('Deseja excluir este bloqueio permanentemente?')}
            onConfirm={mutate.mutate}
            loading={mutate.isLoading}
          >
            Esta ação não poderá ser desfeita
          </ModalAlert>
        </Box>
      )}
    </ModalMenu>
  )
}
