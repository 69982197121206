import React, { useState, useEffect } from 'react'
import { Box, Text, Button, TextInput, Flex } from 'components/atoms'
import { FieldControl, SelectCard, InputImageField, InputFileField } from 'components/molecules'
import { isWithinInterval, parseISO } from 'date-fns'

import { useTranslation } from 'react-i18next'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Select, VStack, Collapse, HStack, Divider, useToast } from '@chakra-ui/react'
import { useInvoiceIssuer } from 'services/hooks'

import { useQuery, useMutation } from 'react-query'

import { getData } from 'services/storage'

const validationSchema = Yup.object().shape({
  active: Yup.boolean(),
  nome: Yup.string().required('Este campo é obrigatório'),
  nome_fantasia: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Este campo é obrigatório'),
  telefone: Yup.string().required('Este campo é obrigatório'),
  cnpj: Yup.string().required('Este campo é obrigatório'),
  inscricao_estadual: Yup.string(),
  inscricao_municipal: Yup.string(),
  cpf_cnpj_contabilidade: Yup.string().required('Este campo é obrigatório'),
  nome_responsavel: Yup.string().required('Este campo é obrigatório'),
  cpf_responsavel: Yup.string().required('Este campo é obrigatório'),
  regime_tributario: Yup.string().required('Este campo é obrigatório'),
  uf: Yup.string().required('Este campo é obrigatório'),
  municipio: Yup.string().required('Este campo é obrigatório'),
  codigo_municipio: Yup.string().required('Este campo é obrigatório'),
  cep: Yup.string().required('Este campo é obrigatório'),
  bairro: Yup.string().required('Este campo é obrigatório'),
  logradouro: Yup.string().required('Este campo é obrigatório'),
  numero: Yup.string().required('Este campo é obrigatório'),
  complemento: Yup.string(),
  arquivo_logo_base64: Yup.object().test('test', 'Este campo é obrigatório', (field) => !!field?.url),
  csc_nfce_producao: Yup.string(),
  id_token_nfce_producao: Yup.string(),
  aliquota_iss: Yup.number(),
  codigo_tributario_municipio: Yup.string(),
  isValideCertificate: Yup.boolean(),
  arquivo_certificado_base64: Yup.object().test(
    'test',
    'Este campo é obrigatório',
    (field, context) => field?.url || context.parent.isValideCertificate
  ),
  senha_certificado: Yup.string().test(
    'test',
    'Este campo é obrigatório',
    (field, context) => field || context.parent.isValideCertificate
  ),
})

export const InvoiceIssuer = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const { getAllInvoiceIssuer, createInvoiceIssuer, updateInvoiceIssuer } = useInvoiceIssuer()
  const [userLogged, setUserLogged] = useState({})

  useEffect(() => {
    const initUserLogged = async () => {
      const user = await getData('@BestBarbers:user')
      const userData = await getData('@BestBarbers:userData')
      setUserLogged({ ...user, barbershop_id: userData?.barbershop_id || userData?.id, userData })
    }
    initUserLogged()
  }, [])

  const invoiceIssuerQuery = useQuery(
    ['invoice-issuers', userLogged?.id],
    () => getAllInvoiceIssuer({ barbershop_id: userLogged?.barbershop_id }),
    {
      enabled: !!userLogged?.id,
      select: (res) => {
        const data = res.data[0]

        const isValideCertificate =
          data?.api_metadata?.certificado_valido_de &&
          isWithinInterval(new Date(), {
            start: parseISO(data.api_metadata.certificado_valido_de),
            end: parseISO(data.api_metadata.certificado_valido_ate),
          })

        return { ...data, isValideCertificate }
      },
    }
  )

  const invoiceIssuerMutation = useMutation(
    'invoice-issuer',
    (data) =>
      invoiceIssuerQuery.data?.id
        ? updateInvoiceIssuer({ id: invoiceIssuerQuery.data.id, ...data })
        : createInvoiceIssuer(data),
    {
      onSuccess: () => {
        toast({
          title: 'Empresa emissora de nota fiscais salva com sucesso!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          title: 'Erro ao salvar empresa emissora de nota fiscais!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const formik = useFormik({
    initialValues: { active: false },
    validationSchema,
    onSubmit: () => {
      const { arquivo_logo_base64, isValideCertificate, arquivo_certificado_base64, senha_certificado, ...formData } =
        formik.values

      const newInvoiceIssuerData = {
        user_id: userLogged.id,
        barbershop_id: userLogged?.barbershop_id,
        ...formData,
        ...(arquivo_logo_base64.url.startsWith('data:image') && {
          arquivo_logo_base64: arquivo_logo_base64.url,
        }),
        ...(!isValideCertificate && {
          arquivo_certificado_base64: arquivo_certificado_base64.url,
          senha_certificado,
        }),
      }

      invoiceIssuerMutation.mutate(newInvoiceIssuerData)
    },
  })

  useEffect(() => {
    const initFormikValues = () => {
      if (!invoiceIssuerQuery.data) return

      const data = invoiceIssuerQuery.data

      formik.setValues({
        active: data.active ?? false,
        nome: data.nome ?? '',
        nome_fantasia: data.nome_fantasia ?? '',
        email: data.email ?? '',
        telefone: data.telefone ?? '',
        cnpj: data.cnpj ?? '',
        inscricao_estadual: data.inscricao_estadual ?? '',
        inscricao_municipal: data.inscricao_municipal ?? '',
        accountingType: data.cpf_cnpj_contabilidade?.replace(/\D/g, '').length === 14 ? 'PJ' : 'PF',
        cpf_cnpj_contabilidade: data.cpf_cnpj_contabilidade ?? '',
        nome_responsavel: data.nome_responsavel ?? '',
        cpf_responsavel: data.cpf_responsavel ?? '',
        regime_tributario: data.regime_tributario ?? '1',
        uf: data.uf ?? 'AC',
        municipio: data.municipio ?? '',
        codigo_municipio: data.codigo_municipio ?? '',
        cep: data.cep ?? '',
        bairro: data.bairro ?? '',
        logradouro: data.logradouro ?? '',
        numero: data.numero ?? '',
        complemento: data.complemento ?? '',
        arquivo_logo_base64: {
          url: data.logo_url ?? '',
          file: null,
        },
        aliquota_iss: data.aliquota_iss ?? '',
        codigo_tributario_municipio: data.codigo_tributario_municipio ?? '',
        id_token_nfce_producao: data.id_token_nfce_producao ?? '',
        csc_nfce_producao: data.csc_nfce_producao ?? '',
        isValideCertificate: data.isValideCertificate ?? false,
        nfse_emission: data.nfse_emission ?? false,
      })
    }
    initFormikValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceIssuerQuery.data, formik.setValues])

  return (
    <Box color="textLight" p={12} pt={0}>
      <Text fontSize={22} kind="bold">
        {t('Configurações - Emissão de Nota Fiscal')}
      </Text>
      <Flex mt={4}>
        {/* <SelectCard
          isLoading={invoiceIssuerQuery.isFetching}
          item={{ title: 'Habilitar emissão de nota fiscal' }}
          setSelected={(ev) => formik.setFieldValue('active', ev)}
          selected={formik.values.active}
          w="50%"
          minW={350}
          h={20}
        /> */}
        <Text kind="medium">{t('SETTINGS_INVOICE_ISSUER_DESC')}</Text>
      </Flex>
      <Collapse in={true} animateOpacity>
        <VStack spacing={4} align="normal">
          <HStack spacing={4} pt={8}>
            <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
              Dados da Empresa
            </Text>
            <Divider borderColor="gray.600" />
          </HStack>
          <HStack spacing={6} align="start">
            <FieldControl
              label="Nome"
              error={formik.errors.nome}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => <TextInput name="nome" value={formik.values.nome} onChange={formik.handleChange} />}
            />

            <FieldControl
              label="Nome fantasia"
              error={formik.errors.nome_fantasia}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput name="nome_fantasia" value={formik.values.nome_fantasia} onChange={formik.handleChange} />
              )}
            />
          </HStack>
          <HStack spacing={6} align="start">
            <FieldControl
              label="E-mail"
              error={formik.errors.email}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput name="email" type="email" value={formik.values.email} onChange={formik.handleChange} />
              )}
            />

            <FieldControl
              label="Telefone"
              error={formik.errors.telefone}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput
                  name="telefone"
                  mask="PHONE_MASK"
                  borderColor={formik.errors.telefone ? 'red' : 'gray'}
                  value={formik.values.telefone}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
          <HStack spacing={6} align="start">
            <FieldControl
              label="CNPJ"
              error={formik.errors.cnpj}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <TextInput
                  name="cnpj"
                  mask="CNPJ_MASK"
                  error={!!error}
                  value={formik.values.cnpj}
                  onChange={formik.handleChange}
                />
              )}
            />

            <FieldControl
              label="Regime tributário"
              error={formik.errors.regime_tributario}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <Select
                  name="regime_tributario"
                  size="lg"
                  bg="backgroundLayout"
                  borderColor="gray.800"
                  h="52px"
                  value={formik.values.regime_tributario}
                  onChange={formik.handleChange}
                >
                  <option value="1">Simples Nacional</option>
                  <option value="2">Simples Nacional - Excesso de sublimite de receita bruta</option>
                  <option value="3">Regime Normal</option>
                </Select>
              )}
            />
          </HStack>
          <HStack spacing={6} align="start">
            <FieldControl
              label="Tipo de contabilidade"
              error={formik.errors.accountingType}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <Select
                  name="accountingType"
                  size="lg"
                  bg="backgroundLayout"
                  borderColor="gray.800"
                  h="52px"
                  value={formik.values.accountingType}
                  onChange={formik.handleChange}
                >
                  <option value="PJ">Pessoa Jurídica</option>
                  <option value="PF">Pessoa Física</option>
                </Select>
              )}
            />

            <FieldControl
              label={`
                ${formik.values.accountingType === 'PF' ? 'CPF' : 'CNPJ'} da contabilidade`}
              error={formik.errors.cpf_cnpj_contabilidade}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <TextInput
                  name="cpf_cnpj_contabilidade"
                  mask={formik.values.accountingType === 'PF' ? 'CPF_MASK' : 'CNPJ_MASK'}
                  error={!!error}
                  value={formik.values.cpf_cnpj_contabilidade}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
          <HStack spacing={6} align="start">
            <FieldControl
              label="Inscrição estadual"
              error={formik.errors.inscricao_estadual}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <TextInput
                  name="inscricao_estadual"
                  mask="NUMBER_MASK"
                  error={!!error}
                  value={formik.values.inscricao_estadual}
                  onChange={formik.handleChange}
                />
              )}
            />

            <FieldControl
              label="Inscrição municipal"
              error={formik.errors.inscricao_municipal}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <TextInput
                  name="inscricao_municipal"
                  mask="NUMBER_MASK"
                  error={!!error}
                  value={formik.values.inscricao_municipal}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
          <HStack spacing={6} align="start">
            <FieldControl
              label="Nome do responsável"
              error={formik.errors.nome_responsavel}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput
                  name="nome_responsavel"
                  value={formik.values.nome_responsavel}
                  onChange={formik.handleChange}
                />
              )}
            />

            <FieldControl
              label="CPF do responsável"
              error={formik.errors.cpf_responsavel}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <TextInput
                  name="cpf_responsavel"
                  mask="CPF_MASK"
                  error={!!error}
                  value={formik.values.cpf_responsavel}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
          <HStack spacing={4} pt={4}>
            <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
              Endereço
            </Text>
            <Divider borderColor="gray.600" />
          </HStack>
          <HStack spacing={6} align="start">
            <FieldControl
              label="CEP"
              error={formik.errors.cep}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <TextInput
                  name="cep"
                  mask="CEP_MASK"
                  error={!!error}
                  value={formik.values.cep}
                  onChange={formik.handleChange}
                />
              )}
            />

            <FieldControl
              label="UF"
              error={formik.errors.uf}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <Select
                  name="uf"
                  size="lg"
                  bg="backgroundLayout"
                  borderColor="gray.800"
                  h="52px"
                  value={formik.values.uf}
                  onChange={formik.handleChange}
                >
                  <option value="AC">Acre (AC)</option>
                  <option value="AL">Alagoas (AL)</option>
                  <option value="AP">Amapá (AP)</option>
                  <option value="AM">Amazonas (AM)</option>
                  <option value="BA">Bahia (BA)</option>
                  <option value="CE">Ceará (CE)</option>
                  <option value="DF">Distrito Federal (DF)</option>
                  <option value="ES">Espírito Santo (ES)</option>
                  <option value="GO">Goiás (GO)</option>
                  <option value="MA">Maranhão (MA)</option>
                  <option value="MT">Mato Grosso (MT)</option>
                  <option value="MS">Mato Grosso do Sul (MS)</option>
                  <option value="MG">Minas Gerais (MG)</option>
                  <option value="PA">Pará (PA)</option>
                  <option value="PB">Paraíba (PB)</option>
                  <option value="PR">Paraná (PR)</option>
                  <option value="PE">Pernambuco (PE)</option>
                  <option value="PI">Piauí (PI)</option>
                  <option value="RJ">Rio de Janeiro (RJ)</option>
                  <option value="RN">Rio Grande do Norte (RN)</option>
                  <option value="RS">Rio Grande do Sul (RS)</option>
                  <option value="RO">Rondônia (RO)</option>
                  <option value="RR">Roraima (RR)</option>
                  <option value="SC">Santa Catarina (SC)</option>
                  <option value="SP">São Paulo (SP)</option>
                  <option value="SE">Sergipe (SE)</option>
                  <option value="TO">Tocantins (TO)</option>
                </Select>
              )}
            />
          </HStack>
          <HStack spacing={6} align="start">
            <FieldControl
              label="Município"
              error={formik.errors.municipio}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput name="municipio" value={formik.values.municipio} onChange={formik.handleChange} />
              )}
            />
            <FieldControl
              label="Código do município"
              error={formik.errors.codigo_municipio}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <TextInput
                  name="codigo_municipio"
                  mask="NUMBER_MASK"
                  error={!!error}
                  value={formik.values.codigo_municipio}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
          <FieldControl
            label="Bairro"
            error={formik.errors.bairro}
            isLoading={invoiceIssuerQuery.isLoading}
            render={() => <TextInput name="bairro" value={formik.values.bairro} onChange={formik.handleChange} />}
          />
          <HStack spacing={6} align="start">
            <FieldControl
              flex={3}
              label="Logradouro"
              error={formik.errors.logradouro}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput name="logradouro" value={formik.values.logradouro} onChange={formik.handleChange} />
              )}
            />
            <FieldControl
              flex={1}
              label="Número"
              error={formik.errors.numero}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <TextInput
                  name="numero"
                  mask="NUMBER_MASK"
                  error={!!error}
                  value={formik.values.numero}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
          <FieldControl
            label="Complemento"
            error={formik.errors.complemento}
            isLoading={invoiceIssuerQuery.isLoading}
            render={() => (
              <TextInput name="complemento" value={formik.values.complemento} onChange={formik.handleChange} />
            )}
          />
          <HStack spacing={4} pt={4}>
            <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
              Anexos
            </Text>
            <Divider borderColor="gray.600" />
          </HStack>
          <FieldControl
            label="Logo"
            error={formik.errors.arquivo_logo_base64}
            isLoading={invoiceIssuerQuery.isLoading}
            render={({ error }) => (
              <InputImageField
                name="arquivo_logo_base64"
                value={formik.values.arquivo_logo_base64}
                error={!!error}
                onChange={(file) => formik.setFieldValue('arquivo_logo_base64', file)}
              />
            )}
          />

          <HStack spacing={6} align="start">
            <FieldControl
              label="Emitir NFse"
              error={formik.errors.nfse_emission}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <SelectCard
                  selected={formik.values.nfse_emission}
                  item={{ title: `Emitir NFse` }}
                  setSelected={() => formik.setFieldValue('nfse_emission', !formik.values.nfse_emission)}
                />
              )}
            />
            <FieldControl
              label="CSC"
              error={formik.errors.csc_nfce_producao}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput
                  name="csc_nfce_producao"
                  value={formik.values.csc_nfce_producao}
                  onChange={formik.handleChange}
                />
              )}
            />
            <FieldControl
              label="ID Token"
              error={formik.errors.id_token_nfce_producao}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput
                  name="id_token_nfce_producao"
                  value={formik.values.id_token_nfce_producao}
                  onChange={formik.handleChange}
                />
              )}
            />
            <FieldControl
              label="Alíquota ISS"
              error={formik.errors.aliquota_iss}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput name="aliquota_iss" value={formik.values.aliquota_iss} onChange={formik.handleChange} />
              )}
            />
            <FieldControl
              label="Cód. trib. do Município"
              error={formik.errors.codigo_tributario_municipio}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput
                  name="codigo_tributario_municipio"
                  value={formik.values.codigo_tributario_municipio}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
          {/* {!invoiceIssuerQuery.data?.isValideCertificate && ( */}
          <>
            <FieldControl
              label="Certificado digital"
              error={formik.errors.arquivo_certificado_base64}
              isLoading={invoiceIssuerQuery.isLoading}
              render={({ error }) => (
                <InputFileField
                  accept=".pfx"
                  name="arquivo_certificado_base64"
                  value={formik.values.arquivo_certificado_base64}
                  error={!!error}
                  onChange={(file) => formik.setFieldValue('arquivo_certificado_base64', file)}
                />
              )}
            />

            <FieldControl
              label="Senha do certificado"
              error={formik.errors.senha_certificado}
              isLoading={invoiceIssuerQuery.isLoading}
              render={() => (
                <TextInput
                  name="senha_certificado"
                  type="password"
                  value={formik.values.senha_certificado}
                  onChange={formik.handleChange}
                />
              )}
            />
          </>
          {/* )} */}
        </VStack>
      </Collapse>

      <Button onClick={formik.handleSubmit} isLoading={invoiceIssuerMutation.isLoading} w="full" mt={8}>
        Salvar alterações
      </Button>
    </Box>
  )
}
