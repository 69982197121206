import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, CheckBox, Flex, Icon, PercentageSliderInput, Text, TextInput } from 'components/atoms'
import { toast, useTheme } from '@chakra-ui/react'
import { useAppointmentDetailStore, useSingleSaleStore, useAppointmentStore } from 'services/stores'
import { t } from 'services/translation'
import { validation, initial } from './settings'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { Form, SelectCard } from 'components/molecules'
import { clearCurrency } from 'utils/clearCurrency'
import { ModalButtonBackground } from 'components-v2/atoms'
import { pxToRem } from 'styles/metrics'
import { useAddAppointmentDiscount } from 'services/hooks/use-appointment-discount'

export const Discount = ({ goBack, isSingleSale, hideDiscountFromBarber, setEnabled }) => {
  const theme = useTheme()

  const { setDiscount, discount, selectedAppointment, normal, setNormal, commandTotalValue, setSelectedAppointment } =
    useAppointmentDetailStore()
  const { additionalProductsAppointmentList } = additionalProductsStore()
  const { productsList } = useAppointmentStore()
  const { setDiscount: setDiscountSingleSale, products } = useSingleSaleStore()

  const [discountInPercentage, setDiscountInPercentage] = useState(false)
  const [values, getValues] = useState({ params: initial, isValid: false })
  const [select, setSelect] = useState(true)

  const { mutate: putAppointmentGlobalDiscount, isLoading } = useAddAppointmentDiscount({
    appointmentId: selectedAppointment?.id,
    onSuccess: () => {
      goBack()
    },
    onError: (error) => {
      setDiscount(null)
      toast({
        title: 'Erro ao adicionar desconto',
        description: error.response.data.error,
        status: 'error',
        isClosable: true,
        duration: 4000,
      })
    },
  })

  const handleSubmitDiscount = async () => {
    let params
    if (normal) {
      const d = values?.params?.discount
      const price = typeof d === 'string' ? values?.params?.discount?.replace(/\,/g, '.') : d

      if (isSingleSale) {
        setDiscountSingleSale({
          discount: Number(price),
          discount_observation: values?.params?.discount_observation,
          discount_from_barber: select,
        })
        goBack()
        return
      } else {
        if (setEnabled) {
          setEnabled(false)
        }
        setDiscount({
          discount: Number(price),
          discount_observation: values?.params?.discount_observation,
          discount_from_barber: select,
        })
      }

      if (selectedAppointment?.status === 'reopened') {
        setSelectedAppointment({
          ...selectedAppointment,
          discount: Number(price),
          discount_observation: values?.params?.discount_observation,
          discount_from_barber: select,
        })
      }
      params = {
        discount: {
          discount: Number(price),
          discount_observation: values?.params?.discount_observation,
          discount_from_barber: select,
        },
      }
    } else {
      params = {
        discounts: discount?.map((item) => ({
          appointment_service_id: item?.type === 'product' ? null : item?.appointment_service_id || item?.id,
          appointment_product_id: item?.type === 'product' ? item?.id : null,
          discount_value: item?.discount_value,
          discount_from_barber: item?.discount_from_barber,
          type: item?.type === 'product' ? 'product' : 'service',
        })),
      }
      if (isSingleSale) {
        setDiscountSingleSale(
          discount?.map((item) => ({
            appointment_service_id: item?.type === 'product' ? null : item?.appointment_service_id || item?.id,
            appointment_product_id: item?.type === 'product' ? item?.id : null,
            discount_value: item?.discount_value,
            discount_from_barber: item?.discount_from_barber,
            type: item?.type === 'product' ? 'product' : 'service',
            name: item?.name,
          }))
        )
        goBack()
        return
      }
    }
    if (setEnabled) {
      setEnabled(true)
    }

    putAppointmentGlobalDiscount(params)
  }

  const newArr = useMemo(() => {
    const arr = [...additionalProductsAppointmentList]

    if (isSingleSale) {
      arr.push(...products)
    }

    if (selectedAppointment?.formatted_services) {
      arr.push(...selectedAppointment?.formatted_services)
    }
    return arr
  }, [productsList, additionalProductsAppointmentList, selectedAppointment])

  const onChangeValue = (id, key, value) => {
    const changed = discount?.map((item) => {
      if (item.appointment_service_id === id || item?.id === id) {
        return {
          ...item,
          [key]: value,
        }
      }
      return item
    })
    if (isSingleSale) {
      setDiscountSingleSale(changed)
    }
    setDiscount(changed)
  }

  const onChangePercentageValue = (id, key, totalValue, percentage) => {
    const value = totalValue * percentage

    const changed = discount?.map((item) => {
      if (item.appointment_service_id === id || item?.id === id) {
        return {
          ...item,
          [key]: value,
        }
      }
      return item
    })
    if (isSingleSale) {
      setDiscountSingleSale(changed)
    }
    setDiscount(changed)
  }

  const isDisabled = useMemo(() => {
    if (normal) {
      return (
        !values.params?.discount_observation ||
        values.params?.discount_observation?.length < 3 ||
        !values.params?.discount ||
        values.params?.discount?.length === 0
      )
    }
    return (
      discount?.filter((i) => i?.discount_value).reduce((acc, val) => acc + Number(val?.discount_value) || 0, 0) === 0
    )
  }, [normal, discount, values])

  const formData = [
    {
      id: 'discount_observation',
      name: 'discount_observation',
      type: 'text',
      noBorder: true,
      label: 'DISCOUNT_DESCRIPTION',
      placeholder: t('DISCOUNT_DESCRIPTION'),
    },
    {
      id: 'discount',
      name: 'discount',
      type: 'text',
      noBorder: true,
      label: 'VALUE',
      placeholder: '0,00',
      mask: 'CURRENCY_MASK',
      typeInput: discountInPercentage ? 'percentage' : '',
      valueToCalculatePercentage: commandTotalValue || 100,
      leftElement: (
        <Text color="textLight" kind="medium" mt={pxToRem(2)} width={pxToRem(48)}>
          {t('CURRENCY')}
        </Text>
      ),
    },
  ]

  return (
    <Flex flexDir="column">
      {isSingleSale && (
        <Icon
          name="ArrowLeft"
          color="primary"
          cursor="pointer"
          w="fit-content"
          size={20}
          mb={theme.pxToRem(16)}
          mr={theme.pxToRem(8)}
          onClick={goBack}
        />
      )}
      <Flex alignItems="center" mt={theme.pxToRem(8)} mb={theme.pxToRem(16)}>
        <Button
          flex="1"
          mr={theme.pxToRem(8)}
          h={theme.pxToRem(52)}
          kind={normal ? 'primary' : 'outline'}
          onClick={() => {
            setDiscount(null)
            setNormal(true)
          }}
        >
          Descontar todos de uma vez
        </Button>
        {selectedAppointment?.status !== 'reopened' && (
          <Button
            flex="1"
            ml={theme.pxToRem(8)}
            onClick={() => {
              setDiscount(newArr)
              setNormal(false)
            }}
            kind={!normal ? 'primary' : 'outline'}
          >
            Desconto individual no produto/serviço
          </Button>
        )}
      </Flex>

      <SelectCard
        item={{
          title: 'Aplicar desconto em porcentagem',
        }}
        selected={discountInPercentage}
        setSelected={(checked) => setDiscountInPercentage(checked)}
        mb={theme.pxToRem(16)}
      />

      {!normal ? (
        <>
          <Flex justify="space-between">
            <Text kind="semiBold" w="80%">
              Valor do desconto
            </Text>
            {!hideDiscountFromBarber && (
              <Text kind="semiBold" w="20%">
                Descontar
                <Text fontSize={12} kind="regular">
                  do barbeiro?
                </Text>
              </Text>
            )}
          </Flex>
          <Box marginBottom="16px">
            {newArr?.map((item, index) => {
              const totalValue = item?.type === 'product' ? Number(item?.item_value) : item?.price

              return (
                <Flex w="100%" justify="space-between" mb={discountInPercentage ? '0px' : '24px'} align="center">
                  {!discountInPercentage && (
                    <TextInput
                      mask="CURRENCY_MASK"
                      value={discount?.[index]?.discount_value}
                      label={item?.name}
                      placeholder="Digite o valor"
                      w={hideDiscountFromBarber ? '100%' : '75%'}
                      leftElement={
                        <Text color="textLight" kind="medium" mt={pxToRem(2)} width={pxToRem(48)}>
                          {t('CURRENCY')}
                        </Text>
                      }
                      onChange={(e) => {
                        onChangeValue(
                          item?.appointment_service_id || item?.id,
                          'discount_value',
                          Number(clearCurrency(e.target.value) || 0)
                        )
                      }}
                    />
                  )}

                  {discountInPercentage && (
                    <PercentageSliderInput
                      label={item?.name}
                      w={hideDiscountFromBarber ? '100%' : '75%'}
                      initialValue={(discount?.[index]?.discount_value || 0) / totalValue}
                      onChange={(percentage) => {
                        onChangePercentageValue(
                          item?.appointment_service_id || item?.id,
                          'discount_value',
                          totalValue,
                          percentage
                        )
                      }}
                    />
                  )}

                  {!hideDiscountFromBarber && (
                    <CheckBox
                      onChange={(event) =>
                        onChangeValue(
                          item?.appointment_service_id || item?.id,
                          'discount_from_barber',
                          event?.target?.checked
                        )
                      }
                      isChecked={discount?.[index]?.discount_from_barber}
                      color="primary"
                      borderColor="primary"
                      size="lg"
                      align="center"
                      w="16%"
                      mt={discountInPercentage ? '14px' : '8%'}
                    />
                  )}
                </Flex>
              )
            })}
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Form
              initialValues={initial}
              validationSchema={validation}
              data={formData}
              getValues={getValues}
              mb={discountInPercentage ? 13 : 32}
            />
          </Box>

          {!hideDiscountFromBarber && (
            <>
              <Text fontSize={theme.pxToRem(14)} fontWeight="800" marginBottom={theme.pxToRem(10)}>
                {t('DISCOUNT_BARBER_APPOINTMENT')}
              </Text>
              <Text fontSize={theme.pxToRem(12)} marginBottom={theme.pxToRem(16)} color="textGrey" fontWeight="600">
                {t('DISCOUNT_BARBER_SUBTITLE_APPOINTMENT')}
              </Text>
              <Flex alignItems="center" marginBottom="36px">
                <Button
                  flex="1"
                  mr={theme.pxToRem(8)}
                  h={theme.pxToRem(52)}
                  kind={select === true ? 'primary' : 'outline'}
                  onClick={() => {
                    setSelect(true)
                  }}
                >
                  {t('YES')}
                </Button>
                <Button
                  flex="1"
                  ml={theme.pxToRem(8)}
                  onClick={() => {
                    setSelect(false)
                  }}
                  kind={select === false ? 'primary' : 'outline'}
                >
                  {t('NO')}
                </Button>
              </Flex>
            </>
          )}
        </>
      )}

      <ModalButtonBackground>
        <Button
          type="submit"
          size="block"
          onClick={() => handleSubmitDiscount()}
          disabled={isDisabled || isLoading}
          isLoading={isLoading}
        >
          {t('ADD_DISCOUNT')}
        </Button>
      </ModalButtonBackground>
    </Flex>
  )
}
