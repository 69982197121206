import React from 'react'
import { Text, Icon, TextInput, SelectFilter, Button } from 'components'
import { Flex, useTheme, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { types } from './settings'
import { useBreakpoint, usePremiumBlock } from 'services/hooks'
import { differenceInDays, parseISO } from 'date-fns'

export const Filters = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  loading,
  setLoading,
  refetch,
  setFilterModalOpen,
  dateFilterType,
  setDateFilterType,
  transactionType,
  setTransactionType,
}) => {
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const isBlocked = usePremiumBlock()
  const { isDesktop } = useBreakpoint()

  return (
    <Flex mb={theme.pxToRem(24)} align="flex-end">
      {!isBlocked && (
        <Flex align="flex-end" justify="flex-end">
          {isDesktop && (
            <Flex mb={3.5}>
              <TextInput
                noBorder
                type="date"
                name="start_date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label={t('Data Inicial')}
                padding={theme.pxToRem(10)}
                mr={theme.pxToRem(12)}
                w={theme.pxToRem(150)}
              />
              <TextInput
                noBorder
                type="date"
                name="end_date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label={t('Data Final')}
                padding={theme.pxToRem(10)}
                mr={theme.pxToRem(12)}
                w={theme.pxToRem(150)}
              />
            </Flex>
          )}
          <Button
            isLoading={loading}
            icon="Search"
            size="small"
            iconColor="black"
            mr="12px"
            onClick={() => {
              // if (differenceInDays(parseISO(endDate), parseISO(startDate)) > 30) {
              //   toast({
              //     title: 'Período máximo de 1 mês',
              //     status: 'error',
              //     duration: 4000,
              //     isClosable: true,
              //   })
              //   return
              // }
              setLoading(true)
              refetch()
            }}
          >
            Pesquisar
          </Button>

          {!isDesktop && (
            <Flex
              w={theme.pxToRem(36)}
              h={theme.pxToRem(36)}
              bgColor="cardBackground"
              borderWidth={1}
              borderColor="modalBorder"
              borderRadius={theme.pxToRem(4)}
              align="center"
              justify="center"
              ml={theme.pxToRem(8)}
              onClick={() => setFilterModalOpen(true)}
            >
              <Icon name="Filter" />
            </Flex>
          )}
        </Flex>
      )}
      <Flex flexDir="column">
        <Text mb={theme.pxToRem(9)} fontSize={14} kind="medium" color="textGrey">
          Filtrar período por:
        </Text>
        <SelectFilter
          withoutPlaceholder
          minW={210}
          onChange={(e) =>
            setDateFilterType(
              e === 'payment_date' || e === 'Data de recebimento'
                ? { value: 'payment_date', name: 'Data de recebimento' }
                : { value: 'date', name: 'Data de lançamento' }
            )
          }
          value={dateFilterType?.value}
          options={[
            { value: 'date', name: 'Data de lançamento' },
            { value: 'payment_date', name: 'Data de recebimento' },
          ]}
        />
      </Flex>
      <SelectFilter
        onChange={(e) => {
          setTransactionType(types?.find((i) => i?.value === e))
        }}
        value={transactionType?.value}
        options={types}
        label={'Tipo de transação'}
      />
    </Flex>
  )
}
