import { useFetch } from './use-fetch'

export const useInternal = () => {
  const { onFetchExport } = useFetch()

  async function exportBarbershopClients(props) {
    const response = await onFetchExport({
      ...props,
      url: `v3/internal-adm/export-barbershop-clients/${props?.type}/${props?.id}`,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function exportPaymentTotem(props) {
    const response = await onFetchExport({
      ...props,
      url: `v3/internal-adm/export-payment-totem/${props?.id}`,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  return {
    exportPaymentTotem,
    exportBarbershopClients,
  }
}
