import React from 'react'
import { Text, Flex } from 'components'
import { formatPrice, handleAppoitmentStatusColor } from 'utils'
import './hideScrollbar.css'
import { t } from 'services/translation'
import { pxToRem } from 'styles/metrics'
import { format, parse, parseISO } from 'date-fns'

export const firstRender = (monthExtract) => {
  return [
    {
      id: 0,
      label: 'Saldo do período',
      description: 'Entradas - Saídas',
      color: 'white',
      value: monthExtract?.balance_calculated || 0,
      balance: monthExtract?.balance_calculated >= 0 ? 'successDark' : 'dangerCard',
      background: monthExtract?.balance_calculated >= 0 ? 'success' : 'danger',
    },
    {
      id: 2,
      label: 'Total de saídas',
      description: 'Total de despesas no período',
      balance: 'cardBackground',
      color: 'red',
      value: -monthExtract?.exist_calculated || 0,
    },
    {
      id: 1,
      label: 'Total de entradas',
      color: 'success',
      value: monthExtract?.entries_calculated || 0,
      balance: 'cardBackground',
      description: 'Taxas de pagamento já descontadas',
      last: true,
    },
  ]
}

export const secondRender = (monthExtract) => {
  return [
    {
      id: 5,
      label: 'Valor total BRUTO das comandas finalizadas no período selecionado',
      value: monthExtract?.finished_appointments_total_sum_discounts || 0,
      color: 'blue',
    },
    {
      label: 'Taxas dos métodos de pagamento das comandas finalizadas',
      color: 'primary',
      value: -(monthExtract?.finished_appointments_payments_methods_discounts || 0),
    },
    {
      label: 'Descontos oferecidos aos clientes nas comandas finalizadas',
      color: 'primary',
      value: -(monthExtract?.finished_appointments_others_discounts || 0),
    },
    {
      id: 5,
      label: 'Valor total LÍQUIDO das comandas finalizadas no período selecionado',
      value: monthExtract?.finished_appointments_total_discounting_payment_methods || 0,
      color: 'blue',
      last: true,
    },
  ]
}

export const secondCards = (monthExtract) => [
  {
    id: 7,
    label: 'Comissões e vales pagos para barbeiros',
    color: 'danger',
    value: -((monthExtract?.comission_paid_total_value || 0) + (monthExtract?.pending_worht_total_value || 0)) || 0,
  },
  {
    label: 'Lançamentos manuais de Despesas',
    color: 'danger',
    value: -(monthExtract?.expenses_total_value || 0),
  },
  {
    id: 3,
    label: 'Lançamentos manuais de Receita',
    color: 'success',
    value: monthExtract?.revenues_total_value || 0,
  },
  {
    id: 3,
    label: 'Venda de Pacotes e Assinaturas',
    color: 'successIsrael',
    value: monthExtract?.signatures_paid_total_with_discounts || 0,
    last: true,
  },
]

export const thirtyCards = (monthExtract) => [
  {
    id: 4,
    label: 'Taxas métodos de pagamento',
    color: 'danger',
    value: -(monthExtract?.appointment_payments_methods_discounts || 0),
    description: 'Taxas maquininha',
    info: 'disabled',
    infoText: 'Total de taxas dos métodos de pagamentos',
    last: true,
  },
  // {
  //   id: 8,
  //   label: 'Vales',
  //   color: 'danger',
  //   value: -(monthExtract?.worht_total_value || 0) || 0,
  //   description: 'Pendentes abatimento',
  //   info: 'disabled',
  //   infoText: 'Total de vales concedidos para barbeiros',
  //   last: true,
  //   fontSize: 14,
  // },
]

export const cardsMobile = (monthExtract) => {
  return [
    //cebidos
    {
      label: 'APPOINTMENTS',
      color: 'success',
      value: monthExtract?.total_entries || 0,
      description: 'TOTAL_ENTRIES',
    },
    //lançamentos receitas
    {
      label: 'APPOINTMENTS',
      color: 'danger',
      value: -monthExtract?.total_exits || 0,
      description: 'TOTAL_EXITS',
      last: true,
    },
  ]
}

export const statuses = [
  {
    name: 'Pago',
    value: 'paid',
  },
  {
    name: 'Pendente',
    value: 'pending',
  },
]

export const types = [
  {
    name: 'Todas as Entradas',
    value: 'all_entries',
  },
  {
    name: 'Todas as Saídas',
    value: 'all_exits',
  },
  {
    name: 'Lançamento Despesas',
    value: 'expense',
  },
  {
    name: 'Lançamento Receitas',
    value: 'revenue',
  },
  {
    name: 'Atendimento Cliente',
    value: 'appointment',
  },
  {
    name: 'Vale para Barbeiros',
    value: 'worth',
  },
  {
    name: 'Pagamento Comissões',
    value: 'paid_appointment',
  },
  {
    name: 'Venda de Produtos',
    value: 'single_product',
  },
  {
    name: 'Pacotes e Assinaturas',
    value: 'package_and_signatures',
  },
  {
    name: 'Produtos para Barbeiro',
    value: 'singleProductBarber',
  },
  {
    name: 'Agendamentos pendentes',
    value: 'pending',
  },
]

export const columns = [
  {
    key: 'date',
    label: 'DATA DO LANÇAMENTO',
    width: 90,
    render: (item) => {
      const date = item?.simple_date || item?.date || item?.due_at_date || item?.createdAt
      return <Text>{date ? format(parseISO(date?.substring(0, 10)), 'dd/MM/yyyy') : '-'}</Text>
    },
  },
  {
    key: 'payment_date',
    label: 'DATA DE RECEBIMENTO',
    width: 90,
    render: (item) => {
      const date =
        item?.type === 'expense' || item?.type === 'revenue'
          ? null
          : item?.cost_price
          ? item?.createdAt
          : item?.payment_date || item?.due_at_date
      return <Text>{date ? format(parseISO(date?.substring(0, 10)), 'dd/MM/yyyy') : '-'}</Text>
    },
  },
  {
    key: 'description',
    label: 'DESCRIÇÃO',
    width: 215,
    render: (item) => {
      if (item?.type === 'expense' || item?.type === 'revenue') {
        return (
          <Text kind="medium">
            {item?.type === 'revenue' ? t('_REVENUES') : t('_EXPENSES')}
            {` - ${item?.title}`}
          </Text>
        )
      }
      if (item?.subscription_id) {
        return (
          <>
            <Text kind="medium">{item?.title}</Text>

            <Text fontSize={pxToRem(12)} kind="regular">
              Cliente: {item?.subscription?.client?.name}
            </Text>
          </>
        )
      }
      const client_name = item?.client?.name.split(' ')?.[0]
      const service_name = item?.appointmentServices?.[0]?.product?.name
      const product_name = item?.appointmentProducts?.[0]?.additional_product?.name
      const service_text = service_name
        ? ` - ${service_name}${
            item?.appointmentServices?.length > 1 ? ` + ${item?.appointmentServices?.length} serviços` : ''
          }`
        : ''
      const product_text = product_name
        ? `${product_name}${
            item?.appointmentProducts?.length > 1 ? ` + ${item?.appointmentProducts?.length} produtos` : ''
          }`
        : null
      const getType = () => {
        if (item?.type === 'paid_appointment') {
          return 'Pagamento de comissão'
        }
        if (item?.type === 'expense') {
          return 'Lançamento Despesa'
        }
        if (item?.type === 'worth') {
          return item?.description || 'Vale'
        }
        if (item?.type === 'revenue') {
          return 'Lançamento Receita'
        }
        if (item?.type === 'singleProduct') {
          return 'Venda Produto'
        }
        if (item?.subscription?.type === 'individual_package_service') {
          return 'Pacote de serviço'
        }
        if (item?.subscription_id) {
          return 'Assinatura'
        }
        if (item?.type === 'singleProductBarber') {
          return 'Venda Produto para colaborador'
        }
        return 'Atendimento Cliente'
      }
      return (
        <Flex flexDir="column">
          <Text kind="semiBold">{getType(item)}</Text>
          <Text fontSize={pxToRem(12)} w="100%" kind="regular" noOfLines={1}>
            {client_name || ''}
            {item?.type === 'singleProduct' && product_text ? product_text : service_text}
          </Text>
          {item?.barber?.name && !client_name && (
            <Text fontSize={pxToRem(12)} kind="regular">
              Colaborador: {item?.barber?.name}
            </Text>
          )}
          {item?.employee?.name && !client_name && (
            <Text fontSize={pxToRem(12)} kind="regular">
              Colaborador: {item?.employee?.name}
            </Text>
          )}
        </Flex>
      )
    },
  },
  {
    key: 'total_value',
    label: 'VALOR',
    width: 90,
    render: (item) => {
      const text_props = {
        kind: 'medium',
        color:
          item?.type === 'expense' || item?.type === 'worth' || item?.type === 'paid_appointment'
            ? 'danger'
            : 'success',
      }
      if (item?.type === 'worth' || item?.type === 'paid_appointment') {
        return <Text {...text_props}>-{formatPrice(item?.value)}</Text>
      }
      if (item?.type === 'expense' || item?.type === 'revenue' || item?.subscription_id) {
        return (
          <Text {...text_props}>
            {item?.type === 'expense' ? '-' : '+'}
            {formatPrice(item?.value)}
          </Text>
        )
      }
      return (
        <Text {...text_props} color="textLight">
          {formatPrice(item?.total_value + item?.discount)}
        </Text>
      )
    },
  },

  {
    key: 'discount',
    label: 'TAXAS E DESCONTOS',
    width: 90,
    render: (item) => {
      const discount = item?.discount > 0 && !item?.subscription_id ? item?.discount : 0

      const taxes_paid = item?.subscription_id
        ? item?.asaas_status === 'RECEIVED_IN_CASH'
          ? 0
          : item?.discount
        : item?.payment_percentage_value

      const total = -(discount || 0) - (taxes_paid || 0)
      return (
        <Text kind="medium" color={'primary'}>
          {total < 0 ? formatPrice(total) : '-'}
        </Text>
      )
    },
  },
  {
    key: 'value',
    label: 'VALOR RECEBIDO',
    width: 90,
    render: (item) => {
      const text_props = {
        kind: 'medium',
        color: item?.type === 'expense' || item?.type === 'paid_appointment' ? 'danger' : 'success',
      }

      if (item?.type === 'worth') {
        return <Text {...text_props}>{' - '}</Text>
      }
      if (item?.status === 'paid' || item?.status === 'finished' || item?.status === 'PAID') {
        if (item?.type === 'expense' || item?.type === 'revenue' || item?.type === 'paid_appointment') {
          return (
            <Text {...text_props}>
              {item?.type === 'expense' || item?.type === 'paid_appointment' ? '-' : '+'}
              {formatPrice(item?.value)}
            </Text>
          )
        }
        if (item?.subscription_id) {
          return (
            <Text {...text_props}>
              +{formatPrice(item?.asaas_status === 'RECEIVED_IN_CASH' ? item?.value : item?.net_value)}
            </Text>
          )
        }
        return <Text {...text_props}>+{formatPrice(item?.total_value - (item?.payment_percentage_value || 0))}</Text>
      }
      return <Text {...text_props}>{' - '}</Text>
    },
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => (
      <Text kind="bold" color={handleAppoitmentStatusColor(item?.status)}>
        {item?.type === 'worth' ? ' - ' : t(item?.status)}
      </Text>
    ),
  },
]

export const columnsDetailed = [
  {
    key: 'date',
    label: 'DATA DO LANÇAMENTO',
    width: 90,
    render: (item) => {
      const date = item?.simple_date || item?.date || item?.due_at_date || item?.createdAt
      return <Text>{date ? format(parseISO(date?.substring(0, 10)), 'dd/MM/yyyy') : '-'}</Text>
    },
  },
  {
    key: 'payment_date',
    label: 'DATA DE RECEBIMENTO',
    width: 90,
    render: (item) => {
      const date =
        item?.type === 'expense' || item?.type === 'revenue'
          ? null
          : item?.cost_price
          ? item?.createdAt
          : item?.payment_date || item?.due_at_date
      return <Text>{date ? format(parseISO(date?.substring(0, 10)), 'dd/MM/yyyy') : '-'}</Text>
    },
  },
  {
    key: 'description',
    label: 'DESCRIÇÃO',
    width: 215,
    render: (item) => {
      if (item?.type === 'expense' || item?.type === 'revenue') {
        return (
          <Text kind="medium">
            {item?.type === 'revenue' ? t('_REVENUES') : t('_EXPENSES')}
            {` - ${item?.title}`}
          </Text>
        )
      }
      if (item?.subscription_id) {
        return (
          <>
            <Text kind="medium">{item?.title}</Text>

            <Text fontSize={pxToRem(12)} kind="regular">
              Cliente: {item?.subscription?.client?.name}
            </Text>
          </>
        )
      }
      const client_name = item?.client?.name.split(' ')?.[0]
      const service_name = item?.appointmentServices?.[0]?.product?.name
      const product_name = item?.appointmentProducts?.[0]?.additional_product?.name
      const service_text = service_name
        ? ` - ${service_name}${
            item?.appointmentServices?.length > 1 ? ` + ${item?.appointmentServices?.length} serviços` : ''
          }`
        : ''
      const product_text = product_name
        ? `${product_name}${
            item?.appointmentProducts?.length > 1 ? ` + ${item?.appointmentProducts?.length} produtos` : ''
          }`
        : null
      const getType = () => {
        if (item?.type === 'paid_appointment') {
          return 'Pagamento de comissão'
        }
        if (item?.type === 'expense') {
          return 'Lançamento Despesa'
        }
        if (item?.type === 'worth') {
          return item?.description || 'Vale'
        }
        if (item?.type === 'revenue') {
          return 'Lançamento Receita'
        }
        if (item?.type === 'singleProduct') {
          return 'Venda Produto'
        }
        if (item?.subscription?.type === 'individual_package_service') {
          return 'Pacote de serviço'
        }
        if (item?.subscription_id) {
          return 'Assinatura'
        }
        if (item?.type === 'singleProductBarber') {
          return 'Venda Produto para colaborador'
        }
        return 'Atendimento Cliente'
      }
      return (
        <Flex flexDir="column">
          <Text kind="semiBold">{getType(item)}</Text>
          <Text fontSize={pxToRem(12)} w="100%" kind="regular" noOfLines={1}>
            {client_name || ''}
            {item?.type === 'singleProduct' && product_text ? product_text : service_text}
          </Text>
          {item?.barber?.name && !client_name && (
            <Text fontSize={pxToRem(12)} kind="regular">
              Colaborador: {item?.barber?.name}
            </Text>
          )}
          {item?.employee?.name && !client_name && (
            <Text fontSize={pxToRem(12)} kind="regular">
              Colaborador: {item?.employee?.name}
            </Text>
          )}
        </Flex>
      )
    },
  },
  {
    key: 'total_value',
    label: 'VALOR',
    width: 90,
    render: (item) => {
      const text_props = {
        kind: 'medium',
        color:
          item?.type === 'expense' || item?.type === 'worth' || item?.type === 'paid_appointment'
            ? 'danger'
            : 'success',
      }
      if (item?.type === 'worth' || item?.type === 'paid_appointment') {
        return <Text {...text_props}>-{formatPrice(item?.value)}</Text>
      }
      if (item?.type === 'expense' || item?.type === 'revenue' || item?.subscription_id) {
        return (
          <Text {...text_props}>
            {item?.type === 'expense' ? '-' : '+'}
            {formatPrice(item?.value)}
          </Text>
        )
      }
      return (
        <Text {...text_props} color="textLight">
          {formatPrice(item?.total_value + item?.discount)}
        </Text>
      )
    },
  },
  {
    key: 'discount',
    label: 'TAXAS DE PAGAMENTO',
    width: 90,
    render: (item) => {
      const taxes_paid = item?.subscription_id
        ? item?.asaas_status === 'RECEIVED_IN_CASH'
          ? 0
          : item?.discount
        : item?.payment_percentage_value

      const total = -(taxes_paid || 0)
      return (
        <Text kind="medium" color={'primary'}>
          {total < 0 ? formatPrice(total) : '-'}
        </Text>
      )
    },
  },
  {
    key: 'discount',
    label: 'DESCONTOS OFERECIDOS',
    width: 90,
    render: (item) => {
      const discount = item?.discount > 0 && !item?.subscription_id ? item?.discount : 0

      const total = -(discount || 0)
      return (
        <Text kind="medium" color={'primary'}>
          {total < 0 ? formatPrice(total) : '-'}
        </Text>
      )
    },
  },
  {
    key: 'value',
    label: 'VALOR RECEBIDO',
    width: 90,
    render: (item) => {
      const text_props = {
        kind: 'medium',
        color: item?.type === 'expense' || item?.type === 'paid_appointment' ? 'danger' : 'success',
      }

      if (item?.type === 'worth') {
        return <Text {...text_props}>{' - '}</Text>
      }
      if (item?.status === 'paid' || item?.status === 'finished' || item?.status === 'PAID') {
        if (item?.type === 'expense' || item?.type === 'revenue' || item?.type === 'paid_appointment') {
          return (
            <Text {...text_props}>
              {item?.type === 'expense' || item?.type === 'paid_appointment' ? '-' : '+'}
              {formatPrice(item?.value)}
            </Text>
          )
        }
        if (item?.subscription_id) {
          return (
            <Text {...text_props}>
              +{formatPrice(item?.asaas_status === 'RECEIVED_IN_CASH' ? item?.value : item?.net_value)}
            </Text>
          )
        }
        return <Text {...text_props}>+{formatPrice(item?.total_value - (item?.payment_percentage_value || 0))}</Text>
      }
      return <Text {...text_props}>{' - '}</Text>
    },
  },
  {
    key: 'value',
    label: 'COMISSÃO COLABORADOR',
    width: 90,
    render: (item) => {
      const text_props = {
        kind: 'medium',
        color: 'textLight',
      }
      if (!item?.barber_percentage_value && !item?.barber_products_value) {
        return <Text {...text_props}>-</Text>
      }
      const total_products =
        item?.appointmentProducts?.reduce(
          (accumulated, current) => accumulated + Number(current?.barber_value || 0),
          0
        ) || 0
      const total_services =
        item?.appointmentServices?.reduce(
          (accumulated, current) => accumulated + Number(current?.barber_value || 0),
          0
        ) || 0
      return <Text {...text_props}>{formatPrice(total_products + total_services)}</Text>
    },
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => (
      <Text kind="bold" color={handleAppoitmentStatusColor(item?.status)}>
        {item?.type === 'worth' ? ' - ' : t(item?.status)}
      </Text>
    ),
  },
]

export const getDescriptionFromTable = (item) => {
  if (item?.type === 'expense' || item?.type === 'revenue') {
    return `${item?.type === 'revenue' ? t('_REVENUES') : t('_EXPENSES')} - ${item?.title}`
  }
  if (item?.subscription_id) {
    return `Cliente: ${item?.subscription?.client?.name}`
  }
  const client_name = item?.client?.name.split(' ')?.[0]
  const service_name = item?.appointmentServices?.[0]?.product?.name
  const product_name = item?.appointmentProducts?.[0]?.additional_product?.name
  const service_text = service_name
    ? ` - ${service_name}${
        item?.appointmentServices?.length > 1 ? ` + ${item?.appointmentServices?.length} serviços` : ''
      }`
    : ''
  const product_text = product_name
    ? `${product_name}${
        item?.appointmentProducts?.length > 1 ? ` + ${item?.appointmentProducts?.length} produtos` : ''
      }`
    : null

  return (
    <Flex flexDir="column">
      <Text kind="semiBold">{getType(item)}</Text>
      <Text fontSize={pxToRem(12)} w="100%" kind="regular" noOfLines={1}>
        {client_name || ''}
        {item?.type === 'singleProduct' && product_text ? product_text : service_text}
      </Text>
      {item?.barber?.name && !client_name && (
        <Text fontSize={pxToRem(12)} kind="regular">
          Barbeiro: {item?.barber?.name}
        </Text>
      )}
      {item?.employee?.name && !client_name && (
        <Text fontSize={pxToRem(12)} kind="regular">
          Colaborador: {item?.employee?.name}
        </Text>
      )}
    </Flex>
  )
}

export const getType = (item) => {
  if (item?.type === 'paid_appointment') {
    return 'Pagamento de comissão'
  }
  if (item?.type === 'expense') {
    return 'Lançamento Despesa'
  }
  if (item?.type === 'worth') {
    return item?.description || 'Vale'
  }
  if (item?.type === 'revenue') {
    return 'Lançamento Receita'
  }
  if (item?.type === 'singleProduct') {
    return 'Venda Produto'
  }
  if (item?.subscription?.type === 'individual_package_service') {
    return 'Pacote de serviço'
  }
  if (item?.subscription_id) {
    return 'Assinatura'
  }
  if (item?.type === 'singleProductBarber') {
    return 'Venda Produto para colaborador'
  }
  return 'Atendimento Cliente'
}

export const renderValue = (item) => {
  if (item?.type === 'worth') return ' - '
  if (item?.status === 'paid' || item?.status === 'finished' || item?.status === 'PAID') {
    if (item?.type === 'expense' || item?.type === 'revenue' || item?.type === 'paid_appointment') {
      return item?.value
    }
    if (item?.subscription_id) {
      return item?.asaas_status === 'RECEIVED_IN_CASH' ? item?.value : item?.net_value
    }
    return item?.total_value - (item?.payment_percentage_value || 0)
  }
  return '-'
}
