import axios from 'axios'
import { getData } from 'services/storage'

// const BASE_URL = 'http://localhost:3333/'
// const BASE_URL = 'https://api.bestbarbers.app/'
// const BASE_URL = 'https://bestbarbers-api-homologation-e915.up.railway.app/'
const BASE_URL = null

const apiReport = axios.create({
  // baseURL: BASE_URL || 'https://api.bestbarbers.app',
  baseURL: BASE_URL || 'https://bestbarbers-api-client-report.up.railway.app/',
})

apiReport.interceptors.request.use(
  async (config) => {
    const result = localStorage.getItem('USER')
    if (result?.token) {
      config.headers.Authorization = `Bearer ${result?.token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export { apiReport }
