import { useFetch } from './use-fetch'

export const useClientsReport = () => {
  const { onFetch, onFetchReport, onFetchExportReport } = useFetch()

  async function getNewClientsReport(props) {
    const response = await onFetchReport({
      ...props,
      url: `v3/barbershop/new-clients-report/${props?.barbershop_id}?start_date=${props?.startDate}&end_date=${props?.endDate}&skip=${props?.skip}`,
      method: 'get',
    })
    return response
  }

  async function exportNewClientsReport(props) {
    const response = await onFetchExportReport({
      ...props,
      url: `v3/barbershop/new-clients-report/export-csv/${props?.barbershop_id}?start_date=${props?.startDate}&end_date=${props?.endDate}`,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
        ...props.params,
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function getFirstAppointmentClientsReport(props) {
    const response = await onFetchReport({
      ...props,
      url: `v3/barbershop/first-appointment-clients/${props?.barbershop_id}?start_date=${props?.startDate}&end_date=${props?.endDate}&skip=${props?.skip}`,
      method: 'get',
    })
    return response
  }

  async function exportFirstAppointmentClientsReport(props) {
    const response = await onFetchExportReport({
      ...props,
      url: `v3/barbershop/first-appointment-clients/export/${props?.barbershop_id}?start_date=${props?.startDate}&end_date=${props?.endDate}`,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
        ...props.params,
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function exportLastAppointmentClientsReport(props) {
    const response = await onFetchExportReport({
      ...props,
      url: `v3/barbershop/last-appointment-clients/export/${props?.barbershop_id}?start_date=${props?.startDate}&end_date=${props?.endDate}`,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
        ...props.params,
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function getLastAppointmentClientsReport(props) {
    const response = await onFetchReport({
      ...props,
      url: `v3/barbershop/last-appointment-clients/${props?.barbershop_id}?start_date=${props?.startDate}&end_date=${props?.endDate}&skip=${props?.skip}`,
      method: 'get',
    })
    return response
  }

  return {
    getNewClientsReport,
    exportNewClientsReport,
    getFirstAppointmentClientsReport,
    getLastAppointmentClientsReport,
    exportFirstAppointmentClientsReport,
    exportLastAppointmentClientsReport,
  }
}
