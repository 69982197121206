import create from 'zustand'

export const useAppointmentDetailStore = create((set) => ({
  commandTotalValue: 0,
  setCommandTotalValue: (commandTotalValue) => set({ commandTotalValue }),

  selectedAppointment: null,
  setSelectedAppointment: (selectedAppointment) => set({ selectedAppointment }),

  additional: [],
  setAdditional: (additional) => set({ additional }),

  paymentMethod: [],
  setPaymentMethod: (paymentMethod) => set({ paymentMethod }),

  discount: [],
  setDiscount: (discount) => set({ discount }),

  normal: true,
  setNormal: (normal) => set({ normal }),

  services: [],
  setServices: (discount) => set({ discount }),

  addProduct: (product) => set((state) => ({ additional: [...state.additional, product] })),
  removeProduct: (product) =>
    set((state) => ({
      additional: state.additional.filter((item) => item.name !== product.name && item.price !== product.price),
    })),
}))
