import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTheme } from '@chakra-ui/react'
import { Flex, Table, Box, Text, TextInput, Button, Icon } from 'components'
import { useAppointmentStore, useBarbershopStore, useSingleSaleStore } from 'services/stores'
import { formatPhone } from 'utils/phone-formater'
import { pxToRem } from 'styles/metrics'
import { colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { useQuery, useQueryClient } from 'react-query'
import { ModalButtonBackground } from 'components-v2/atoms'
import { People } from 'assets/icons'

const AppointmentSelectClient = ({ goBack, isSingleSale, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    setClient,
    setClientsList,
    clientsList,
    loading,
    scheduleType,
    setPackageSell,
    setPackageService,
    setSignature,
  } = useAppointmentStore()
  const { setClient: setClientSingleSale } = useSingleSaleStore()
  const [isRegisteredCustomer, setIsRegisteredCustomer] = useState(true)
  const [query, setQuery] = useState('')
  const [skip, setSkip] = useState(0)
  const [refreshing, setRefreshing] = useState(false)

  const { userData } = useBarbershopStore()
  const { getClients } = useBarbershop()
  const queryClient = useQueryClient()

  const columns = [
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => (
        <Text w="100%" kind="bold" color={item?.blocked ? 'red' : 'textLight'}>
          {item.client.name}
        </Text>
      ),
    },
    {
      key: 'phone',
      label: 'PHONE_NUMBER',
      width: 130,
      render: (item) => (
        <Text w="100%" kind="medium" color={item?.blocked ? 'red' : 'textGrey'}>
          {formatPhone(item.client.user.phone, userData)}
        </Text>
      ),
    },
    {
      key: 'blocked',
      label: 'BLOCKED',
      render: (item) => (
        <Text w="100%" kind="medium" color={item?.blocked ? 'red' : 'green'}>
          {item?.blocked ? 'Bloqueado' : 'Ativo'}
        </Text>
      ),
    },
  ]

  const { refetch } = useQuery(['get-clients', skip], () => getClients({ id: userData?.id, skip, query }), {
    onSuccess: (data) => {
      let newList = data
      if (skip !== 0 && query?.length % 2 === 0) {
        newList = [...clientsList, ...data]
      }
      setClientsList(newList)
      setRefreshing(false)
    },
  })

  const handleSelectCustomer = (item) => {
    setSignature(false)
    setPackageService(false)
    queryClient.removeQueries('get-services')
    if (isSingleSale) {
      setClientSingleSale({ ...item, created: false })
    } else {
      setClient({
        ...item,
        created: false,
      })
    }

    goBack()
  }

  const fetchMoreItems = () => {
    setRefreshing(true)
    setSkip(skip + 20)
  }

  const handleAddNewCustomer = (value) => {
    setClient(value)
    setPackageSell(false)
    goBack()
  }

  const { values, handleSubmit, handleChange, errors, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      birth_date: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(3, t('NAME_SIZE')).required(t('NAME_REQUIRED')),
      email: Yup.string().email(t('INVALID_EMAIL')),
      phone: Yup.string().required(t('PHONE_REQUIRED')),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () =>
      handleAddNewCustomer({
        // follow the same structure of client from back-end
        created: true,
        client: {
          name: values.name,
          birth_date: values.birth_date,
          user: {
            phone: values.phone,
            email: values.email,
          },
        },
      }),
  })

  return (
    <Flex flexDirection="column" flex="1">
      {isSingleSale || scheduleType !== 'normal' ? (
        <Flex mt={theme.pxToRem(-12)} mb={theme.pxToRem(12)} w="100%" flexDir="row" align="center">
          {!scheduleType && (
            <Icon
              name="ArrowLeft"
              color="primary"
              cursor="pointer"
              w="fit-content"
              size={20}
              mr={theme.pxToRem(12)}
              onClick={goBack}
            />
          )}
          {props?.withSubtitle && (
            <Text kind="bold" fontSize={18}>
              {t('CLIENT')}
            </Text>
          )}
        </Flex>
      ) : (
        <Flex justifyContent="flex-start" borderBottom={`1px solid ${colors.grey[600]}`} marginBottom={4} mt={-4}>
          <Box
            onClick={() => setIsRegisteredCustomer(true)}
            marginRight={theme.pxToRem(8)}
            py={2}
            w="50%"
            align="center"
            cursor="pointer"
            borderBottom={isRegisteredCustomer ? `2px solid ${colors.primary}` : 'none'}
          >
            <Text fontSize={pxToRem(14)} kind="semiBold" color={isRegisteredCustomer ? 'primary' : 'textGrey'}>
              {t('CUSTOMER_REGISTERED')}
            </Text>
          </Box>
          <Box
            w="50%"
            align="center"
            onClick={() => setIsRegisteredCustomer(false)}
            py={2}
            cursor="pointer"
            borderBottom={!isRegisteredCustomer ? `2px solid ${colors.primary}` : 'none'}
          >
            <Text fontSize={pxToRem(14)} kind="semiBold" color={!isRegisteredCustomer ? 'primary' : 'textGrey'}>
              {t('CUSTOMER_NOT_REGISTERED')}
            </Text>
          </Box>
        </Flex>
      )}

      {isRegisteredCustomer ? (
        <>
          <Flex w="100%" justify="space-between" mb={4}>
            <TextInput
              noBorder
              type="text"
              color={colors.white}
              placeholder={t('SEARCH_CLIENT')}
              backgroundColor="backgroundLayout"
              borderWidth={1}
              borderColor="modalBorder"
              borderRadius={12}
              paddingLeft={theme.pxToRem(16)}
              mb={theme.pxToRem(12)}
              onChange={(event) => setQuery(event?.target?.value)}
              w="95%"
              mr="3%"
            />
            <Button
              onClick={refetch}
              icon="Search"
              iconColor="black"
              iconSize={20}
              w={theme.pxToRem(52)}
              h={theme.pxToRem(52)}
            />
          </Flex>

          <Table
            withoutHeader
            loading={!refreshing && loading}
            columns={columns}
            data={clientsList}
            isSelectableRow
            onSelect={handleSelectCustomer}
          />
          <Flex width="full" justifyContent="center" paddingY={theme.pxToRem(32)}>
            {!loading && <Button onClick={fetchMoreItems}>Carregar mais</Button>}
          </Flex>
        </>
      ) : (
        <Flex flexDirection="column" flex="1">
          <Text kind="medium" color="textLight" fontSize={pxToRem(14)} marginBottom={pxToRem(12)}>
            {t('NAME_TABLE')}
          </Text>
          <TextInput
            type="text"
            name="name"
            id="name"
            placeholder={t('CUSTOMER_NAME')}
            color={colors.textLight}
            disabled={loading}
            error={errors.name}
            onChange={handleChange}
            noBorder
            backgroundColor="backgroundLayout"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={12}
            paddingLeft={theme.pxToRem(16)}
          />
          <Text
            fontSize={pxToRem(12)}
            marginBottom={pxToRem(12)}
            textTransform="uppercase"
            fontWeight="600"
            marginTop={pxToRem(16)}
          >
            {t('PHONE_NUMBER')}
          </Text>
          <TextInput
            // mask="PHONE_MASK"
            name="phone"
            id="phone"
            placeholder={t('PHONE_NUMBER')}
            error={errors.phone}
            type="text"
            color={colors.textLight}
            disabled={loading}
            onChange={(text) => setFieldValue('phone', String(text.target.value).replace(/\D/g, ''))}
            noBorder
            backgroundColor="backgroundLayout"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={12}
            paddingLeft={theme.pxToRem(16)}
          />
          <Text
            fontSize={pxToRem(12)}
            marginBottom={pxToRem(16)}
            textTransform="uppercase"
            fontWeight="600"
            marginTop={pxToRem(16)}
          >
            {t('OPTIONAL_EMAIL')}
          </Text>
          <TextInput
            name="email"
            id="email"
            placeholder="E-mail"
            type="text"
            color={colors.textLight}
            disabled={loading}
            noBorder
            backgroundColor="backgroundLayout"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={12}
            paddingLeft={theme.pxToRem(16)}
            error={errors.email}
            onChange={handleChange}
          />

          <Box marginTop={pxToRem(16)}>
            <Text fontSize={pxToRem(12)} marginBottom={pxToRem(16)} textTransform="uppercase" fontWeight="600">
              {t('BIRTH_DATE')}
            </Text>

            <TextInput
              noBorder
              type="text"
              name="birth_date"
              id="name"
              placeholder="XX/XX/XXXX"
              mask="DATE_MASK"
              color={colors.textLight}
              value={values.birth_date}
              disabled={loading}
              error={errors.birth_date}
              onChange={handleChange}
              leftElement={<People color={colors.primary} />}
            />
          </Box>

          <ModalButtonBackground>
            <Button disabled={!values.name || !values.phone} size="block" onClick={handleSubmit}>
              {t('ADD_CUSTOMER')}
            </Button>
          </ModalButtonBackground>
        </Flex>
      )}
    </Flex>
  )
}

export default AppointmentSelectClient
