import { colors } from 'styles/colors'
import { pxToRem } from 'styles/metrics'

export const gridLayout = (pxToRem) => ({
  gridTemplate: `
      'zip_code state city' 1fr
      'street street street_number' 1fr
      'neighborhood neighborhood neighborhood' 1fr
    `,
  gridGap: `${pxToRem(8)} ${pxToRem(24)}`,
})

export const toastSuccessMessage = {
  title: `Endereço atualizado com sucesso!`,
  status: 'success',
  duration: 4000,
  isClosable: true,
}

export const toastErrorMessage = {
  title: 'Falha ao atualizar endereço',
  description: 'Por favor, revise os dados inseridos',
  status: 'error',
  duration: 4000,
  isClosable: true,
}

export const getStyledSelectBox = (opened) => ({
  position: 'absolute',
  top: pxToRem(64),
  border: `1px solid ${opened ? colors.whiteTransparent[200] : colors.transparent[100]}`,
  backgroundColor: opened ? colors.backgroundLayout : colors.transparent[100],
  borderRadius: pxToRem(4),
  zIndex: 10,
})

export const getStyledOption = (active) => ({
  padding: `${pxToRem(10)} ${pxToRem(16)} ${pxToRem(8)}`,
  backgroundColor: active && colors.whiteTransparent[100],
  cursor: 'pointer',
})
