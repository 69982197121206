import React, { useEffect, useState, useMemo } from 'react'
import {
  Layout,
  Table,
  MobileList,
  Text,
  Icon,
  ServicesModal,
  ServiceCard,
  ClientDetailModal,
  AppointmentsModal,
  TextInput,
} from 'components'
import { useTheme, useToast, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks/use-breakpoint'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from 'react-query'
import { useBarbershopStore, useCustomerStore, useAppointmentStore } from 'services/stores'
import { usePremiumBlock } from 'services/hooks/use-premium-block'
import { usePermissionCollaborator, useBarbershop } from 'services/hooks'
import { serviceColumns, clientColumns } from './settings'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { ClientPackageModal } from 'components-v2/organisms/ManagerClientPackageModal/ManagerClientPackageModal'
import { Search } from 'assets/icons'
import { colors } from 'styles'

export function PackageServices({ ...props }) {
  const [selectedService, setSelectedService] = useState(null)
  const [serviceModalOpen, setServiceModalOpen] = useState(false)
  const [packageDetail, setPackageDetail] = useState(false)
  const [packageDetailOpen, setPackageDetailOpen] = useState(false)
  const [clientDetailModalOpen, setClientDetailModalOpen] = useState(false)
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const isBlocked = usePremiumBlock()
  const theme = useTheme()
  const { getServices, getClientsPackage, deleteClientsPackage } = useBarbershop()
  const permission = usePermissionCollaborator('services')
  const clientsPermission = usePermissionCollaborator('clients')
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false)
  const { setSelectedCustomer } = useCustomerStore()
  const { setPackageService, setScheduleType, setPackageSell } = useAppointmentStore()

  const [sections, setSections] = useState({
    services: true,
    clients: true,
  })

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  function SectionHeader({ onClick, isOpen, title }) {
    return (
      <Flex onClick={onClick} cursor="pointer" align="center" mt={theme.pxToRem(12)}>
        <Text fontSize={18} kind="bold">
          {t(title)}
        </Text>
        <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} color="white" size={18} ml="4px" />
      </Flex>
    )
  }

  const { data: clients, isLoading: clientsLoading } = useQuery(['get-clients-package'], () =>
    getClientsPackage({ id: userData?.id })
  )

  const { data: services, isLoading: loadingServices } = useQuery('get-services', () =>
    getServices({ id: userData?.id })
  )

  const onCloseServiceModal = () => {
    setServiceModalOpen(false)
    setSelectedService(null)
    setAppointmentModalOpen(false)
    // refetch();
  }

  const onClickTableRow = (selectedRow) => {
    if (permission !== 'blocked') {
      setSelectedService(selectedRow)
      setServiceModalOpen(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const onClickClient = (data) => {
    if (clientsPermission !== 'blocked') {
      setPackageDetail({
        id: data?.id,
        name: data?.client?.name,
        package_service: data?.product?.name,
        value: data?.product?.price,
        quantity_hired: data?.product?.quantity,
        quantity_left: data?.quantity,
      })
      setPackageDetailOpen(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const [searchPackage, setSearchPackage] = useState('')

  const filteredData = useMemo(() => {
    const apiData = services?.filter(({ type }) => type === 'individual_service_package') || []
    const clientData = clients || []

    if (searchPackage?.length > 0) {
      return {
        data: apiData?.filter(({ name }) => name?.toLocaleLowerCase().includes(searchPackage.toLocaleLowerCase())),
        clients: clientData?.filter(({ product }) =>
          product?.name?.toLocaleLowerCase().includes(searchPackage.toLocaleLowerCase())
        ),
      }
    }

    return { data: apiData, clients: clientData }
  }, [services, searchPackage, clients])

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Pacote de serviços"
      headerActionText={permission === 'edit' && t('Cadastrar novo pacote de serviço')}
      headerActionClick={() => setServiceModalOpen(true)}
      paddingButton={80}
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      contentPosition={isBlocked && 'relative'}
      isBlocked={isBlocked}
      iconSecondActionText="Plus"
      iconColor="black"
      secondActionText={t('Cadastrar venda de pacote')}
      secondActionClick={() => {
        setPackageSell(true)
        setScheduleType('package')
        setPackageService(true)
        setAppointmentModalOpen(true)
      }}
    >
      <TextInput
        noBorder
        type="text"
        width="full"
        color="white"
        placeholder={t('Buscar por nome do pacote')}
        value={searchPackage}
        mb="16px"
        onChange={(event) => setSearchPackage(event.target.value)}
        leftElement={<Search color={colors.primary} />}
      />
      <SectionHeader
        isOpen={sections?.services}
        onClick={() => setSections({ ...sections, services: !sections?.services })}
        title="Pacotes de serviços cadastrados"
      />

      {sections?.services &&
        (isDesktop ? (
          <Table
            isSelectableRow
            onSelect={(select) => onClickTableRow(select, 'product')}
            data={filteredData?.data || []}
            loading={loadingServices}
            columns={serviceColumns}
          />
        ) : (
          <MobileList
            data={filteredData?.data || []}
            loading={loadingServices}
            renderItem={({ item }) => <ServiceCard inList service={item} fullWidth onSelect={onClickTableRow} />}
          />
        ))}

      <SectionHeader
        isOpen={sections?.clients}
        onClick={() => setSections({ ...sections, clients: !sections?.clients })}
        title="Gerenciamento de clientes"
      />

      {sections?.clients && (
        <Table
          isSelectableRow
          onSelect={onClickClient}
          data={filteredData?.clients || []}
          loading={clientsLoading}
          columns={clientColumns}
        />
      )}

      <ClientDetailModal isModalOpen={clientDetailModalOpen} onClose={() => setClientDetailModalOpen(false)} />

      <ServicesModal
        isOpen={serviceModalOpen}
        onClose={onCloseServiceModal}
        selectedService={selectedService}
        packageService={true}
      />

      <AppointmentsModal isModalOpen={appointmentModalOpen} onClose={onCloseServiceModal} withoutTabs />

      <ClientPackageModal
        isOpen={packageDetailOpen}
        onClose={() => setPackageDetailOpen(false)}
        selectedClient={packageDetail}
      />
    </Layout>
  )
}

PackageServices.path = '/pacote-de-servicos'
PackageServices.title = 'Pacote de servicos'
PackageServices.secured = true
