import { api } from 'services/api/config'

export const useTeam = () => {
  const getAllCollaborators = async (props) => {
    const response = await api({
      url: `v3/barbershop/team/barbers/${props.id}`,
    })
    return response.data
  }

  const updateNFbarberData = async (body) => {
    const response = await api.put(`v3/barbershop/edit-barber-nf`, body)
    return response.data
  }

  const getOnlyBarbersTeam = async (barbershop_id) => {
    const response = await api.get(`v3/barbershop/team/only-barbers/${barbershop_id}`)
    return response.data
  }

  return {
    updateNFbarberData,
    getAllCollaborators,
    getOnlyBarbersTeam,
  }
}
