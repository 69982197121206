import React, { useEffect, useMemo, useState } from 'react'
import { Box, Tab, TabList, Tabs, TabPanels, TabPanel, Flex } from '@chakra-ui/react'
import { useTheme } from '@chakra-ui/react'
import { useBarbershopStore } from 'services/stores'
import { MultiColumnChart } from 'components/molecules/MultiColumnChart'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { useBarberGoalsQuery } from './services/barberGoalsQuery'
import { Table } from 'components/molecules'
import { columns } from './services/columns'
import { GroupedStackedColumnsChart } from 'components/molecules/GroupedStackedColumnsChart'
import { Button } from 'components/atoms'

const useTabs = () => {
  const tabIndexes = ['product', 'service', 'signatureEnabled', 'totalSales']

  return {
    tabIndexes,
    initialTab: tabIndexes[0],
    tabToindex: (tabName) => tabIndexes.findIndex((tab) => tab === tabName),
    indexToTab: (tabIndex) => tabIndexes[tabIndex],
  }
}

const getMin = (arr) => arr?.reduce((prev, curr) => (curr?.value < prev ? curr?.value : prev), Infinity) || 0
const getMax = (arr) => arr?.reduce((prev, curr) => (curr?.value > prev ? curr?.value : prev), 0) || 0

export const BarberGoalsResume = ({ selectedDay }) => {
  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])

  const theme = useTheme()

  const [graphicByMax, setGraphicByMax] = useState(false)

  const { tabIndexes, initialTab, tabToindex, indexToTab } = useTabs()
  const [selectedTab, setSelectedTab] = useState(initialTab)

  const { userData } = useBarbershopStore()
  const barbershopId = userData?.id
  const signatureEnabled = selectedTab === 'signatureEnabled'

  const barberGoalsQuery = useBarberGoalsQuery()
  const { data, isLoading } = barberGoalsQuery({ selectedDay, signatureEnabled, barbershopId })

  const tabsData = useMemo(() => {
    const filteredByCategory = {
      product: data?.filter(({ barber_goals }) => barber_goals?.some((t) => t?.type === 'product')),
      service: data?.filter(({ barber_goals }) =>
        barber_goals?.some((t) => t?.type === 'service' && t?.signature_enabled === false)
      ),
      serviceSignatureEnabled: data?.filter(({ barber_goals }) =>
        barber_goals?.some((t) => t?.type === 'service' && t?.signature_enabled === true)
      ),
      totalSales: data?.filter(({ barber_goals }) =>
        barber_goals?.some((t) => t?.type === 'total_sales' && t?.signature_enabled === false)
      ),
      signatureEnabled: data?.filter(({ barber_goals }) =>
        barber_goals?.some((t) => t?.type === 'total_sales' && t?.signature_enabled === true)
      ),
    }

    return {
      [tabIndexes[0]]: filteredByCategory?.product?.map(({ collaborator, barber_goals, products_value }) => {
        const filtered = barber_goals?.filter?.(({ type }) => type === 'product')

        return {
          name: collaborator?.name || '',
          profile_image_url: collaborator?.profile_image_url,
          total: products_value,
          min: getMin(filtered),
          max: getMax(filtered),
        }
      }),
      [tabIndexes[1]]: filteredByCategory?.service?.map(
        ({ collaborator, barber_goals, services_value, signature_value }) => {
          const filtered = barber_goals?.filter?.(
            ({ type, signature_enabled }) => type === 'service' && signature_enabled === false
          )

          return {
            name: collaborator?.name || '',
            profile_image_url: collaborator?.profile_image_url,
            total: services_value,
            min: getMin(filtered),
            max: getMax(filtered),
          }
        }
      ),
      [tabIndexes[2]]: filteredByCategory?.serviceSignatureEnabled?.map(
        ({ collaborator, barber_goals, services_value, signature_value }) => {
          const filtered = barber_goals?.filter?.(
            ({ type, signature_enabled }) => type === 'service' && signature_enabled === true
          )

          return {
            name: collaborator?.name || '',
            profile_image_url: collaborator?.profile_image_url,
            total: services_value + signature_value,
            min: getMin(filtered),
            max: getMax(filtered),
          }
        }
      ),
      [tabIndexes[3]]: filteredByCategory?.signatureEnabled?.map(
        ({ collaborator, barber_goals, type, signature_value, total_value }) => {
          const filtered = barber_goals?.filter?.(
            ({ signature_enabled }) => type === 'total_sales' && signature_enabled === true
          )

          return {
            name: collaborator?.name || '',
            profile_image_url: collaborator?.profile_image_url,
            total: signature_value + total_value,
            min: getMin(filtered),
            max: getMax(filtered),
          }
        }
      ),
      [tabIndexes[4]]: filteredByCategory?.totalSales?.map(({ collaborator, barber_goals, total_value }) => {
        const filtered = barber_goals?.filter?.(
          ({ type, signature_enabled }) => type === 'total_sales' && signature_enabled === false
        )

        return {
          name: collaborator?.name || '',
          profile_image_url: collaborator?.profile_image_url,
          total: total_value,
          min: getMin(filtered),
          max: getMax(filtered),
        }
      }),
    }
  }, [data])

  const graphicData = useMemo(() => {
    const selectedTabsData = tabsData[selectedTab]

    return {
      series: [
        {
          name: 'Total Vendido',
          group: 'total',
          color: '#E5B817',
          data: selectedTabsData?.map(({ total }) => total),
        },
        {
          name: graphicByMax ? 'Meta Máxima Prevista' : 'Meta Mínima Prevista',
          group: 'prev',
          color: '#FFF',
          data: selectedTabsData?.map(({ max, min }) => (graphicByMax ? max : min)),
        },
      ],
      categories: selectedTabsData?.map(({ name }) => name),
    }
  }, [tabsData, selectedTab, graphicByMax])

  return (
    <Box h="100vh" w="100%" pt={theme.pxToRem(12)}>
      <MultiColumnChart
        renderTop={() => (
          <Flex w="100%" align="center">
            <Button
              size="x-small"
              kind={graphicByMax ? 'dashed' : 'solid'}
              onClick={() => setGraphicByMax(!graphicByMax)}
            >
              Ver Meta Máxima
            </Button>
          </Flex>
        )}
        h="400px"
        w="100%"
        series={graphicData?.series || []}
        type="money"
        title="Metas dos colaboradores"
        name="Metas dos colaboradores"
        categories={graphicData?.categories || []}
        isLoading={isLoading}
      />

      <Tabs w="100%" tabIndex={tabToindex(selectedTab)} onChange={(index) => setSelectedTab(indexToTab(index))}>
        <TabList color="textGrey" borderColor="modalBorder" borderBottomWidth={4}>
          <Tab
            w="33%"
            fontSize={14}
            fontWeight={900}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            Metas por produtos
          </Tab>
          <Tab
            w="33%"
            fontSize={14}
            fontWeight={900}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            Metas por serviços - Sem assinatura
          </Tab>
          <Tab
            w="33%"
            fontSize={14}
            fontWeight={900}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            Metas por serviços - Com assinatura
          </Tab>
          <Tab
            w="33%"
            fontSize={14}
            fontWeight={900}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            Metas gerais - Com assinatura
          </Tab>
          <Tab
            w="33%"
            fontSize={14}
            fontWeight={900}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            Metas gerais - Sem assinatura
          </Tab>
        </TabList>
      </Tabs>

      <Box flex={1} mt="12px">
        <Table data={tabsData?.[selectedTab]} loading={isLoading} columns={columns} />
      </Box>
    </Box>
  )
}
