import React from 'react'
import { format, set } from 'date-fns'
import { Text, Icon } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import pt from 'date-fns/locale/pt-BR'

export const DateTimeCard = ({ date, time, onClick, finishHour, recurrent }) => {
  const theme = useTheme()
  const formattedDate =
    !recurrent &&
    date &&
    set(date, {
      hours: time?.substring(0, 2),
      minutes: time?.substring(3, 5),
    })

  const formattedHour =
    !recurrent &&
    date &&
    format(formattedDate, 'HH:mm', {
      locale: pt,
    })

  const formattedText =
    !recurrent &&
    date &&
    format(formattedDate, 'EEEE, dd/MM', {
      locale: pt,
    })

  return (
    <Box
      display="flex"
      flexDir="row"
      w="100%"
      background="cardBackground"
      alignItems="center"
      justifyContent="space-between"
      p={theme.pxToRem(12)}
      px={6}
      h={theme.pxToRem(68)}
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Icon name="CalendarBasic" size={18} color="primary" />

      <Flex w="100%" flexDir="row" alignItems="center" justifyContent="space-between">
        <Flex flexDir="column">
          <Text kind="bold" color={!recurrent ? 'textGrey' : 'textLight'} ml={theme.pxToRem(24)}>
            {recurrent && date
              ? `Agendamento recorrente todo dia ${format(date, 'dd')} do mês`
              : recurrent && !date
              ? 'Agendamento recorrente'
              : formattedText?.charAt(0)?.toUpperCase() + formattedText?.slice(1)}
          </Text>
          {!recurrent && (
            <Text kind="extraBold" color="textLight" ml={theme.pxToRem(24)}>
              {finishHour ? `De ${time?.substring(0, 5)} às ${finishHour?.substring(0, 5)}` : `às ${formattedHour}`}
            </Text>
          )}
        </Flex>
      </Flex>

      {onClick && <Icon cursor="pointer" onClick={onClick} name="Trash" color="baseRed" size={16} />}
    </Box>
  )
}
