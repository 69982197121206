const convertTimeToDecimal = (time) => {
  if (!time || !time?.includes(':')) return 0

  const [hours, minutes] = time.split(':')
  return Number(hours) + Number(minutes) / 60
}

const convertTimeToInteger = (time) => {
  if (!time || !time?.includes(':')) return 0

  const [hours, minutes] = time.split(':')
  return Number(hours) * 60 + Number(minutes)
}

export const productiveCapacity = (values) => {
  const workingHours = convertTimeToDecimal(values?.find((i) => i?.key === 'working_hours')?.working_hours)

  const workingDays = workingHours * values?.find((i) => i?.key === 'working_days')?.working_days ?? 0

  const workingMinutes = convertTimeToDecimal(values?.find((i) => i?.key === 'working_minutes')?.working_minutes) ?? 0

  const product_capacity =
    (workingMinutes === 0
      ? 0
      : (
          (workingDays / workingMinutes || 0) *
          (values?.find((i) => i?.key === 'working_barbers')?.working_barbers || 0)
        ).toFixed(2) ?? 0) / 100

  const occupancy_rate =
    product_capacity == 0
      ? '0'
      : ((values?.find((i) => i?.key === 'working_services')?.working_services / product_capacity) * 100).toFixed(2) ??
        0

  return { product_capacity, occupancy_rate: (occupancy_rate / 100).toFixed(2) }
}
