import React, { useState } from 'react'
import { Box, Button, Flex, Text, TextInput } from 'components/atoms'
import { ClientCard, ModalMenu, ServiceCard } from 'components/molecules'
import { useMutation, useQueryClient } from 'react-query'
import { t } from 'services/translation'
import { useToast } from '@chakra-ui/react'
import { SelectPlan } from './SelectPlan'
import { SelectClient } from './SelectClient'
import { useSignature } from 'services/hooks/use-signature'
import { useBarbershopStore } from 'services/stores'
import { ConfirmationModal } from './ConfirmationModal'

export const AddNewSubscriber = ({ isOpen, onClose }) => {
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [clientModalOpen, setClientModalOpen] = useState(false)
  const [client, setClient] = useState(null)
  const [due_date, setDueDate] = useState(null)
  const [plan, setPlan] = useState(null)
  const [status, setStatus] = useState('PENDING')
  const { createSignature } = useSignature()
  const { userData } = useBarbershopStore()
  const queryClient = useQueryClient()
  const toast = useToast()

  const mutate = useMutation('create-signature-for-client', (params) => createSignature(params), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-subscribers'])
      toast({
        title: 'Link gerado com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setClient(null)
      setDueDate(null)
      setPlan(null)
      window.open(data?.link, '_blank')
      onClose()
    },
    onError: (err) => {
      setClient(null)
      setDueDate(null)
      setPlan(null)
      toast({
        title: 'Erro ao gerar link',
        description: err.response.data.error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  return (
    <ModalMenu isOpen={isOpen} onClose={onClose} title="Gerar link para assinatura">
      <Box>
        <Box marginBottom="24px">
          <Text kind="medium" fontSize="14px" color="textGrey" mb="6px">
            {t('SELECT_CLIENT')}
          </Text>
          {client && <ClientCard client={client} onClick={() => setClient(null)} />}
          <Button mt={'12px'} size="block" kind="appointmentOptions" onClick={() => setClientModalOpen(true)}>
            {t('SELECT_CLIENT')}
          </Button>
        </Box>
        <Box marginBottom="24px">
          <Text kind="medium" fontSize="14px" color="textGrey" mb="6px">
            {t('SELECT_PLAN')}
          </Text>
          {plan && <ServiceCard marginBottom="12px" fullWidth service={plan} onDelete={() => setPlan(null)} />}
          <Button mt={'12px'} size="block" kind="appointmentOptions" onClick={() => setPlanModalOpen(true)}>
            {t('SELECT_PLAN')}
          </Button>
        </Box>
        <Box mb="24px">
          <TextInput
            noBorder
            type="date"
            name="due_date"
            value={due_date}
            onChange={(e) => setDueDate(e.target.value)}
            label="Data de vencimento"
            padding="10px"
          />
        </Box>

        <Box mt="12px" mb="24px">
          <Text marginBottom="16px" fontSize="14px" color="textGrey">
            Status
          </Text>
          <Flex flexDirection="row" justifyContent="space-between">
            <Button
              kind={status === 'PENDING' ? 'primary' : 'outline'}
              size="small"
              w="100%"
              marginRight="32px"
              onClick={() => setStatus('PENDING')}
            >
              Pendente
            </Button>
            <Button
              kind={status === 'ACTIVE' ? 'primary' : 'outline'}
              size="small"
              w="100%"
              onClick={() => setStatus('ACTIVE')}
            >
              Ativo
            </Button>
          </Flex>
        </Box>

        <ConfirmationModal
          data={{
            client: client?.client,
            plan: plan,
            dueDate: due_date,
            status,
          }}
          onConfirm={() => {
            const body = {
              value: plan.value,
              description: plan.id,
              client_id: client.client.id,
              due_date,
              status,
            }

            mutate.mutate({ barbershop_id: userData?.barbershop?.id ?? userData?.id, body })
          }}
          openButtonRender={({ onOpen }) => (
            <Button
              kind={'primary'}
              isDisabled={!client || !plan || !due_date}
              isLoading={mutate.isLoading}
              size="block"
              mb="16px"
              onClick={() => onOpen()}
            >
              {t('GENERATE_LINK')}
            </Button>
          )}
        />

        <SelectPlan isOpen={planModalOpen} onClose={() => setPlanModalOpen(false)} plan={plan} setPlan={setPlan} />

        <SelectClient
          isOpen={clientModalOpen}
          onClose={() => setClientModalOpen(false)}
          client={client}
          setClient={setClient}
        />
      </Box>
    </ModalMenu>
  )
}
