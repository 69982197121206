import React, { useState, useEffect } from 'react'
import { useTheme, Center, useToast, Flex } from '@chakra-ui/react'
import { Text, Loader, Button, SignaturePlanCard, ModalMenu, ModalAlert, SelectServiceModal } from 'components'
import { pxToRem } from 'styles/metrics'
import { formatCurrency } from 'utils/formatCurrency'
import { MobileList } from '../MobileList'
import { t } from 'services/translation'
import { format, parseISO } from 'date-fns'
import { useQuery, useMutation } from 'react-query'
import { useBarbershopSubscription, usePermissionCollaborator } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { useSignature } from 'services/hooks/use-signature'
import { ReleaseDetailsModal } from '../ReleaseDetailsModal'

export const SubscriptionDetailModal = ({ barbershop_id, client_id, signature_id, isOpen, onClose }) => {
  const theme = useTheme()
  const [enable, setEnable] = useState(false)
  const [open, setOpen] = useState(false)
  const [payment, setPayment] = useState(false)
  const { userData } = useBarbershopStore()
  const [alertOpen, setAlertOpen] = useState(false)
  const [serviceOpen, setServiceOpen] = useState(false)
  const { getSubscriptionDetails, deleteSubscription, reactivateSignature } = useBarbershopSubscription()
  const signature = usePermissionCollaborator('signature')
  const { getAllSubscriptionPayments } = useSignature()
  const toast = useToast()

  const { data, isLoading, refetch } = useQuery(
    ['get-subscriptions', signature_id, barbershop_id, client_id],
    () => getSubscriptionDetails({ signature_id, barbershop_id, client_id }),
    {
      enabled: enable,
    }
  )

  const mutatePayments = useMutation(
    ['get-subscription-payments'],
    () => getAllSubscriptionPayments(data?.signature?.data?.id),
    {
      onSuccess: () => {
        refetch()
        toast({
          title: 'Pagamentos atualizados com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const mutateReactive = useMutation('mutate-reactive', (params) => reactivateSignature(params))

  useEffect(() => {
    if (signature_id && barbershop_id && client_id) {
      setEnable(true)
    } else {
      setEnable(false)
    }
  }, [signature_id, barbershop_id, client_id])

  const mutate = useMutation('delete-signature', (params) => deleteSubscription(params))

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
    >
      <Flex flexDirection="column" h="100%" overflow="scroll">
        {isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <>
            <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
              {t('PLAN_DETAIL')}
            </Text>
            <Flex flexDirection="row">
              <Text mr={theme.pxToRem(6)}>{t('CURRENT_PLAN')}</Text>
              <Text kind="bold" color="primary">
                {userData?.version === 'v3' ? data?.signature?.data?.product?.name : data?.signature?.data?.plan?.name}
              </Text>
            </Flex>
            <Flex mt={theme.pxToRem(24)} flexDirection="row">
              <Text mr={theme.pxToRem(6)}>{t('VALUE')}:</Text>
              <Text kind="bold">
                {formatCurrency(
                  Number(
                    userData?.version === 'v3'
                      ? data?.signature?.data?.product?.price
                      : data?.signature?.data?.plan?.value
                  )
                )}
              </Text>
            </Flex>
            <Flex mt={theme.pxToRem(24)} flexDirection="row">
              <Text mr={theme.pxToRem(6)}>{t('STATUS')}:</Text>
              <Text kind="bold" color={data?.signature?.data?.status === 'ACTIVE' ? 'success' : 'error'}>
                {t(data?.signature?.data?.status)}
              </Text>
            </Flex>
            {(data?.signature?.data?.change_type === 'CANCEL' ||
              data?.signature?.data?.change_type === 'DOWNGRADE') && (
              <Text mt={8} color={'primary'}>
                {`${t(
                  data?.signature?.data?.change_type === 'CANCEL'
                    ? 'APPOINTMENT_CONDITION_CANCEL'
                    : data?.signature?.data?.change_type === 'DOWNGRADE'
                    ? 'APPOINTMENT_CONDITION_DOWNGRADE'
                    : null
                )}${format(
                  parseISO(
                    userData?.version === 'v3'
                      ? data?.signature?.data?.due_date || data?.signature?.due_date
                      : data?.signature?.data?.due_date || data?.due_date
                  ),
                  'dd/MM/yyyy'
                )}`}
              </Text>
            )}
            <Flex mt={theme.pxToRem(48)} flexDirection="column" w="100%">
              <Text kind="bold">{t('LAST_PAYMENTS')}</Text>
              <MobileList
                emptyListMessage={t('PAYMENT_NOT_FOUND')}
                data={data?.signature?.data?.subscription_releases || []}
                loading={isLoading}
                renderItem={({ item }) => (
                  <SignaturePlanCard
                    key={item.id}
                    item={item}
                    onClick={() => {
                      setPayment(item)
                      setOpen(true)
                    }}
                  />
                )}
              />
            </Flex>
          </>
        )}
      </Flex>
      <Flex h="5%" mb="-60px" flexDir="row">
        <Button
          w={signature === 'edit' && data?.signature?.data?.change_type !== 'CANCEL' ? '49%' : '100%'}
          mr="2%"
          type="submit"
          isLoading={mutatePayments.isLoading}
          onClick={() => mutatePayments.mutate()}
        >
          Atualizar pagamentos
        </Button>
        {signature === 'edit' && data?.signature?.data?.change_type !== 'CANCEL' && (
          <Flex w="49%">
            <Button w="100%" type="submit" onClick={() => setAlertOpen(true)} kind="danger">
              {t('CANCEL_SIGNATURE')}
            </Button>
          </Flex>
        )}
      </Flex>

      <ModalAlert
        isOpen={alertOpen}
        onClose={() => setAlertOpen(false)}
        onConfirm={() =>
          mutate.mutate({
            id: signature_id,
            onSuccess: () => {
              setAlertOpen(false)
              onClose()
            },
          })
        }
        primaryButtonLabel={t('YES')}
        modalTitle={t('DELETE')}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('CANCEL_SIGNATURE_DESC')}
        </Text>
      </ModalAlert>

      <SelectServiceModal
        isModalOpen={serviceOpen}
        onClose={() => setServiceOpen(false)}
        client_id={client_id}
        barbershop_id={barbershop_id}
      />
      <ReleaseDetailsModal isOpen={open} onClose={() => setOpen(false)} refetch={refetch} payment={payment} />
    </ModalMenu>
  )
}
