import React from 'react'
import { Text, Avatar, Button } from 'components/atoms'
import { useTheme, Table as ChakraTable, Thead, Tbody, Tr, Td, Th, Center, Box, Spinner } from '@chakra-ui/react'
import { formatDate } from 'utils'
import PropTypes from 'prop-types'
import { TrRow } from './styles'
import { ChevronRight } from 'assets/icons'
import { Trash } from 'assets/icons'
import { colors } from 'styles'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { formatPhone } from 'utils/phone-formater'
import { useSingleSaleStore } from 'services/stores'
import { useBarbershopStore } from 'services/stores'

const defaultValues = {
  emptyListMessage: 'EMPTY_LIST_MESSAGE',
  emptyValueMessage: '',
  column: [],
  data: [],
}

export const Table = ({
  data,
  columns,
  children,
  deleteButtom,
  loading,
  cardLoading,
  cardDisabled,
  fluid,
  emptyListMessage = defaultValues.emptyListMessage,
  onSelect = () => {},
  isSelectableRow,
  isMultiSelectable,
  isSelectableRowVariabled,
  isProductsTable = false,
  h,
  containSwitch,
  isFlag,
  bgRow,
  selectedList,
  selected,
  hasMore,
  ...props
}) => {
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const theme = useTheme()
  const { userData } = useBarbershopStore()
  // const [selecteds, setSelecteds] = useState([])
  const { setProducts, removeProduct, products, nullProducts } = useSingleSaleStore()

  const getWidth = React.useCallback(
    (width) => () => {
      if (!width) return null

      return typeof width === 'string' ? width : theme.pxToRem(width)
    },
    [theme]
  )

  const formatValue = React.useCallback((value, formatColumn, key) => {
    if (value instanceof Date) {
      return typeof formatColumn === 'function' ? formatColumn(value) : formatDate(value, formatColumn)
    }
    if (key === 'phone') {
      return formatPhone(value, userData)
    }
    return value
  }, [])

  const onSelectRow = (item, index, column) => {
    if (cardDisabled) {
      return
    }

    if (isProductsTable) {
      if (products?.find((product) => product.id === item.id)) {
        removeProduct(item)
      } else {
        if (isFlag) {
          if (item.id === 0) {
            nullProducts()
            setProducts(item)
            return
          }
          if (item.id !== 0 && products?.find((product) => product.id === 0)) {
            nullProducts()
            setProducts(item)
            return
          }
          if (products?.find((i) => i.id === 0)) {
            return null
          }
        }
        setProducts(item)
      }
    }

    onSelect(item, index, column)
  }

  const rowBorderColor = (item) => {
    const first_condition = (isSelectableRow || isSelectableRowVariabled) && isMultiSelectable
    if (isProductsTable && first_condition) {
      return products?.find((selected) => item?.id === selected?.id) ? 'primary' : 'modalBorder'
    }
    if (selectedList && first_condition) {
      return selectedList?.find((selected) => item?.id === selected?.id) ? 'primary' : 'modalBorder'
    }
    return selected === item ? 'primary' : 'modalBorder'
  }
  return (
    <Box width={!!fluid && '100%'} flex={1} borderRadius={theme.pxToRem(4)}>
      <ChakraTable width="100%" style={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
        {!props?.withoutHeader && (
          <Thead bgColor="transparent">
            <Tr>
              {columns.map((column, index) => (
                <Th
                  width={getWidth(column.width)}
                  color="textLight"
                  key={`column_${column.key}_${index}`}
                  borderColor="transparent"
                  fontFamily="Montserrat SemiBold"
                >
                  {column?.columnRender || t(column.label)}
                </Th>
              ))}
              {!!children && <Th key={`column_action`} width="10%" borderColor="transparent" />}
            </Tr>
          </Thead>
        )}
        <Tbody height="fit-content">
          {!loading &&
            data &&
            !!data.length &&
            data.map((item, index) => {
              return (
                <TrRow
                  key={`data_${index}`}
                  style={{
                    cursor: `${isSelectableRowVariabled?.[index] || isSelectableRow ? 'pointer' : ''}`,
                  }}
                  bgRow={bgRow}
                >
                  {cardLoading && (
                    <Td
                      key={`data_loading_${index}`}
                      borderWidth={1}
                      borderColor={rowBorderColor(item)}
                      bg="cardBackground"
                      py={2}
                    >
                      <Spinner color="white" />
                    </Td>
                  )}

                  {columns.map((column) => (
                    <Td
                      key={`data_${column.key}_${index}`}
                      borderWidth={1}
                      borderColor={rowBorderColor(item)}
                      bg="cardBackground"
                      onClick={() => onSelectRow(item, index, column)}
                      py={2}
                    >
                      {column.type === 'image' ? (
                        <Avatar
                          type={column.placeholder}
                          size={isDesktop ? 'medium' : 'mobile'}
                          src={column.render ? column.render(item, index) : item[column.key]}
                        />
                      ) : (
                        <Text
                          width={getWidth(column.width)}
                          fontSize={isDesktop ? theme.pxToRem(14) : theme.pxToRem(11)}
                        >
                          {column.render
                            ? column.render(item, index, cardDisabled)
                            : formatValue(item[column.key], column.valueFormat, column.key)}
                        </Text>
                      )}
                    </Td>
                  ))}

                  {!!children && (
                    <Td key={`data_action_${index}`} borderColor="transparent">
                      <Text>{children(item, index)}</Text>
                    </Td>
                  )}

                  {(isSelectableRowVariabled?.[index] || isSelectableRow) && isDesktop && (
                    <Td
                      key={`data_action_${index}`}
                      bg="cardBackground"
                      w={theme.pxToRem(20)}
                      borderWidth={1}
                      borderColor={rowBorderColor(item)}
                      alignSelf="end"
                      onClick={() => (containSwitch ? onSelectRow(item, index) : null)}
                    >
                      {(typeof deleteButtom === 'object' ? deleteButtom[index] : deleteButtom) ? (
                        <Trash
                          color={cardDisabled ? colors.agendaIntervalBackground : colors.danger}
                          style={{ fontWeigth: 'bold' }}
                        />
                      ) : (
                        <ChevronRight
                          color={cardDisabled ? colors.agendaIntervalBackground : colors.primary}
                          style={{ fontWeigth: 'bold' }}
                        />
                      )}
                    </Td>
                  )}
                  {!!isSelectableRowVariabled && !isSelectableRowVariabled?.[index] && (
                    <Td
                      key={`data_action_${index}`}
                      bg="cardBackground"
                      w={theme.pxToRem(20)}
                      borderWidth={1}
                      borderColor="modalBorder"
                      alignSelf="end"
                    ></Td>
                  )}
                </TrRow>
              )
            })}
        </Tbody>
      </ChakraTable>
      {hasMore && (
        <Button mt="24px" width="100%" disabled={hasMore?.disabled} onClick={hasMore?.onClick}>
          {t('LOAD_MORE')}
        </Button>
      )}
      {(!data || !data.length) && !loading && emptyListMessage !== 'none' && (
        <Center height={theme.pxToRem(props?.height || 450)}>
          <Text color="textLight">{t(emptyListMessage)}</Text>
        </Center>
      )}
      {loading && (
        <Center height={theme.pxToRem(450)}>
          <Spinner color="white" />
        </Center>
      )}
    </Box>
  )
}

Table.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      subKey: PropTypes.string,
      width: PropTypes.number,
      hasSort: PropTypes.bool,
      render: PropTypes.func,
      columnRender: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
      emptyValueMessage: PropTypes.string,
      valueFormat: PropTypes.oneOf([PropTypes.string, PropTypes.func]),
      mobileProps: PropTypes.shape({
        fluid: PropTypes.bool,
        renderOnHeader: PropTypes.bool,
      }),
    })
  ),
  children: PropTypes.any,
  emptyListMessage: PropTypes.string,
  emptyValueMessage: PropTypes.string,
  widthOptions: PropTypes.number,
  fluid: PropTypes.bool,
  onSelect: PropTypes.func,
  selecteds: PropTypes.array,
  isSelectableRow: PropTypes.bool,
}
