import React from 'react'
import PropTypes from 'prop-types'
import { useTheme, Flex } from '@chakra-ui/react'
import * as IconsAssets from '../../../assets/icons'

export const Icon = ({ name, color, size, onClick, ...props }) => {
  const AssetIcon = IconsAssets[name] || IconsAssets.InterrogationCircle
  const theme = useTheme()
  return (
    <Flex {...props}>
      <AssetIcon
        {...props}
        onClick={onClick}
        height={size ? theme.pxToRem(size) : theme.pxToRem(24)}
        width={size ? theme.pxToRem(size) : theme.pxToRem(24)}
        color={color ? theme.colors[color] : theme.colors.brand.primary}
      />
    </Flex>
  )
}

Icon.propTypes = {
  ...Flex.propTypes,
  name: PropTypes.string,
  height: PropTypes.number,
  size: PropTypes.number,
  color: PropTypes.string,
}
