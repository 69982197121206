import React, { useEffect, useState } from 'react'
import { Layout, Table, ServicesModal, MobileList, ServiceCard, PremiumBlockMessage, Text, Icon } from 'components'
import { Flex, Spinner, useToast } from '@chakra-ui/react'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useBreakpoint } from 'services/hooks/use-breakpoint'
import { useTranslation } from 'react-i18next'
import { usePremiumBlock } from 'services/hooks/use-premium-block'
import { useTheme } from '@chakra-ui/react'
import { usePermissionCollaborator } from 'services/hooks'
import { useMutation, useQuery } from 'react-query'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { formatPrice } from 'utils'
import { useServices } from 'services/hooks/use-services'

export const ServicesScreen = ({ ...props }) => {
  const { userData } = useBarbershopStore()
  const [serviceModalOpen, setServiceModalOpen] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const isBlocked = usePremiumBlock()
  const theme = useTheme()
  const permission = usePermissionCollaborator('services')
  const { getServices } = useBarbershop()
  const { onLogout } = useLogoutPaymentIssues()
  const { updateServiceOrder } = useServices()

  const { data, isLoading, refetch } = useQuery('get-services', () => getServices({ id: userData?.id }))

  const mutateOrder = useMutation('update-service-order', (params) => updateServiceOrder(params), {
    onSuccess: async () => {
      toast({
        title: 'Ordem atualizada com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      refetch()
    },
    onError: (error) => {
      toast({
        title: 'Erro ao atualizar ordem',
        description: error.toString().substring(7),
        status: 'danger',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const columns = [
    {
      key: 'order',
      label: 'ORDENAR',
      type: 'order',
      width: 50,
      render: (item, index) => {
        return (
          <Flex flexDirection="column">
            {mutateOrder.isLoading && <Spinner />}
            {index !== 0 && !mutateOrder.isLoading && (
              <Icon
                name="ArrowUp"
                color="primary"
                onClick={(e) => {
                  mutateOrder.mutate({ body: { service_id: item?.id, barbershop_id: userData?.id, type: 'up' } })
                }}
                disabled={true}
              />
            )}
            {data?.length - 1 !== index && !mutateOrder.isLoading && (
              <Icon
                name="ArrowDown"
                color="primary"
                onClick={(e) => {
                  mutateOrder.mutate({ body: { service_id: item?.id, barbershop_id: userData?.id, type: 'down' } })
                }}
              />
            )}
          </Flex>
        )
      },
    },
    { key: 'name', label: 'NAME_TABLE' },
    {
      key: 'price',
      label: 'VALUE_TABLE',
      render: (item) => <Text>{item?.type === 'signature' ? 'Serviço pelo clube' : formatPrice(item?.price)}</Text>,
    },
    {
      key: 'time_required',
      label: 'TIME_TABLE',
      render: (item) => <Text>{item?.time_required?.substring?.(0, 5)}</Text>,
    },
    {
      key: 'visible_for_clients',
      label: 'VISÍVEL P/ CLIENTES',
      render: (item) => <Text>{!item?.visible_for_clients ? 'Não' : 'Sim'}</Text>,
    },
    {
      key: 'type',
      label: 'TYPE',
      render: (item) => <Text>{t(item?.type)}</Text>,
    },
  ]
  useEffect(() => {
    onLogout()
  }, [])

  const onCloseServiceModal = () => {
    setServiceModalOpen(false)
    setSelectedService(null)
    refetch()
  }

  const onClickAddServiceButton = () => {
    setSelectedService(null)
    setServiceModalOpen(true)
  }

  const onClickTableRow = (selectedRow) => {
    if (permission !== 'blocked') {
      setSelectedService(selectedRow)
      setServiceModalOpen(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const freeAccountLimit = data?.length >= 4

  return (
    <Layout
      {...props}
      headerTitle={t('SERVICES_TITLE')}
      headerActionText={permission === 'edit' && t('ADD_SERVICE_BUTTON')}
      headerActionClick={() => onClickAddServiceButton()}
      premiumBlockMessage={isBlocked && freeAccountLimit && 'SERVICES_BLOCK_MESSAGE_HEADER'}
    >
      {isDesktop ? (
        <Table
          isSelectableRow
          onSelect={(item, index, column) => {
            if (column?.key !== 'order') {
              onClickTableRow(item)
            }
          }}
          data={data || []}
          loading={isLoading}
          columns={columns}
        />
      ) : (
        <MobileList
          data={data || []}
          loading={isLoading}
          renderItem={({ item, index }) => <ServiceCard inList service={item} fullWidth onSelect={onClickTableRow} />}
        />
      )}

      {isBlocked && freeAccountLimit && <PremiumBlockMessage message="SERVICES_BLOCK_MESSAGE" mt={theme.pxToRem(16)} />}

      <ServicesModal isOpen={serviceModalOpen} onClose={onCloseServiceModal} selectedService={selectedService} />
    </Layout>
  )
}

ServicesScreen.path = '/servicos'
ServicesScreen.title = 'Serviços'
ServicesScreen.secured = true
