import React, { useEffect, useRef, useState } from 'react'
import { useTheme, Flex } from '@chakra-ui/react'
import { Box, Logo, Avatar, Icon, Text } from 'components/atoms'
import { MenuItem } from 'components/molecules'
import { StatusModal } from 'components/organisms'
import { useTranslation } from 'react-i18next'
import { useMenuCollapse } from 'services/stores'

export const MenuDesktop = ({
  path,
  userData,
  setOpenStatusModal,
  handleUserStatus,
  onClickToNavigate,
  openStatusModal,
  options,
  isInternalAdm,
  isBannerOpen,
  permissions,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { collapsed, setCollapsed } = useMenuCollapse()
  const [open, setOpen] = useState({
    clients:
      path === '/relatorio-clientes' ||
      path === '/relatorio-frequencia-clientes' ||
      path === '/relatorio-novos-clientes' ||
      path === '/relatorio-clientes-primeiro-agendamento' ||
      path === '/relatorio-clientes-ultimo-agendamento'
        ? true
        : false,
    goals: path === '/metas-resumo-v2' || path === '/metas-barbeiro-v2' ? true : false,
    goals: path === '/metas-resumo-v2' || path === '/metas-barbeiro-v2' ? true : false,
    '/extrato-simplificado': path === '/extrato-simplificado' || path === '/extrato-detalhado' ? true : false,
    nf:
      path === '/nota-fiscal/historico' ||
      path === '/nota-fiscal/configuracoes' ||
      path === '/nota-fiscal/dados-da-equipe',
  })

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      h="100vh"
      w={collapsed ? theme.pxToRem(88) : theme.pxToRem(240)}
      position="fixed"
      left="0"
      bg="menuBackground"
      borderRightWidth={1}
      borderColor="modalBorder"
      overflow="hidden"
      zIndex={5}
      _hover={{ overflowY: 'auto' }}
    >
      <Flex
        alignItems="center"
        justifyContent={collapsed ? 'center' : 'space-between'}
        px={theme.pxToRem(12)}
        py={theme.pxToRem(16)}
        w="100%"
        backgroundColor="cardBackground"
        borderBottomWidth={1}
        borderColor="modalBorder"
      >
        <Logo />
        {/* {!collapsed ? (
          <Logo />
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            w={theme.pxToRem(40)}
            h={theme.pxToRem(40)}
            backgroundColor="menuBackground"
            borderRadius={theme.pxToRem(8)}
            cursor="pointer"
            onClick={() => setCollapsed(false)}
          >
            <Icon name="ArrowRight" size={16} />
          </Flex>
        )}
        {!collapsed && (
          <Box p={1} cursor="pointer" onClick={() => setCollapsed(true)}>
            <Icon name="ArrowLeft" size={16} />
          </Box>
        )} */}
      </Flex>

      {!isInternalAdm && (
        <>
          <Flex
            flexDir={collapsed ? 'column' : 'row'}
            alignItems="center"
            px={theme.pxToRem(12)}
            py={theme.pxToRem(12)}
            w="100%"
            backgroundColor="cardBackground"
            borderBottomWidth={1}
            borderColor="modalBorder"
          >
            <Avatar size="menu" src={userData?.profile_image_url} />

            <Box marginLeft={!collapsed && theme.pxToRem(16)} marginTop={collapsed && theme.pxToRem(16)}>
              {!collapsed && (
                <Text
                  fontSize={theme.pxToRem(14)}
                  lineHeight={theme.pxToRem(20)}
                  kind="extraBold"
                  marginBottom={theme.pxToRem(8)}
                  maxW={theme.pxToRem(100)}
                  noOfLines={2}
                >
                  {userData?.name}
                </Text>
              )}

              <Flex
                alignItems="center"
                padding={theme.pxToRem(8)}
                backgroundColor="menuBackground"
                borderRadius={25}
                cursor="pointer"
                onClick={() => setOpenStatusModal(true)}
              >
                <Box
                  backgroundColor={handleUserStatus?.color}
                  borderRadius={100}
                  width={theme.pxToRem(14)}
                  height={theme.pxToRem(14)}
                />

                {!collapsed && (
                  <Text fontSize={theme.pxToRem(14)} marginLeft={theme.pxToRem(8)} kind="normal" color="textGrey">
                    {t(handleUserStatus?.label)}
                  </Text>
                )}
                <Icon name="ChevronDown" marginLeft={theme.pxToRem(8)} size={13} />
              </Flex>
            </Box>

            <StatusModal isModalOpen={openStatusModal} onClose={() => setOpenStatusModal(false)} />
          </Flex>
        </>
      )}

      <Box w="full" py={theme.pxToRem(collapsed ? 40 : 4)}>
        {!!options?.length &&
          options.map((item, index) => {
            if (item?.permission !== 'blocked') {
              return collapsed ? (
                <Flex key={index} flexDir="column" justifyContent="center" alignItems="center" w="full">
                  {!!item?.options?.length &&
                    (item?.options).map((subItem, index) => {
                      if (subItem?.permission !== 'blocked') {
                        return (
                          <MenuItem
                            key={index}
                            collapsed={collapsed}
                            selected={subItem?.path === path}
                            item={{ ...subItem }}
                            onClick={() => onClickToNavigate(subItem?.path)}
                          />
                        )
                      }
                    })}
                </Flex>
              ) : (
                <Box key={index} px={theme.pxToRem(4)} marginBottom={theme.pxToRem(8)} w="full">
                  <Text
                    color="textMedium"
                    marginLeft={theme.pxToRem(-4)}
                    pl={theme.pxToRem(12)}
                    py={theme.pxToRem(6)}
                    kind="bold"
                    fontSize={theme.pxToRem(12)}
                    bg="backgroundLayout"
                    w="240px"
                  >
                    {t(item?.section).toUpperCase()}
                  </Text>
                  {!!item?.options?.length &&
                    (item?.options).map((subItem, index) => {
                      if (subItem?.permission !== 'blocked') {
                        return (
                          <>
                            <MenuItem
                              selected={subItem?.path === path}
                              key={index}
                              item={{ ...subItem }}
                              hasSubitem={!!subItem?.options?.length}
                              onClick={(event) =>
                                !!subItem?.options?.length
                                  ? setOpen({
                                      ...open,
                                      [subItem?.path]: !open[subItem?.path],
                                    })
                                  : onClickToNavigate(subItem?.path, event)
                              }
                            />
                            {!!subItem?.options?.length &&
                              open[subItem?.path] &&
                              subItem?.options?.map((sItem) => (
                                <MenuItem
                                  selected={sItem?.path === path}
                                  key={index}
                                  item={{ ...sItem }}
                                  isSubItem
                                  onClick={() => onClickToNavigate(sItem?.path)}
                                />
                              ))}
                          </>
                        )
                      }
                    })}
                </Box>
              )
            }
          })}
      </Box>
    </Flex>
  )
}
