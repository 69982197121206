import React, { useMemo, useEffect, useState } from 'react'
import { Box, Button, Flex, Text } from 'components/atoms'
import { ModalMenu, ModalAlert, Form } from 'components/molecules'
import { useTheme, useToast, Select } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { initial, validation, data, types } from './settings'
import { clearCurrency } from 'utils/clearCurrency'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from 'services/api/config'
import {
  CREATE_ADDITIONAL_PRODUCTS,
  UPDATE_ADDITIONAL_PRODUCTS,
  DELETE_ADDITIONAL_PRODUCTS,
} from 'services/api/endpoints'
import { useBarbershop } from 'services/hooks'
import { additionalProductsStore } from 'services/stores/additionalProducts'

export const AdditionalProductsModal = ({ onClose, isModalOpen, selectedProduct, addQuantityAction }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const toast = useToast()
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [producer, setProducer] = useState()
  const [producersList, setProducersList] = useState()
  const [type, setType] = useState()
  const { userData } = useBarbershopStore()
  const [values, getValues] = useState({ params: initial, isValid: false })
  const { getAdditionalProductsProducers, getAllEmployees } = useBarbershop()
  const { setEntryType } = additionalProductsStore()
  const queryClient = useQueryClient()
  const [barber, setBarber] = useState()
  const [barbers, setBarbers] = useState([])

  const updatingProduct = useMemo(() => !!selectedProduct?.id, [selectedProduct])
  useEffect(() => {
    updatingProduct &&
      getValues({
        params: {
          name: selectedProduct?.name,
          price: selectedProduct?.price,
          cost_price: selectedProduct?.cost_price,
          minimum_quantity: selectedProduct?.minimum_quantity,
          percentage: selectedProduct?.percentage,
          codigo_ncm: selectedProduct?.codigo_ncm,
        },
        isValid: values?.isValid,
      })
    setProducer(selectedProduct?.producer)
    setType(selectedProduct?.type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingProduct, selectedProduct])

  async function changeAdditionalProducts(params) {
    const res = await api[updatingProduct ? 'put' : 'post'](
      updatingProduct ? UPDATE_ADDITIONAL_PRODUCTS : CREATE_ADDITIONAL_PRODUCTS,
      params
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('change-additional-products', (params) => changeAdditionalProducts(params), {
    onSuccess: (data) => {
      toast({
        title: updatingProduct ? t('SUCCESS_PRODUCTS_UPDATED') : t('SUCCESS_PRODUCTS_CREATED'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      getValues({ params: initial, isValid: false })
      setProducer(null)
      setType(null)
      onClose(false)
    },
    onSettled: () => {
      queryClient.invalidateQueries('get-products-by-barber')
      queryClient.invalidateQueries('get-additional-products')
    },
    onError: (error) => {
      toast({
        title: updatingProduct ? t('ERROR_UPDATE_PRODUCTS') : t('ERROR_CREATE_PRODUCTS'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  async function deleteAdditionalProduct(params) {
    const res = await api
      .delete(DELETE_ADDITIONAL_PRODUCTS(selectedProduct?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation('delete-additional-products', (params) => deleteAdditionalProduct(params), {
    onSuccess: (data) => {
      toast({
        title: t('SUCCESS_PRODUCTS_DELETE'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setAlertModalOpen(false)
      getValues({ params: initial, isValid: false })
      onClose()
    },
    onError: (error) => {
      toast({
        title: t('ERROR_DELETE_PRODUCTS'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  useQuery('get-additional-products-producers', () => getAdditionalProductsProducers({ id: userData?.id }), {
    onSuccess: (data) => {
      let newArr = data
      newArr?.push({ producer: 'Outro' })
      setProducersList(newArr)
    },
  })

  const handleSubmitProduct = async (payload) => {
    let params
    const price = typeof payload?.price === 'string' ? payload?.price.replace(/\,/g, '.') : payload?.price
    const cost_price =
      typeof payload?.cost_price === 'string' ? payload?.cost_price.replace(/\,/g, '.') : payload?.cost_price
    const percentage =
      typeof payload?.percentage === 'string'
        ? Number(payload?.percentage?.toString()?.replace?.('%', ''))
        : payload?.percentage
    if (updatingProduct) {
      params = {
        product_id: selectedProduct?.id,
        name: payload?.name,
        price:
          payload?.price === 'number' || payload?.price?.includes?.('.')
            ? payload?.price
            : clearCurrency?.(payload?.price),
        cost_price:
          typeof payload?.cost_price === 'number' || payload?.cost_price?.includes('.')
            ? payload?.cost_price
            : clearCurrency(payload?.cost_price),
        minimum_quantity: payload?.minimum_quantity,
        producer: producer === 'Outro' || !producer ? payload?.producer : producer,
        type,
        percentage,
        codigo_ncm: payload?.codigo_ncm && payload?.codigo_ncm?.length > 0 ? payload?.codigo_ncm : null,
        barber,
      }
    } else {
      params = {
        ...payload,
        barbershop_id: userData?.id,
        price: Number(price),
        cost_price: Number(cost_price),
        producer: producer === 'Outro' || !producer ? values?.params?.producer : producer,
        type,
        percentage,
        codigo_ncm: payload?.codigo_ncm && payload?.codigo_ncm?.length > 0 ? payload?.codigo_ncm : null,
      }
    }
    mutate.mutate(params)
  }

  const isDisabled = useMemo(() => {
    return (
      !values?.params?.name ||
      values?.params?.name?.length < 2 ||
      !values?.params?.price ||
      values?.params?.price?.length < 0 ||
      !values?.params?.cost_price ||
      values?.params?.cost_price?.length < 0 ||
      !values?.params?.quantity ||
      values?.params?.quantity?.length < 2 ||
      !producer ||
      !type
    )
  }, [values, producer, type])

  useQuery('get-only-barbers', () => getAllEmployees({ barbershop_id: userData?.barbershop?.id || userData?.id }), {
    onSuccess: (data) => {
      setBarbers([{ name: 'Todos os barbeiros', value: 'all_barbers' }, ...data])
    },
  })

  const handleSelectBarbers = (e) => {
    const [barber] = barbers.filter((item) => e.target.value === item?.name)
    setBarber(barber)
  }

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={() => {
        onClose()
        getValues({ params: initial, isValid: false })
        setProducer(null)
        setType(null)
      }}
      title={t(selectedProduct ? 'Editar produto' : 'ADD_PRODUCT_BUTTON')}
    >
      <Flex flexDir="column">
        <Box marginBottom={theme.pxToRem(12)}>
          {selectedProduct && (
            <>
              <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)}>
                Selecione para quem deve ser essa alteração
              </Text>

              <Select
                name="barber"
                placeholder="Não alterar para nenhum barbeiro"
                w="100%"
                h={theme.pxToRem(56)}
                fontFamily="Montserrat Bold"
                textColor="textLight"
                iconColor="primary"
                backgroundColor="cardBackground"
                borderWidth={1}
                borderColor="modalBorder"
                borderRadius={theme.pxToRem(8)}
                value={barber?.name}
                mb="16px"
                errorBorderColor="baseRed"
                onChange={handleSelectBarbers}
              >
                {!!barbers &&
                  barbers.map((item) => (
                    <option
                      style={{
                        backgroundColor: theme.colors.menuBackground,
                        fontFamily: 'Montserrat Medium',
                      }}
                      key={item?.id}
                    >
                      {item?.name}
                    </option>
                  ))}
              </Select>
            </>
          )}
          <Form
            initialValues={values?.params}
            validationSchema={validation}
            data={data(producer, updatingProduct)}
            getValues={getValues}
            mb={32}
          />
          <Text fontSize={14} kind="medium" mt={theme.pxToRem(24)} mb={theme.pxToRem(8)}>
            {t('PRODUCER')}
          </Text>

          <Select
            name="producer"
            placeholder={t('SELECT')}
            w="100%"
            h={theme.pxToRem(56)}
            fontFamily="Montserrat Bold"
            textColor="textLight"
            iconColor="primary"
            backgroundColor="cardBackground"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={theme.pxToRem(8)}
            value={producer || t('SELECT')}
            errorBorderColor="baseRed"
            onChange={(e) => setProducer(e.target.value)}
          >
            {!!producersList &&
              producersList?.map((item, index) => (
                <option
                  style={{
                    backgroundColor: theme.colors.menuBackground,
                    fontFamily: 'Montserrat Medium',
                  }}
                  key={`${index}_`}
                >
                  {item?.producer}
                </option>
              ))}
          </Select>
          <Text fontSize={14} kind="medium" mt={theme.pxToRem(8)} mb={theme.pxToRem(8)}>
            {t('TYPE')}
          </Text>

          <Select
            name="producer"
            placeholder={t('SELECT')}
            w="100%"
            h={theme.pxToRem(56)}
            fontFamily="Montserrat Bold"
            textColor="textLight"
            iconColor="primary"
            backgroundColor="cardBackground"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={theme.pxToRem(8)}
            value={type || t('SELECT')}
            errorBorderColor="baseRed"
            onChange={(e) => setType(e.target.value)}
          >
            {!!types &&
              types?.map((item, index) => (
                <option
                  style={{
                    backgroundColor: theme.colors.menuBackground,
                    fontFamily: 'Montserrat Medium',
                  }}
                  key={`${index}_`}
                >
                  {item?.value}
                </option>
              ))}
          </Select>
        </Box>

        <Flex w="100%">
          {updatingProduct ? (
            <Flex flexDir="column" w="100%">
              <Text fontSize={theme.pxToRem(14)} kind="regular" mb={theme.pxToRem(12)}>
                Quantidade disponível no estoque: {selectedProduct?.quantity || 'Não informado'}
              </Text>
              <Flex w="100%" justify="space-between">
                <Button
                  w="50%"
                  disabled={mutate.isLoading}
                  isLoading={mutateDelete.isLoading}
                  mr={theme.pxToRem(8)}
                  mb={theme.pxToRem(16)}
                  kind="success"
                  textTransform="uppercase"
                  onClick={() => {
                    setEntryType('entry')
                    addQuantityAction()
                  }}
                  h={theme.pxToRem(48)}
                >
                  + Entrada
                </Button>
                <Button
                  w="50%"
                  disabled={mutate.isLoading}
                  isLoading={mutateDelete.isLoading}
                  mb={theme.pxToRem(16)}
                  kind="danger"
                  textTransform="uppercase"
                  onClick={() => {
                    setEntryType('exit')
                    addQuantityAction()
                  }}
                  ml={theme.pxToRem(8)}
                  h={theme.pxToRem(48)}
                >
                  - Saída
                </Button>
              </Flex>

              <Flex w="100%">
                <Button
                  flex="1"
                  mr={theme.pxToRem(8)}
                  disabled={mutate.isLoading}
                  isLoading={mutateDelete.isLoading}
                  h={theme.pxToRem(48)}
                  kind="danger"
                  textTransform="uppercase"
                  onClick={() => setAlertModalOpen(true)}
                >
                  {t('DELETE')}
                </Button>
                <Button
                  flex="1"
                  ml={theme.pxToRem(8)}
                  onClick={() => handleSubmitProduct(values?.params)}
                  isLoading={mutate.isLoading}
                  h={theme.pxToRem(48)}
                  textTransform="uppercase"
                >
                  {t('UPDATE')}
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Button
              w="100%"
              disabled={mutate.isLoading || isDisabled}
              onClick={() => handleSubmitProduct(values?.params)}
              isLoading={mutate.isLoading}
            >
              {t('SAVE')}
            </Button>
          )}
        </Flex>
      </Flex>

      <ModalAlert
        isOpen={alertModalOpen}
        onClose={() => setAlertModalOpen(false)}
        onConfirm={() => mutateDelete.mutate()}
        primaryButtonLabel={t('DELETE')}
        modalTitle={t('CONFIRM_DELETE_PRODUCT')}
        loading={mutateDelete.isLoading}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('CONFIRM_DELETE_PRODUCT')} "{values?.params?.name}" ?
        </Text>
      </ModalAlert>
    </ModalMenu>
  )
}
AdditionalProductsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
}
