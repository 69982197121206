import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'components'
import { AppRouter } from 'routes'
import reportWebVitals from './reportWebVitals'
import 'services/firebase'
import 'services/translation'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

document.documentElement.lang = 'pt'
document.documentElement.setAttribute('translate', 'no')

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider>
        <AppRouter />
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
