import React, { useState, useEffect, useMemo } from 'react'
import { Layout, Table, Text, TextInput, Button, SelectFilter } from 'components'
import { CashierDetailsModal } from 'components-v2/organisms'
import { Center, Flex, Spinner, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { parseISO, differenceInDays, format, startOfMonth, endOfMonth } from 'date-fns'
import './hideScrollbar.css'
import { useQuery } from 'react-query'
import { useCashiers, useTeam } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { formatCurrency } from 'utils/formatCurrency'

export const CashiersReport = (props) => {
  const { userData } = useBarbershopStore()
  const toast = useToast()
  const { t } = useTranslation()

  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const [selectedItem, setSelectedItem] = useState(null)
  const [userFilter, setUserFilter] = useState('all')
  const [statusFilter, setStatusFilter] = useState('all')
  const [needRefetch, setNeedRefetch] = useState(false)
  const { getCashiersReport } = useCashiers()
  const { getAllCollaborators } = useTeam()

  const barbershop_id = useMemo(() => {
    return userData?.barbershop_id || userData?.barbershop?.id || userData?.id
  }, [userData])

  const { data: team } = useQuery('get-all-collaborators', () => getAllCollaborators({ id: barbershop_id }), {
    refetchOnWindowFocus: false,
  })

  const { data, refetch, isLoading, isRefetching } = useQuery(
    ['get-clients-resume'],
    () => getCashiersReport({ barbershop_id: userData?.id, start_date: startDate, end_date: endDate }),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setNeedRefetch(false)
      },
      onError: (error) => {
        toast({
          title: 'Erro ao buscar relatório de caixas',
          description: error?.response?.data?.error?.message || error?.response?.data?.error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      retry: false,
    }
  )

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const columns = [
    {
      key: 'created_by_user_email',
      label: 'Criado por',
      width: 180,
      render: (item) => (
        <>
          <Text noOfLines={1}>ID do usuário: {item?.created_by_user?.id}</Text>
          <Text noOfLines={1}>{item?.created_by_user?.email}</Text>
        </>
      ),
    },
    {
      key: 'open_date',
      label: 'Data do caixa',
      width: 120,
      render: (item) => <Text>{format(parseISO(item?.open_date), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'createdAt',
      label: 'Abertura',
      width: 220,
      render: (item) => (
        <>
          <Text>
            {format(parseISO(item?.createdAt), 'dd/MM/yyyy')} às {item?.open_hour}
          </Text>
          <Text kind="medium">Valor de abertura: {formatCurrency(item?.open_value)}</Text>
        </>
      ),
    },
    {
      key: 'updatedAt',
      label: 'Fechamento',
      width: 240,
      render: (item) => {
        if (!item?.close_date) {
          return <Text color="grey">-</Text>
        }
        const findCloseLogDate = item?.logs?.find((log) => log?.modify === 'close_cashier')?.date_time
        const formattedCloseDate = findCloseLogDate
          ? format(new Date(findCloseLogDate), 'dd/MM/yyyy')
          : format(parseISO(item?.updatedAt), 'dd/MM/yyyy')
        return (
          <>
            <Text>
              {formattedCloseDate} às {item?.close_hour}
            </Text>
            <Text kind="medium">Valor de fechamento: {formatCurrency(item?.close_value)}</Text>
          </>
        )
      },
    },
    {
      key: 'status',
      label: 'Status',
      width: 80,
      render: (item) => (
        <Text kind="semiBold" color={item?.inactive ? 'grey' : item?.status === 'open' ? 'success' : 'danger'}>
          {item?.inactive ? 'Excluído' : item?.status === 'open' ? 'Aberto' : 'Fechado'}
        </Text>
      ),
    },
  ]
  const filteredData = useMemo(() => {
    if (userFilter !== 'all' && !isNaN(parseInt(userFilter))) {
      const firstFilter = data?.filter((item) => {
        return item?.created_by_user?.id === parseInt(userFilter)
      })
      if (statusFilter !== 'all' && statusFilter?.length > 0) {
        return firstFilter?.filter((item) => {
          if (statusFilter === 'inactive') {
            return item?.inactive
          }
          return item?.status === statusFilter && !item?.inactive
        })
      }
      return firstFilter
    }
    if (statusFilter !== 'all' && statusFilter?.length > 0) {
      const secondFilter = data?.filter((item) => {
        if (statusFilter === 'inactive') {
          return item?.inactive
        }
        return item?.status === statusFilter && !item?.inactive
      })
      return secondFilter
    }
    return data
  }, [data, userFilter, statusFilter])

  return (
    <Layout {...props} isPremiumScreen headerTitle="Relatório de Caixas" widthTitle="100%" iconColor="black">
      <Flex mb={4}>
        <Flex>
          <TextInput
            noBorder
            type="date"
            name="start_date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
              setNeedRefetch(true)
            }}
            label={t('START')}
            padding="5%"
            width={'90%'}
          />
          <TextInput
            noBorder
            type="date"
            name="end_date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
              setNeedRefetch(true)
            }}
            label={t('END')}
            padding="5%"
            width={'90%'}
          />
        </Flex>
        <Button
          isLoading={isRefetching || isLoading}
          icon="Search"
          size="small"
          iconColor="black"
          mt="27px"
          onClick={() => {
            if (differenceInDays(parseISO(endDate), parseISO(startDate)) > 30) {
              toast({
                title: 'Período máximo de 1 mês',
                status: 'error',
                duration: 4000,
                isClosable: true,
              })
              return
            }
            refetch()
          }}
        >
          Pesquisar
        </Button>
        <SelectFilter
          ml={4}
          selW={220}
          upLabel="Filtrar por usuário"
          options={team?.map((item) => ({ name: item?.name, value: item?.user_id }))}
          value={userFilter}
          onChange={(e) => {
            setUserFilter(e)
          }}
        />
        <SelectFilter
          selW={220}
          upLabel="Filtrar por status"
          options={[
            { name: 'Aberto', value: 'open' },
            { name: 'Fechado', value: 'closed' },
            { name: 'Excluído', value: 'inactive' },
          ]}
          value={statusFilter}
          onChange={(e) => {
            setStatusFilter(e)
          }}
        />
      </Flex>
      {isLoading ? (
        <Center w="100%">
          <Spinner color="textLight" />
        </Center>
      ) : needRefetch ? (
        <Center w="100%">
          <Text mt={12} mb={12}>
            Clique novamente em Pesquisar
          </Text>
        </Center>
      ) : (
        <Flex w="100%" flexDir="column">
          <Table data={filteredData} columns={columns} isSelectableRow onSelect={(item) => setSelectedItem(item)} />
        </Flex>
      )}
      {!!selectedItem && (
        <CashierDetailsModal
          item={selectedItem}
          onClose={(close_info) => {
            setSelectedItem(null)
            if (close_info?.refetch) {
              refetch()
            }
          }}
        />
      )}
    </Layout>
  )
}

CashiersReport.path = '/relatorio-de-caixas'
CashiersReport.title = 'Relatório Caixa'
CashiersReport.secured = true
