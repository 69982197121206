import React, { useState, useMemo, useEffect } from 'react'
import { format } from 'date-fns'
import {
  Layout,
  SelectDateModal,
  SelectDateButton,
  Table,
  Text,
  AppointmentDetailsModal,
  HistoryList,
  Button,
  SelectFilter,
  CheckBox,
} from 'components'
import { useToast, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { useAppointmentDetailStore, useBarbershopStore } from 'services/stores'
import { useBarbershop } from 'services/hooks'
import { formatAppointmentTime, formatAppointmentStatus, formatPrice } from 'utils'
import { useTranslation } from 'react-i18next'

import { useQuery, useQueryClient } from 'react-query'
import { api } from 'services/api/config'
import {
  GET_APPOINTMENTS_BY_DATE,
  GET_APPOINTMENTS_BY_DATE_HISTORIC,
  GET_RECURRENT_APPOINTMENTS,
} from 'services/api/endpoints'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

const columns = [
  {
    key: 'time',
    label: 'TIME_TABLE',
    render: (item) => <Text kind="medium">{formatAppointmentTime(item)}</Text>,
  },
  {
    key: 'client',
    label: 'CLIENT_TABLE',
    render: (item) => <Text kind="medium">{item?.client?.name}</Text>,
  },
  {
    key: 'barber',
    label: 'BARBER_TABLE',
    render: (item) => <Text kind="medium">{item?.barber?.name}</Text>,
  },
  {
    key: 'service',
    label: 'SERVICE_TABLE',
    render: (item) => (
      <Text kind="medium" maxW="120px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {item?.product?.map((i) => i?.name).join(', ') || item?.service?.name}
      </Text>
    ),
  },
  {
    key: 'payment',
    label: 'PAYMENT_TABLE',
    render: (item) => (
      <Text kind="medium" maxW="120px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {item?.payment_method_choosed?.map?.((i) => i?.name)?.join(', ') || item?.payment_method_choosed?.name}
      </Text>
    ),
  },
  {
    key: 'value',
    label: 'VALUE_TABLE',
    render: (item) => (
      <Text kind="medium">
        {formatPrice(Number(item?.total_value || 0)) ||
          formatPrice(Number(item?.service?.price || 0)) ||
          formatPrice(
            item?.formatted_services?.reduce((acumulador, item) => {
              return acumulador + Number(item?.price || item?.product?.price || 0)
            }, 0)
          )}
      </Text>
    ),
  },
  {
    key: 'points',
    label: 'PONTOS',
    render: (item) => (
      <Text kind="medium">
        {item?.formatted_services?.reduce((acumulador, item) => {
          return acumulador + Number(item?.points || 0)
        }, 0)}
      </Text>
    ),
  },
  {
    key: 'status',
    label: 'STATUS_TABLE',
    render: (item) => (
      <Text
        kind="bold"
        color={
          item?.canceled_at
            ? 'textMedium'
            : item?.status === 'pending'
            ? 'danger'
            : item?.status === 'awaiting'
            ? 'primary'
            : item?.status === 'client_missed'
            ? 'textMedium'
            : item?.status === 'confirmed'
            ? 'blue'
            : !item?.status
            ? 'textMedium'
            : item?.status === 'client_arrived'
            ? 'orange'
            : 'success'
        }
      >
        {formatAppointmentStatus(item)}
      </Text>
    ),
  },
]

export const HistoricScreen = (props) => {
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [barberFilter, setBarberFilter] = useState(null)
  const [clientFilter, setClientFilter] = useState(null)
  const [clients, setClients] = useState([])
  const [recurrentFilter, setRecurrentFilter] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const [statusFilter, setStatusFilter] = useState(null)
  const [app, setApp] = useState()

  const [selectedDate, setSelectedDate] = useState(new Date())
  const { userData } = useBarbershopStore()
  const { getBarbers, getClients } = useBarbershop()

  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const onCloseModals = () => {
    setDetailsModalVisible(false)
    setApp(null)
    refetch()
    queryClient.removeQueries(['get-appointment-details'])
  }

  const onSelectAppointment = (appointment) => {
    setApp(appointment)
    setDetailsModalVisible(true)
  }

  async function getAppointments({ queryKey }) {
    const res = await api
      .get(
        GET_APPOINTMENTS_BY_DATE_HISTORIC(
          format(queryKey[1], 'yyyy-MM-dd'),
          false,
          userData?.barbershop?.id || userData?.id
        )
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const {
    data: appointmentsList,
    isLoading,
    refetch,
  } = useQuery(['get-appointments', selectedDate], getAppointments, {
    onError: (error) => {
      toast({
        title: t('APPOINTMENT_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  async function getRecurrentAppointments({ queryKey }) {
    const res = await api
      .get(GET_RECURRENT_APPOINTMENTS(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: recurrentAppointmentsList } = useQuery(['get-recurrent-appointments'], getRecurrentAppointments, {
    onError: (error) => {
      toast({
        title: t('APPOINTMENT_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const { data: barbers } = useQuery('get-team', () => getBarbers({ barbershop_id: userData.id }))

  useQuery(['get-clients'], () => getClients({ id: userData?.id }), {
    onSuccess: (data) => {
      let res = [
        ...data?.map((item) => ({
          client_id: item.client.id,
          name: item.client.name,
          image: item.client.profile_image_url,
          phone: item.client.user.phone,
          email: item.client.user.email,
        })),
      ]

      setClients(res)
    },
  })

  const filteredData = useMemo(() => {
    if (recurrentFilter === true) return appointmentsList.filter((i) => i?.repeat_every_week_day)
    if (barberFilter !== null)
      return (appointmentsList || recurrentAppointmentsList).filter((i) => i?.barber?.id === barberFilter?.id)
    if (clientFilter !== null)
      return (appointmentsList || recurrentAppointmentsList).filter((i) => i?.client?.id === clientFilter?.client_id)
    return appointmentsList
  }, [recurrentFilter, barberFilter, clientFilter, appointmentsList])

  useEffect(() => {
    if (recurrentFilter === false) return refetch()
    if (barberFilter === null) return refetch()
    if (clientFilter === null) return refetch()

    return appointmentsList
  }, [recurrentFilter, barberFilter, clientFilter, appointmentsList])

  return (
    <Layout {...props} headerTitle={t('HISTORIC_TITLE')}>
      {/* Filters */}
      <Flex mb="16px">
        <SelectDateButton date={selectedDate} setDate={setSelectedDate} onClickCenter={() => setDateModalOpen(true)} />
        <SelectFilter
          withoutPlaceholder
          onChange={(value) => setRecurrentFilter(value === 'Agendamentos recorrentes' ? true : false)}
          value={recurrentFilter === true ? 'Agendamentos recorrentes' : 'Todos os Agendamentos'}
          options={[{ name: 'Todos os Agendamentos' }, { name: 'Agendamentos recorrentes' }]}
          label="TYPE"
          ml="12px"
        />
        <SelectFilter
          onChange={(value) => setBarberFilter(barbers?.find((i) => i?.name?.trim() === value?.trim()))}
          value={barberFilter?.name}
          placeholder="Todos"
          options={barbers || []}
          label="BARBER"
        />
        <SelectFilter
          onChange={(value) => setClientFilter(clients?.find((i) => i?.name?.trim() === value?.trim()))}
          value={clientFilter?.name === null ? 'Todos' : clientFilter?.name}
          placeholder="Todos"
          options={clients || []}
          label="CLIENT"
        />
        <Flex align="center">
          <CheckBox
            onChange={(event) => setCancelled(!cancelled)}
            isChecked={cancelled}
            color="primary"
            borderColor="primary"
            size="lg"
            align="center"
          />
          <Text ml="6px" kind="semiBold">
            Exibir agendamentos cancelados
          </Text>
        </Flex>
      </Flex>
      {isDesktop ? (
        <Table
          loading={isLoading}
          data={filteredData?.filter((i) => (i?.canceled_at && cancelled ? i?.canceled_at : !i?.canceled_at)) || []}
          columns={columns}
          isSelectableRow
          onSelect={onSelectAppointment}
        />
      ) : (
        <HistoryList
          loading={isLoading}
          data={filteredData?.filter((i) => (i?.canceled_at && cancelled ? i?.canceled_at : !i?.canceled_at)) || []}
          onSelect={onSelectAppointment}
        />
      )}
      <SelectDateModal
        onClose={() => setDateModalOpen(false)}
        isModalOpen={dateModalOpen}
        selectedDate={selectedDate}
        onSelectDate={(date) => {
          setSelectedDate(new Date(date))
          setDateModalOpen(false)
        }}
      />

      {detailsModalVisible && (
        <AppointmentDetailsModal
          isModalOpen={detailsModalVisible}
          onClose={onCloseModals}
          setAppointmentId={setApp}
          app={app}
        />
      )}
    </Layout>
  )
}

HistoricScreen.path = '/historico'
HistoricScreen.title = 'Histórico'
HistoricScreen.secured = true
