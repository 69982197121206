export const defaultPermissions = {
  finance: 'edit',
  single_product: 'edit',
  appointment: 'edit',
  clients: 'edit',
  historic: 'edit',
  services: 'edit',
  blocked_hours: 'edit',
  team: 'edit',
  additional_products: 'edit',
  unitys: 'edit',
  payment_methods: 'edit',
  notifications: 'edit',
  profile: 'edit',
  cashier: 'edit',
  reopen: 'edit',
  signature: 'edit',
  configurations: 'edit',
  balance_withdraw: 'edit',
  edit_appointment: 'edit',
}

export const defaultBarberPermissions = {
  finance: 'blocked',
  single_product: 'blocked',
  appointment: 'blocked',
  clients: 'blocked',
  historic: 'blocked',
  services: 'blocked',
  blocked_hours: 'blocked',
  team: 'blocked',
  additional_products: 'blocked',
  unitys: 'blocked',
  payment_methods: 'blocked',
  notifications: 'blocked',
  finish_appointment: 'blocked',
  create_appointment: 'blocked',
  cancel_appointment: 'blocked',
  appointment_services: 'blocked',
  appointment_products: 'blocked',
  appointment_discount: 'blocked',
  appointment_source: 'blocked',
  signature: 'blocked',
  cashier: 'blocked',
  reopen: 'blocked',
  configurations: 'blocked',
  nota_fiscal: 'blocked',
  visibile_all_barbers: 'blocked',
  balance_withdraw: 'blocked',
  edit_appointment: 'blocked',
}

export const equivalentPermission = {
  '/home': 'finance',
  '/agenda': 'appointment',
  '/best-coins': 'appointment',
  '/historico': 'historic',
  '/financeiro': 'finance',
  '/extrato-simplificado': 'finance',
  '/extrato-detalhado': 'finance',
  '/relatorio-barbeiros': 'finance',
  '/relatorio-clientes': 'finance',
  '/relatorio-novos-clientes': 'finance',
  '/relatorio-clientes-ultimo-agendamento': 'finance',
  '/relatorio-clientes-primeiro-agendamento': 'finance',
  '/relatorio-frequencia-clientes': 'clients',
  '/relatorio-servicos': 'finance',
  '/relatorio-pagamentos': 'finance',
  '/relatorio-assinaturas': 'finance',
  '/relatorio-assinaturas-total': 'finance',
  '/detalhes-comissao': 'finance',
  '/metas-equipe': 'finance',
  '/taxa-ocupacao': 'finance',
  '/ticket-medio': 'finance',
  '/metas-resumo': 'finance',
  '/metas-barbeiro': 'finance',
  '/pagamentos/metodos': 'finance',
  '/pagamentos/saldo': 'finance',
  '/clientes': 'clients',
  '/equipe': 'team',
  '/servicos': 'services',
  '/pacote-de-servicos': 'services',
  '/produtos-adicionais': 'additional_products',
  '/meios-de-pagamento': 'payment_methods',
  '/unidades': 'unitys',
  '/notificacoes': 'notifications',
  '/caixa': 'cashier',
  '/relatorio-de-caixas': 'cashier',
  '/horarios-bloqueados': 'blocked_hours',
  '/assinantes': 'signature',
  '/assinantes-novo': 'signature',
  '/planos-assinatura': 'signature',
  '/frequencia-assinantes': 'signature',
  '/configuracoes/tempo-cancelamento': 'configurations',
  '/configuracoes/:section': 'configurations',
  '/configuracoes/maximo-agendamento': 'configurations',
  '/nota-fiscal/historico': 'nota_fiscal',
  '/nota-fiscal/configuracoes': 'finance',
  '/nota-fiscal/dados-da-equipe': 'finance',
  '/perfil/:section': 'configurations',
  '/perfil/dados': 'configurations',
  '/pagamentos/:section': 'finance',
  '/saldo': 'balance_withdraw',
}
