import React, { useEffect, useState } from 'react'
import { Layout } from 'components'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { CreateGoalModal } from 'components-v2/organisms/BarberGoals/CreateGoals'
import { ManagerGoals } from 'components-v2/organisms/BarberGoals/ManagerGoals'
import { ManagerGoalsV2 } from 'components-v2/organisms/BarberGoals-v2/ManagerGoals'
import { CreateGoalModalV2 } from 'components-v2/organisms/BarberGoals-v2/CreateGoals'

export const ManagerGoalsScreenV2 = ({ ...props }) => {
  const [goalModalOpen, setGoalModalOpen] = useState(false)
  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])

  return (
    <Layout
      {...props}
      h="auto"
      headerTitle="Gerenciamento de metas"
      px={100}
      secondActionClick={() => setGoalModalOpen(true)}
      secondActionText="Criar nova meta para colaborador"
      iconSecondActionText={'ReportChart'}
      iconColor="black"
    >
      <ManagerGoalsV2 />
      {goalModalOpen && <CreateGoalModalV2 isOpen={goalModalOpen} onClose={() => setGoalModalOpen(false)} />}
    </Layout>
  )
}

ManagerGoalsScreenV2.path = '/metas-barbeiro-v2'
ManagerGoalsScreenV2.title = 'Gerenciamento de metas'
ManagerGoalsScreenV2.secured = true
