import React, { useState } from 'react'
import { Text, TextInput } from 'components/atoms'
import { Table } from 'components/molecules'
import { StockModal } from '..'
import { useAppointmentDetailStore, useAppointmentStore } from 'services/stores'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { Flex, useToast } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { t } from 'services/translation'

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api/config'
import { GET_ADDITIONAL_PRODUCTS, ADD_ADDITIONAL_PRODUCT_APPOINTMENT } from 'services/api/endpoints'
import { useBarbershopStore } from 'services/stores'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { colors } from 'styles'

const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'price',
    label: 'VALUE_TABLE',
    render: (item) => <Text>{formatPrice(item.price)}</Text>,
  },
  {
    key: 'quantity',
    label: 'QUANTITY_',
    render: (item) => (
      <Text w="100%" color={(!item?.quantity || item?.quantity === 0) && 'error'}>
        {`${t('ON_STOCK')} ${item.quantity || 0}`}
      </Text>
    ),
  },
]

export const AdditionalProducts = ({ goBack, setEnabled }) => {
  const toast = useToast()
  const { addProduct, selectedAppointment } = useAppointmentDetailStore()
  const { userData } = useBarbershopStore()
  const { productsList, setProductsList, isLoadingAdditionalProduct, setLoadingAdditionalProduct } =
    useAppointmentStore()
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [batch, setBatch] = useState(false)
  const [query, setQuery] = useState('')
  const [data, setData] = useState({ data: [], filtered: [] })
  const { getProductsByBarber } = useBarbershop()
  const queryClient = useQueryClient()

  const { isLoading } = useQuery(
    'get-products-by-barber',
    () =>
      getProductsByBarber({
        id: userData?.id,
        barber_id: selectedAppointment?.barber_id,
      }),
    {
      onSuccess: (d) => {
        setData({ data: d, filtered: d })
      },
    }
  )

  async function AddAdditionalProducts(params) {
    const res = await api
      .post(ADD_ADDITIONAL_PRODUCT_APPOINTMENT, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('add-additional-products', (params) => AddAdditionalProducts(params), {
    onSuccess: () => {
      setEnabled(false)
      goBack()
    },
    onSettled: () => {
      setEnabled(false)
      queryClient.invalidateQueries('get-add-additional-products')
    },
    onError: (error) => {
      toast({
        title: t('ERROR_LOADING_PRODUCTS'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const handleSelectAdditionalProduct = (pm) => {
    if (!pm?.quantity || pm?.quantity === 0) {
      setBatch(pm)
      setAddModalOpen(true)
      return
    }
    const params = {
      product_id: pm?.product_id || pm?.id,
      appointment_id: selectedAppointment?.id,
    }
    setLoadingAdditionalProduct(true)
    mutate.mutate(params)
    addProduct(pm)
  }

  const handleSelect = (item) => {
    if (!item?.quantity || item?.quantity === 0) {
      setBatch(item)
      setAddModalOpen(true)
      return
    }

    setProductsList([...productsList, item])
    goBack()
  }

  const onSearch = (text) => {
    setQuery(text)
    if (text.length === 0) {
      setData({ ...data, filtered: data?.data })
    } else {
      const search_result = data?.data.filter((item) => String(item?.name.toLowerCase()).includes(query.toLowerCase()))
      setData({ ...data, filtered: search_result })
    }
  }

  return (
    <Flex flexDirection="column">
      <Flex w="100%" justify="space-between">
        <TextInput
          noBorder
          type="text"
          color={colors.white}
          placeholder="Pesquisar produto"
          backgroundColor="backgroundLayout"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={12}
          paddingLeft="16px"
          mb="12px"
          onChange={(event) => {
            onSearch(event?.target?.value)
          }}
          w="100%"
          mr="5"
        />
      </Flex>
      <Table
        isSelectableRow
        data={data?.filtered || []}
        loading={isLoading}
        columns={columns}
        onSelect={(item) => {
          if (selectedAppointment?.status === 'reopened') {
            setEnabled(false)
            handleSelect(item)
            return
          }
          handleSelectAdditionalProduct(item)
        }}
        cardLoading={isLoadingAdditionalProduct}
        cardDisabled={isLoadingAdditionalProduct}
      />

      <StockModal
        isModalOpen={addModalOpen}
        onClose={() => {
          setBatch(null)
          setAddModalOpen(false)
        }}
        selectedBatch={batch}
        isAppointment
      />
    </Flex>
  )
}
