import { api } from 'services/api/config'

export const useCashiers = () => {
  const getCashiersReport = async (props) => {
    const response = await api({
      url: `v3/barbershop/cashiers-report/${props.barbershop_id}?start_date=${props.start_date}&end_date=${props.end_date}`,
    })
    return response.data
  }

  const deleteCashier = async (props) => {
    const response = await api({
      url: `v3/barbershop/cashiers/${props.cashier_id}`,
      method: 'DELETE',
    })
    return response.data
  }

  return {
    getCashiersReport,
    deleteCashier,
  }
}
