import { useEffect, useState } from 'react'
import { useTheme, Flex } from '@chakra-ui/react'
import { Table, TextInput, Box, Text, Icon, Link, Button } from 'components'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useTranslation } from 'react-i18next'
import { formatPhone } from 'utils/phone-formater'
import { t } from 'services/translation'
import { differenceInDays, endOfMonth, format, parseISO, startOfMonth, subHours } from 'date-fns'
import * as XLSX from 'xlsx'

const mobileColumns = [
  { key: 'image', label: '', type: 'image', placeholder: 'client' },
  { key: 'name', label: 'NAME_TABLE' },
  { key: 'phone', label: 'PHONE_TABLE' },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => (
      <Text color={item?.status === 'ACTIVE' && !item?.inactive ? 'success' : 'danger'}>
        {item?.inactive ? 'Cancelado' : t(item?.status)}
      </Text>
    ),
  },
]

export const NewSubscribers = ({ data, isLoading, handleSelectCustomer }) => {
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  const { userData } = useBarbershopStore()
  const [subscribers, setSubscribers] = useState({ data: data, filtered: data })

  const desktopColumns = [
    {
      key: 'image',
      label: '',
      type: 'image',
      placeholder: 'client',
      render: (item) => item?.client?.profile_image_url,
    },
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => <Text>{item?.client?.name}</Text>,
    },
    {
      key: 'email',
      label: 'EMAIL_TABLE',
      width: 100,
      render: (item) => <Text>{item?.client?.user?.email}</Text>,
    },
    {
      key: 'phone',
      label: 'PHONE_TABLE',
      render: (item) => (
        <Link href={`https://wa.me/${item?.client?.user?.phone}`}>
          {formatPhone(item?.client?.user?.phone, userData)}
        </Link>
      ),
    },
    {
      key: 'plan',
      label: 'PLANO',
      render: (item) => <Text>{item?.plan?.name}</Text>,
    },
    {
      key: 'createdAt',
      label: 'DATA DA 1ª MENSALIDADE',
      render: (item) => <Text>{format(subHours(parseISO(item?.createdAt), 3), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'due_simple_date',
      label: 'Data venc.',
      render: (item) => <Text>{format(parseISO(item?.due_simple_date ?? item?.due_date), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => {
        const due_date = parseISO(item?.due_simple_date)
        const diff = differenceInDays(due_date, new Date())
        return (
          <Text color={diff < 0 || item?.inactive || item?.status === 'INACTIVE' ? 'danger' : 'success'}>
            {item?.inactive ? 'Cancelado' : diff < 0 ? 'Inativo' : t(item?.status)}
          </Text>
        )
      },
    },
  ]

  useEffect(() => {
    setSubscribers({ data: data, filtered: data })
  }, [data])

  useEffect(() => {
    const filtered = subscribers?.data?.filter?.((item) => {
      const createdAt = parseISO(item?.createdAt)
      return createdAt >= startOfMonth(new Date()) && createdAt <= endOfMonth(new Date())
    })
    setSubscribers({ ...subscribers, filtered })
  }, [subscribers?.data])

  useEffect(() => {
    const filtered = subscribers?.data?.filter?.((item) => {
      const createdAt = parseISO(item?.createdAt)
      return createdAt >= parseISO(startDate) && createdAt <= parseISO(endDate)
    })
    setSubscribers({ ...subscribers, filtered })
  }, [startDate, endDate])

  const emptyList = () => (
    <Flex align="center" flexDir="column" mt={theme.pxToRem(24)}>
      <Icon name="Scissors" size={24} mb={theme.pxToRem(12)} />
      <Text>{t('NO_SUBSCRIBERS')}</Text>
    </Flex>
  )

  const handleExport = () => {
    const tableExport = subscribers?.filtered?.map((item) => {
      const due_date = parseISO(item?.due_simple_date)
      const diff = differenceInDays(due_date, new Date())

      return {
        Nome: item?.client?.name,
        Email: item?.client?.user?.email,
        Telefone: item?.client?.user?.phone,
        Plano: item?.plan?.name,
        'Data de vencimento': format(parseISO(item?.due_simple_date ?? item?.due_date), 'dd/MM/yyyy'),
        'Data da primeira mensalidade': format(subHours(parseISO(item?.createdAt), 3), 'dd/MM/yyyy'),
        Status: item?.inactive ? 'Cancelado' : diff < 0 ? 'Inativo' : t(item?.status),
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(tableExport)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabela')

    XLSX.writeFile(workbook, 'assinantes.xlsx')
  }

  return (
    <Flex minW="100%" flexDir="column" mt="24px" justify="center" align="center">
      <Box flex={1} mt="12px">
        <Flex w="30%" mt="16px" mb="24px">
          <TextInput
            noBorder
            type="date"
            name="start_date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            label={t('Data inicial de assinatura')}
            padding={theme.pxToRem(10)}
            mr={theme.pxToRem(24)}
            w={theme.pxToRem(250)}
          />
          <TextInput
            noBorder
            type="date"
            name="end_date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            label={t('Data final de assinatura')}
            padding={theme.pxToRem(10)}
            mr={theme.pxToRem(12)}
            w={theme.pxToRem(250)}
          />
          <Button h="40px" alignSelf="flex-end" mt="16px" onClick={handleExport}>
            Exportar em excel
          </Button>
        </Flex>

        {subscribers?.filtered?.length === 0 && emptyList()}
        {subscribers?.filtered?.length !== 0 && (
          <Table
            isSelectableRow={isDesktop}
            data={subscribers?.filtered || []}
            loading={isLoading}
            columns={isDesktop ? desktopColumns : mobileColumns}
            onSelect={handleSelectCustomer}
          />
        )}
      </Box>
    </Flex>
  )
}
