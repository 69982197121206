import { api } from 'services/api/config'

export const usePlans = () => {
  const getPlans = async (props) => {
    const response = await api({
      url: `v3/barbershop/plans/${props.id}`,
    })
    return response.data
  }

  const getServicesPlans = async (props) => {
    if (!props?.plan_id) {
      throw new Error('Plano não informado')
    }
    const response = await api({
      url: `v3/barbershop/services-plans/${props.plan_id}`,
    })
    return response.data
  }

  const createPlans = async (props) => {
    const response = await api.post('v3/barbershop/plans', props?.body)
    return response.data
  }

  const updatePlans = async (props) => {
    const response = await api.put('v3/barbershop/plans', props?.body)
    return response.data
  }

  const updatePlanVisible = async (props) => {
    const response = await api.put('v3/barbershop/visible-plan', props?.body)
    return response.data
  }

  const deletePlan = async (props) => {
    const response = await api.delete(`v3/barbershop/plans/${props?.id}`)
    return response.data
  }

  const updatePlanValue = async (props) => {
    const response = await api.post('v3/scripts/update-plan-value', props?.body)
    return response.data
  }

  const updatePlan = async (props) => {
    const response = await api.put('v3/barbershop/plan', props?.body)
    return response.data
  }

  const updateService = async (props) => {
    const response = await api.put('v3/barbershop/update-services-plans', props?.body)
    return response.data
  }

  const updatePlanOrder = async (props) => {
    const response = await api.put(`v3/barbershop/plans/order`, props?.body)
    return response.data
  }

  return {
    updatePlanOrder,
    updateService,
    updatePlan,
    updatePlanValue,
    updatePlanVisible,
    getServicesPlans,
    updatePlans,
    deletePlan,
    getPlans,
    createPlans,
  }
}
