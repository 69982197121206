import React, { useState } from 'react'
import { Box, Table, Text, Button, Icon, Flex, StockModal } from 'components'
import { useSingleSaleStore } from 'services/stores'
import { formatPrice } from 'utils'
import { useTranslation } from 'react-i18next'
import { Input, useTheme } from '@chakra-ui/react'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { useBarbershopStore } from 'services/stores'
import { useQuery } from 'react-query'
import { t } from 'services/translation'
import { pxToRem } from 'styles/metrics'

const columns = [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item.name}
      </Text>
    ),
  },
  {
    key: 'price',
    label: 'VALUES',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {formatPrice(item.price)}
      </Text>
    ),
  },
  {
    key: 'quantity',
    label: 'QUANTITY_',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {`${t('ON_STOCK')} ${item.quantity || 0}`}
      </Text>
    ),
  },
]

const AppointmentSelectProducts = ({ goBack, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { barber, products, setProducts } = useSingleSaleStore()
  const { userData } = useBarbershopStore()
  const { getProductsByBarber } = useBarbershop()

  const [searchProduct, setSearchProduct] = useState('')
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [batch, setBatch] = useState(false)

  const { isLoading, data } = useQuery('get-products-by-barber', () =>
    getProductsByBarber({
      id: userData?.id,
      barber_id: barber?.id,
      type: !!barber?.type ? 'employee' : 'barber',
    })
  )
  const handleSelect = (payload) => {
    setProducts({ ...payload, index_id: products?.length })
    goBack()
    if (!payload?.quantity || payload?.quantity === 0) {
      setBatch(payload)
      setAddModalOpen(true)
      return
    }
    // if (products.find((product) => product.id === payload.id)) {
    //   const newProducts = products.filter(
    //     (product) => product.id !== payload.id
    //   )
    //   setProducts(newProducts)
    //   return
    // }
    // setProducts(payload)
  }

  const handleConfirm = () => {
    goBack()
  }

  const filteredProducts = data?.filter(({ name }) =>
    name?.toLocaleLowerCase().includes(searchProduct.toLocaleLowerCase())
  )

  return (
    <Box>
      <Box h="90%">
        <Flex mt={theme.pxToRem(-12)} mb={theme.pxToRem(12)} w="100%" flexDir="row" align="center">
          <Icon
            name="ArrowLeft"
            color="primary"
            cursor="pointer"
            w="fit-content"
            size={20}
            mr={theme.pxToRem(12)}
            onClick={goBack}
          />
          {props?.withSubtitle && (
            <Text kind="bold" fontSize={18}>
              {t('PRODUCTS')}
            </Text>
          )}
        </Flex>

        <Flex alignItems="center" border="1px solid white" borderRadius={pxToRem(10)} mt={pxToRem(8)} mb={pxToRem(24)}>
          <Icon name="Search" mb={pxToRem(2)} size={20} ml={pxToRem(12)} />
          <Input
            border={0}
            color="white"
            mt={pxToRem(8)}
            mb={pxToRem(24)}
            m={0}
            placeholder="Pesquisar produto"
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
          />
        </Flex>

        <Table
          isSelectableRow
          isMultiSelectable
          data={filteredProducts || []}
          loading={isLoading}
          columns={columns}
          withoutHeader
          onSelect={(product, index) => handleSelect(product, index)}
          selectedList={products}
        />
      </Box>

      <StockModal
        isModalOpen={addModalOpen}
        onClose={() => {
          setBatch(null)
          setAddModalOpen(false)
        }}
        selectedBatch={batch}
        isAppointment
      />
    </Box>
  )
}

export default AppointmentSelectProducts
