import {
  CREATE_INVOICE_ISSUER,
  GET_ALL_INVOICE_ISSUER,
  GET_ONE_INVOICE_ISSUER,
  UPDATE_INVOICE_ISSUER,
  DELETE_INVOICE_ISSUER,
  UPDATE_INVOICE_SERVICE_ISSUER,
  UPDATE_MANY_INVOICE_SERVICE_ISSUER,
} from 'services/api/endpoints'

import { api } from 'services/api/config'
import { useFetch } from './use-fetch'

export const useInvoiceIssuer = () => {
  const { onFetchExport } = useFetch()
  const createInvoiceIssuer = async (data) => {
    try {
      const response = await api.post(CREATE_INVOICE_ISSUER, data)
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const getAllInvoiceIssuer = async (data) => {
    try {
      const response = await api.get(GET_ALL_INVOICE_ISSUER(data))
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const getOneInvoiceIssuer = async (id) => {
    try {
      const response = await api.get(GET_ONE_INVOICE_ISSUER(id))
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const updateInvoiceIssuer = async (data) => {
    try {
      const response = await api.put(UPDATE_INVOICE_ISSUER(data.id), data)
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const updateInvoiceServiceIssuer = async (id) => {
    try {
      const response = await api.post(UPDATE_INVOICE_SERVICE_ISSUER(id))
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const updateAllInvoiceServiceIssuer = async (id) => {
    try {
      const response = await api.post(UPDATE_MANY_INVOICE_SERVICE_ISSUER(id))
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const removeInvoiceIssuer = async (data) => {
    try {
      const response = await api.delete(DELETE_INVOICE_ISSUER(data.id))
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }
  const getHistoric = async (data) => {
    try {
      const response = await api({
        url: `v3/historic-nf`,
        params: {
          barbershop_id: data?.barbershop_id,
          start_date: data?.start_date,
          end_date: data?.end_date,
        },
      })
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const getDownloadNF = async (data) => {
    try {
      const response = await api.post('v3/invoice-records/downloads', data)
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  async function exportDownloadNf(props) {
    const response = await onFetchExport({
      ...props,
      url: `v3/invoice-records/download/${props?.barbershop_id}`,
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        ...props.params,
        responseType: 'blob',
      },
      method: 'post',
      message: {
        error: 'Erro ao fazer download',
      },
    })
    return response
  }

  return {
    exportDownloadNf,
    getDownloadNF,
    getHistoric,
    updateInvoiceServiceIssuer,
    updateAllInvoiceServiceIssuer,
    getAllInvoiceIssuer,
    getOneInvoiceIssuer,
    createInvoiceIssuer,
    updateInvoiceIssuer,
    removeInvoiceIssuer,
  }
}
