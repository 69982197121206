import { useEffect, useMemo, useRef, useState } from 'react'

import { Box, Grid, Text, GridItem, Skeleton } from '@chakra-ui/react'
import { addMinutes, differenceInMinutes, format } from 'date-fns'
import GridLayout from 'react-grid-layout'

import { Flex, Loader } from 'components/atoms'

import { GridBackground } from './GridBackground'
import { GridTimeBar } from './GridTimeBar'
import { GridHeader } from './GridHeader'

import 'react-grid-layout/css/styles.css'

export const DraggableTimeline = ({
  data,
  isLoading = false,
  headerRender = (headerData) => <Text p={2}>{headerData.title}</Text>,
  itemRender,
  onChange,
  onDragStop,
  onItemChange,
  onGridClick,
  minTime = '06:00',
  maxTime = '24:00',
  minItemRows = 2,
  rowHeight = 15,
  colWidth = 220,
  isClosed = false,
  agenda_interval = 30,
  ...props
}) => {
  const [canItemClick, setCanItemClick] = useState(true)
  // header
  const headers = data.map((item) => item.header)
  const headerRef = useRef(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  // col and row calcs
  const displayMinutes = differenceInMinutes(new Date(`2000/01/01 ${maxTime}`), new Date(`2000/01/01 ${minTime}`))
  const cols = headers.length
  const colsDividers = cols + 1
  const rows30Min = displayMinutes / agenda_interval
  const rows5Min = displayMinutes / 5

  // timeline calcs
  const timelineHeight = rowHeight * rows5Min + rows5Min
  const timelineWidth = cols * colWidth + colsDividers

  // timeline items
  const items = data.map((e) => e.items).flat()
  const [itemsFormatted, setItemsFormatted] = useState([])

  useEffect(() => {
    const initHeaderHeight = () => {
      const height = headerRef.current?.clientHeight
      if (height) setHeaderHeight(height)
    }
    initHeaderHeight()
  }, [headerRef, isLoading, data])

  useMemo(() => {
    const initItemsFormatted = () => {
      const newItemsFormatted = data.reduce((acc, e, colIdx) => {
        const newItems = e.items.map((item, itemIdx) => {
          const minutesY = differenceInMinutes(
            new Date(`2000/01/01 ${item.startTimeStr}`),
            new Date(`2000/01/01 ${minTime}`)
          )
          const minutesH = differenceInMinutes(
            new Date(`2000/01/01 ${item.endTimeStr}`),
            new Date(`2000/01/01 ${item.startTimeStr}`)
          )

          const overlappingItems = e.items.filter(
            (i) =>
              ((item.startTimeStr >= i.startTimeStr && item.startTimeStr < i.endTimeStr) ||
                (item.endTimeStr > i.startTimeStr && item.endTimeStr <= i.endTimeStr) ||
                (item.startTimeStr <= i.startTimeStr && item.endTimeStr >= i.endTimeStr)) &&
              i?.type !== 'blocked_times' &&
              item?.type !== 'blocked_times'
          )

          const itemWidth = overlappingItems.length > 1 ? 0.5 : 1
          const itemX = colIdx + (overlappingItems.length > 1 ? (itemIdx % 2) * 0.5 : 0)

          const newItem = {
            i: item.id,
            x: itemX,
            y: minutesY / 5,
            w: itemWidth,
            h: minutesH / 5,
            minH: minItemRows,
            static: item.static,
            isDraggable: item.isDraggable ?? true,
            isResizable: item.isResizable ?? true,
            resizeHandles: ['s', 'n'],
          }
          return newItem
        })

        return [...acc, ...newItems]
      }, [])
      setItemsFormatted(newItemsFormatted)
    }
    initItemsFormatted()
  }, [data, minItemRows, minTime])

  const handleLayoutChange = (newItemsFormatted, byDrag) => {
    if (onItemChange) {
      const newItemFormatted = newItemsFormatted?.find((newItem) => {
        return itemsFormatted?.filter(
          (oldItem) =>
            (oldItem.x !== newItem.x && oldItem.i === newItem.i) ||
            (oldItem.y !== newItem.y && oldItem.i === newItem.i) ||
            (oldItem.h !== newItem.h && oldItem.i === newItem.i)
        )?.[0]
      })
      if (!newItemFormatted) return
      const metadata = items.find((item) => item.id === newItemFormatted.i)
      const itemChange = {
        appointment_id: metadata?.metadata?.id,
        startTimeStr: format(addMinutes(new Date(`2000/01/01 ${minTime}`), newItemFormatted.y * 5), 'HH:mm'),
        endTimeStr: format(
          addMinutes(new Date(`2000/01/01 ${minTime}`), (newItemFormatted.y + newItemFormatted.h) * 5),
          'HH:mm'
        ),
        id: newItemFormatted.i,
        barber: data?.[newItemFormatted.x]?.header?.metadata,
        barber_column: newItemFormatted.x,
        old_barber_id: metadata?.metadata?.barber_id,
        byDrag: !!byDrag,
        static: newItemFormatted.static,
        isDraggable: newItemFormatted.isDraggable ?? true,
        isResizable: newItemFormatted.isResizable ?? true,
        metadata,
      }
      onItemChange(itemChange)
    }

    if (onChange) {
      const itemsChange = newItemsFormatted.map((itemFormatted) => {
        const startTimeStr = format(addMinutes(new Date(`2000/01/01 ${minTime}`), itemFormatted.y * 5), 'HH:mm')
        const endTimeStr = format(
          addMinutes(new Date(`2000/01/01 ${minTime}`), (itemFormatted.y + itemFormatted.h) * 5),
          'HH:mm'
        )
        const metadata = items.find(({ id }) => id === itemFormatted.i)
        return {
          id: itemFormatted.i,
          startTimeStr,
          endTimeStr,
          static: itemFormatted.static,
          isDraggable: itemFormatted.isDraggable ?? true,
          isResizable: itemFormatted.isResizable ?? true,
          metadata,
        }
      })
      onChange(itemsChange)
    }
    setItemsFormatted(newItemsFormatted)
  }
  if (isClosed) {
    return (
      <Flex borderRadius={12} h="80vh" bg="modalMenu" align="center" justify="center">
        <Text color="white" fontSize={24} kind="bold">
          Barbearia Fechada
        </Text>
      </Flex>
    )
  }

  if (isLoading) {
    return (
      <Flex borderRadius={12} h="80vh" bg="modalMenu" align="center" justify="center">
        <Loader />
      </Flex>
    )
  }

  return (
    <Skeleton isLoaded={!isLoading} borderRadius={8}>
      <Box
        className="draggable-timeline"
        borderWidth={1}
        borderColor="modalBorder"
        borderRadius={8}
        overflow="hidden"
        {...props}
      >
        <Grid
          templateAreas={'"time header" "time main"'}
          gridTemplateRows="auto 1fr"
          gridTemplateColumns="50px 1fr"
          position="relative"
          overflow="scroll"
          w="100%"
          h="100%"
        >
          <GridItem
            ref={headerRef}
            sx={{ gridArea: 'header' }}
            position="sticky"
            left={0}
            top={0}
            zIndex="overlay"
            bg="menuBackground"
            borderBottomWidth={1}
            borderColor="modalBorder"
            mb="-1px"
          >
            <GridHeader
              colsDividers={colsDividers}
              colWidth={colWidth}
              headers={headers}
              headerRender={headerRender}
              ml="1px"
            />
          </GridItem>

          <GridItem
            sx={{ gridArea: 'time' }}
            position="sticky"
            left={0}
            zIndex="overlay"
            bg="menuBackground"
            borderRightWidth={1}
            borderColor="modalBorder"
            mr="-1px"
            h="calc(100% + 1px)"
            pt={`${headerHeight}px`}
          >
            <GridTimeBar {...{ rowHeight, rows30Min, agenda_interval, minTime }} />
          </GridItem>

          <GridItem sx={{ gridArea: 'main' }} position="relative" width={timelineWidth} h={`${timelineHeight}px`}>
            <GridBackground
              rowHeight={rowHeight}
              rows5Min={rows5Min}
              rows30Min={rows30Min}
              agenda_interval={agenda_interval}
              displayMinutes={displayMinutes}
              cols={cols}
              headers={headers}
              minTime={minTime}
              maxTime={maxTime}
              onGridClick={onGridClick}
            />
            <GridLayout
              layout={itemsFormatted}
              preventCollision
              compactType={null}
              allowOverlap={true}
              cols={cols}
              maxRows={rows5Min}
              rowHeight={rowHeight}
              width={timelineWidth}
              margin={[1, 1]}
              onLayoutChange={handleLayoutChange}
              onDragStop={(e) => {
                handleLayoutChange(e, 'drag')
                setCanItemClick(false)
                setTimeout(() => setCanItemClick(true), 0)
              }}
            >
              {itemsFormatted.map((itemFormatted) => {
                const startTimeStr = format(addMinutes(new Date(`2000/01/01 ${minTime}`), itemFormatted.y * 5), 'HH:mm')
                const endTimeStr = format(
                  addMinutes(new Date(`2000/01/01 ${minTime}`), (itemFormatted.y + itemFormatted.h) * 5),
                  'HH:mm'
                )

                const { metadata } = items.find(({ id }) => id === itemFormatted.i) ?? {}
                const header = headers[itemFormatted.x]

                const item = {
                  id: itemFormatted.i,
                  barber_id: metadata?.barber_id,
                  startTimeStr,
                  endTimeStr,
                  static: itemFormatted.static,
                  isDraggable: itemFormatted.isDraggable ?? true,
                  isResizable: itemFormatted.isResizable ?? true,
                  metadata,
                }

                return (
                  <Box key={itemFormatted.i} position="relative" borderRadius={8} p="2px" overflow="hidden" zIndex={2}>
                    {itemRender({ item, header, canItemClick })}
                  </Box>
                )
              })}
            </GridLayout>
          </GridItem>
          <style>
            {`
          .draggable-timeline .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
          .draggable-timeline .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
            --margin-x: 20%;
            background: white !important;
            opacity: 0.60;
            width: calc(100% - (2 * var(--margin-x)));
            height: 2px;
            margin-inline: var(--margin-x);
            transform: rotate(0);
            left: 0; 
            border-radius: 100px;
          }
          .draggable-timeline .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
            top: 4px; 
          }
          .draggable-timeline .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
            bottom: 4px; 
          }
          .draggable-timeline .react-grid-item > .react-resizable-handle::after {
            display: none;
          }
          .draggable-timeline ::-webkit-scrollbar { 
            height: 6px;
            width: 6px;
            scrollbar-width: thin;
          }
          .draggable-timeline ::-webkit-scrollbar-track{
            border-left: 1px solid #363D49;
            border-top: 1px solid #363D49;  
          }
          .draggable-timeline ::-webkit-scrollbar-track, 
          .draggable-timeline ::-webkit-scrollbar-corner {
            background-color: #20242a; 
          }
          `}
          </style>
        </Grid>
      </Box>
    </Skeleton>
  )
}
