import React, { useMemo, useState } from 'react'
import { Box, Flex, Text, Button } from 'components/atoms'
import { ModalAlert, ModalMenu } from 'components/molecules'
import { t } from 'services/translation'
import { useMutation } from 'react-query'
import { format, parseISO } from 'date-fns'
import { useToast } from '@chakra-ui/react'
import { formatCurrency } from 'utils/formatCurrency'
import { useCashiers } from 'services/hooks/use-cashiers'
import { usePermissionCollaborator } from 'services/hooks'

export const CashierDetailsModal = ({ onClose, item }) => {
  const cashier_permission = usePermissionCollaborator('cashier')
  const toast = useToast()

  const [isAlertOpen, setIsAlertOpen] = useState()
  const { deleteCashier } = useCashiers()

  const mutate = useMutation('delete-cashier', () => deleteCashier({ cashier_id: item?.id }), {
    onSuccess: () => {
      toast({
        title: 'Caixa excluído com sucesso',
        status: 'success',
      })
      onClose({ refetch: true })
    },
    onError: (error) => {
      toast({
        title: t('ERROR_DELETE_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const logs = useMemo(() => {
    const created_info = {
      user_email: item?.created_by_user?.email,
      date_time: item?.createdAt,
      modify: 'create_cashier',
    }

    let logs = [created_info]

    if (item?.close_date) {
      const findCloseLog = item?.logs?.find((log) => log?.modify === 'close_cashier')
      if (!findCloseLog) {
        logs.push({
          user_email: item?.created_by_user?.email,
          date_time: item?.updatedAt,
          modify: 'close_cashier',
        })
      }
    }

    if (item?.logs?.length > 0) {
      logs.push(...item?.logs)
    }

    if (logs?.length > 0) {
      return logs?.sort((a, b) => parseISO(b.date_time) - parseISO(a.date_time))
    }

    return []
  }, [item])

  const getLogsTitle = (modify) => {
    switch (modify) {
      case 'create_cashier':
        return t('Criação do caixa')
      case 'close_cashier':
        return t('Fechamento do caixa')
      case 'delete_cashier':
        return t('Exclusão do caixa')
      default:
        return ''
    }
  }

  return (
    <ModalMenu size="lg" isOpen onClose={onClose} title={t('Detalhes do Caixa')}>
      <Box pb={24}>
        <Flex alignItems="center">
          <Text size={18} kind="bold">
            Data do caixa: {format(parseISO(item?.open_date), 'dd/MM/yyyy')}
          </Text>
          <Flex alignItems="center" bg={'component'} borderRadius={16} pt={1} pb={1} pl={4} pr={4} ml={4}>
            <Text fontSize={15}>Status do caixa:</Text>
            <Text
              fontSize={16}
              ml={1}
              kind="bold"
              color={item?.inactive ? 'grey' : item?.status === 'open' ? 'success' : 'danger'}
            >
              {item?.inactive ? t('Inativo (Excluído)') : item?.status === 'open' ? t('Aberto') : t('Fechado')}
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="center" mt={4}>
          <Box w="100%" p={4} border="1px solid border" borderRadius={12} bg="background2" shadow="0px 0px 4px #4E535A">
            <Text fontSize={14}>ID do Caixa: {item?.id}</Text>

            <Text fontSize={14} kind="semiBold">
              E-mail do usuário que abriu o caixa: {item?.created_by_user?.email}
            </Text>
          </Box>
        </Flex>
        <Flex>
          <Box
            mr="2%"
            w="49%"
            mt={4}
            p={4}
            border="1px solid border"
            borderRadius={12}
            bg="background2"
            shadow="0px 0px 4px #4E535A"
          >
            <Text fontSize={14}>Abertura do caixa:</Text>
            <Text fontSize={14} kind="semiBold">
              {format(parseISO(item?.createdAt), 'dd/MM/yyyy')} às {item?.open_hour}
            </Text>
            <Text fontSize={14} kind="semiBold">
              Valor de abertura: {formatCurrency(Number(item?.open_value))}
            </Text>
          </Box>

          <Box
            w="49%"
            mt={4}
            p={4}
            border="1px solid border"
            borderRadius={12}
            bg="background2"
            shadow="0px 0px 4px #4E535A"
          >
            <Text fontSize={14}>Fechamento do caixa:</Text>
            <Text fontSize={14} kind="semiBold">
              {item?.close_date
                ? `${format(parseISO(item?.updatedAt), 'dd/MM/yyyy')} às ${item?.close_hour}`
                : 'Caixa com status aberto'}
            </Text>
            <Text fontSize={14} kind="semiBold">
              Valor de fechamento: {item?.close_value ? formatCurrency(Number(item?.close_value)) : '-'}
            </Text>
          </Box>
        </Flex>

        {logs?.length > 0 && (
          <Text mt={4} fontWeight="bold">
            Modificações (Logs):
          </Text>
        )}

        {logs?.map((log) => (
          <Box mt={4} p={4} border="1px solid border" borderRadius={12} bg="background2" shadow="0px 0px 4px #4E535A">
            <Text fontSize={14} fontWeight="semiBold">
              Usuário que fez a alteração: {log?.user_email}
            </Text>
            <Text fontSize={14} fontWeight="semiBold">
              Data da alteração: {format(parseISO(log?.date_time), 'dd/MM/yyyy')} às{' '}
              {format(parseISO(log?.date_time), 'HH:mm')}
            </Text>
            <Text fontSize={14} fontWeight="semiBold">
              Tipo de alteração: {getLogsTitle(log?.modify)}
            </Text>
          </Box>
        ))}

        {!item?.inactive && (
          <Flex
            borderTopColor="grey"
            borderTopWidth="1px"
            ml="-24px"
            position="absolute"
            bottom={0}
            bg="background"
            h="110px"
            w="100%"
            p="20px"
            py="24px"
          >
            <Button
              disabled={cashier_permission !== 'edit'}
              ml="12px"
              size="block"
              kind="danger"
              onClick={() => setIsAlertOpen(true)}
            >
              {t('Excluir caixa permanentemente')}
            </Button>
          </Flex>
        )}
        <ModalAlert
          isOpen={isAlertOpen}
          onClose={() => {
            setIsAlertOpen(false)
          }}
          primaryButtonLabel="Confirmar"
          modalTitle={t('Deseja excluir este caixa permanentemente?')}
          onConfirm={mutate.mutate}
          loading={mutate.isLoading}
        >
          Esta ação não poderá ser desfeita
        </ModalAlert>
      </Box>
    </ModalMenu>
  )
}
