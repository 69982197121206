import React, { useEffect } from 'react'
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, Tooltip, Box } from '@chakra-ui/react'
import { pxToRem } from 'styles/metrics'
import { theme } from 'styles/theme'
import { Flex, Text } from '..'
import { useTranslation } from 'react-i18next'

export const PercentageSliderInput = ({ label, w, initialValue, onChange }) => {
  const { t } = useTranslation()

  const [sliderValue, setSliderValue] = React.useState(initialValue * 100 || 0)
  const [showTooltip, setShowTooltip] = React.useState(false)

  useEffect(() => {
    onChange?.(sliderValue / 100)
  }, [sliderValue])

  return (
    <Box w={w || '100%'}>
      {label && (
        <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(6)}>
          {t(label)}
        </Text>
      )}

      <Flex
        h={pxToRem(48)}
        mb={pxToRem(12)}
        bgColor="backgroundLayout"
        borderColor="modalBorder"
        borderWidth={1}
        borderRadius={pxToRem(8)}
        px={pxToRem(24)}
        align="center"
        gap={pxToRem(16)}
      >
        <Slider
          id="slider"
          value={sliderValue}
          min={0}
          max={99}
          colorScheme="yellow"
          onChange={(v) => setSliderValue(v)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>

          <Tooltip hasArrow bg="primary" color="black" placement="top" isOpen={showTooltip} label={`${sliderValue}%`}>
            <SliderThumb />
          </Tooltip>
        </Slider>

        <Text>{`${sliderValue}%`}</Text>
      </Flex>
    </Box>
  )
}
