import React, { useEffect, useState } from 'react'
import { useToast, useTheme } from '@chakra-ui/react'
import { Layout, Table, Box, MobileList, TeamCard, TextInput, FeatureBlockedMessage, Text, Loader } from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useBreakpoint, usePremiumBlock, saveLoginData } from 'services/hooks'
import { Search } from 'assets/icons'
import { colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { UNITYS, REVALIDATE_SUBSCRIPTION } from 'services/api/endpoints'
import { useHistory } from 'react-router-dom'
import { UnityModal } from 'components'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

const COLUMNS = [
  { key: 'profile_image_url', label: '', type: 'image', placeholder: 'barber' },
  { key: 'name', label: 'UNITY_TABLE' },
  {
    key: 'phone',
    label: 'PHONE_TABLE',
    width: 150,
    render: (item) => item?.phone,
  },
  {
    key: 'address',
    label: 'ADDRESS',
    render: (item) => (
      <Text>
        {item?.address
          ? `${item?.address?.street}${
              item?.address?.street_number?.length > 0 ? ', ' + item?.address?.street_number + ', ' : ', '
            } ${item?.address?.city}`
          : ''}
      </Text>
    ),
  },
]

export const UnitysScreen = ({ ...props }) => {
  const toast = useToast()
  const theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoint()
  const [unitysList, setUnitysList] = useState({ data: [], filtered: [] })
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)

  const { user, setData } = useBarbershopStore()
  const [loading, setLoading] = useState(false)
  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])
  async function getUnitys(params) {
    const res = await api
      .get(UNITYS)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, isLoading } = useQuery('get-unitys', (params) => getUnitys(params), {
    onSuccess: (data) => {
      setUnitysList({ data: data, filtered: data })
    },
    onError: (error) => {
      toast({
        title: t('toast.error.products.error_loading_products'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
      })
    },
  })

  async function revalidateSubscription(p) {
    const params = {
      barbershop_id: p?.barbershop?.id || p?.id,
      user_id: user?.id,
    }
    const res = await api
      .post(REVALIDATE_SUBSCRIPTION, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('revalidate-subscription', (params) => revalidateSubscription(params), {
    onSuccess: async (d) => {
      const { user, userData, token, subscription, customer } = d
      const toSaveUserData = userData.barbershop ? userData.barbershop : userData
      saveLoginData({ user, userData: toSaveUserData, token })
      setData(user, toSaveUserData, subscription, customer)
      if (!toSaveUserData.status || toSaveUserData.status === 'not_published') {
        history.push('/cadastro/localizacao')
        return
      }
      history.push('/agenda')
      setLoading(false)
    },
    onError: (error) => {
      setLoading(false)
      toast({
        title: 'Falha ao alterar unidade',
        duration: 4000,
        status: 'error',
      })
    },
  })

  const closeModal = () => {
    setOpen(false)
  }

  const isBlocked = usePremiumBlock()

  const onSearch = (text) => {
    setQuery(text)
    if (text.length === 0) {
      setUnitysList({ ...data, filtered: data?.data })
    } else {
      const search_result = data?.data.filter((item) => String(item?.name.toLowerCase()).includes(query.toLowerCase()))
      setUnitysList({ ...data, filtered: search_result })
    }
  }

  return (
    <Layout
      {...props}
      headerTitle={t('UNITYS')}
      px={100}
      headerActionText={!isBlocked && t('ADD_UNITY')}
      headerActionClick={() => setOpen(true)}
    >
      {!isBlocked || user?.type === 'ownerAndBarber' || user?.type === 'owner' ? (
        <Box flex={1}>
          <TextInput
            noBorder
            type="text"
            width="full"
            color="white"
            placeholder={t('SEARCH_BARBER')}
            value={query}
            mb={!isDesktop && theme.pxToRem(16)}
            onChange={(event) => onSearch(event.target.value)}
            leftElement={<Search color={colors.primary} />}
          />

          {isDesktop ? (
            <Table
              isSelectableRow
              onSelect={(d) => {
                setLoading(true)
                mutate.mutate(d)
              }}
              data={unitysList?.filtered || []}
              loading={isLoading}
              columns={COLUMNS}
              widthOptions="100%"
            />
          ) : (
            <MobileList
              data={unitysList?.filtered || []}
              loading={isLoading}
              renderItem={({ item, index }) => (
                <TeamCard item={item} index={index} onSelect={(d) => mutate.mutate(d)} />
              )}
            />
          )}
          {loading && <Loader />}
        </Box>
      ) : (
        <FeatureBlockedMessage icon="Shop" title="UNITY_BLOCKED" />
      )}
      <UnityModal isModalOpen={open} onClose={closeModal} length={unitysList?.data?.length} />
    </Layout>
  )
}

UnitysScreen.path = '/unidades'
UnitysScreen.title = 'Unidades'
UnitysScreen.secured = true
