import { t } from 'services/translation'
import * as Yup from 'yup'
import { Text } from 'components/atoms'
import { pxToRem } from 'styles/metrics'

export const data = (producer, update) => {
  const arr = [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      noBorder: true,
      label: 'CLIENT_NAME',
      placeholder: t('CLIENT_NAME'),
    },
    {
      id: 'price',
      name: 'price',
      type: 'text',
      noBorder: true,
      label: 'SALE_PRICE',
      placeholder: '0,00',
      mask: 'CURRENCY_MASK',
      leftElement: (
        <Text color="textLight" kind="medium" mt={pxToRem(2)} width={pxToRem(40)}>
          {t('CURRENCY')}
        </Text>
      ),
    },
    {
      id: 'cost_price',
      name: 'cost_price',
      type: 'text',
      noBorder: true,
      label: 'COST_PRICE',
      placeholder: '0,00',
      mask: 'CURRENCY_MASK',
      leftElement: (
        <Text color="textLight" kind="medium" mt={pxToRem(2)} width={pxToRem(40)}>
          {t('CURRENCY')}
        </Text>
      ),
    },
    {
      id: 'minimum_quantity',
      name: 'minimum_quantity',
      type: 'number',
      noBorder: true,
      label: 'MINIMUM_ALERT',
      placeholder: 3,
    },
    {
      id: 'percentage',
      name: 'percentage',
      mask: 'PERCENTAGE_MASK',
      noBorder: true,
      label: 'Percentual padrão de comissão',
      placeholder: '30%',
    },
    {
      id: 'codigo_ncm',
      name: 'codigo_ncm',
      type: 'text',
      noBorder: true,
      label: 'Código NCM',
      placeholder: '3305.10.00',
    },
  ]
  if (!update) {
    arr.push({
      id: 'quantity',
      name: 'quantity',
      type: 'number',
      noBorder: true,
      label: 'QUANTITY_',
      placeholder: 10,
    })
  }
  if (producer === 'Outro') {
    arr.push({
      id: 'producer',
      name: 'producer',
      type: 'text',
      noBorder: true,
      label: 'PRODUCER',
    })
  }
  return arr
}

export const validation = Yup.object().shape({
  name: Yup.string().min(3, t('PRODUCT_NAME_SIZE')).required(t('PRODUCT_NAME_REQUIRED')),
  price: Yup.string().required(t('PRODUCT_PRICE_REQUIRED')),
  cost_price: Yup.string().required(t('PRODUCT_PRICE_REQUIRED')),
})

export const initial = {
  name: '',
  price: '',
  cost_price: '',
  minimum_quantity: 3,
}

export const types = [
  {
    value: 'Vitrine',
  },
  {
    value: 'Estoque interno',
  },
]
