export const internalMenuOptions = [
  {
    id: 0,
    section: 'Reports',
    options: [
      {
        id: '00',
        title: 'Home',
        icon: 'Calendar',
        path: '/internal-adm/home',
      },
      {
        id: '01',
        title: 'Por Data',
        icon: 'Calendar',
        path: '/internal-adm/new-barbershops',
      },
      {
        id: '02',
        title: 'Ativas',
        icon: 'AddBarber',
        path: '/internal-adm/active',
      },
      {
        id: '03',
        title: 'Inativas',
        icon: 'DeleteBarberConfirmation',
        path: '/internal-adm/inactive',
      },
      {
        id: '04',
        title: 'Por Agendamentos',
        icon: 'Calendar',
        path: '/internal-adm/barbershops-by-appointments',
      },
      {
        id: '05',
        title: 'Últimos meses',
        icon: 'Calendar',
        path: '/internal-adm/new-barbershops-last-months',
      },
      {
        id: '06',
        title: 'Novos Usuários',
        icon: 'Calendar',
        path: '/internal-adm/new-registers',
      },
      {
        id: '07',
        title: 'Gerenciamento de Usuários',
        icon: 'People',
        path: '/internal-adm/users-management',
      },
    ],
  },
]

export const menuOptions = (permissions, type, user_id, barbershop) => {
  const options = [
    {
      id: 0,
      section: 'Agendamentos',
      permission: permissions?.appointment === 'blocked' && permissions?.historic === 'blocked' ? 'blocked' : 'edit',
      options: [
        {
          id: '01',
          title: 'AGENDA',
          icon: 'Calendar',
          path: '/agenda',
          permission: permissions?.appointment,
        },
        {
          id: '02',
          title: 'Histórico',
          icon: 'Historic',
          path: '/historico',
          permission: permissions?.historic,
        },
        {
          id: '03',
          title: 'Comandas',
          icon: 'Command',
          path: '/comandas',
          permission: user_id === 240194 ? 'edit' : 'blocked',
        },
      ],
    },
    {
      id: 1,
      section: 'Pontos para clientes',
      permission: permissions?.appointment === 'blocked' && permissions?.historic === 'blocked' ? 'blocked' : 'edit',
      options: [
        {
          id: '01',
          title: 'Programa de Fidelidade',
          icon: 'Star',
          path: '/best-coins',
          permission: permissions?.appointment,
        },
      ],
    },
    ...(barbershop?.signature_club_enable
      ? [
          {
            id: 1.1,
            section: 'CLUBE DE ASSINATURA',
            permission:
              permissions?.appointment === 'blocked' && permissions?.historic === 'blocked' ? 'blocked' : 'edit',
            options: [
              {
                id: '12.2',
                title: 'Planos',
                icon: 'People',
                path: '/planos-assinatura',
                permission: permissions?.signature,
              },
              {
                id: '35',
                title: 'Resumo',
                icon: 'CreditCard',
                path: '/relatorio-assinaturas',
                premium: true,
                permission: permissions?.finance,
              },
              {
                id: '36',
                title: 'Extrato',
                icon: 'CreditCard',
                path: '/relatorio-assinaturas-total',
                premium: true,
                permission: permissions?.finance,
              },
              {
                id: '12',
                title: 'Assinantes',
                icon: 'People',
                path: '/assinantes-novo',
                permission: permissions?.signature,
              },
              {
                id: '14',
                title: 'Frequência',
                icon: 'People',
                path: '/frequencia-assinantes',
                permission: permissions?.signature,
              },
              {
                id: '120',
                title: 'Saldo e saque',
                icon: 'Money',
                path: '/saldo',
                permission: permissions?.balance_withdraw,
              },
            ],
          },
        ]
      : []),
    {
      id: 2,
      section: 'FINANCEIRO',
      permission: permissions?.cashier !== 'blocked' || permissions?.finance !== 'blocked' ? 'edit' : 'blocked',
      options: [
        {
          id: '24',
          title: 'CAIXA',
          icon: 'Cashier',
          path: '/caixa',
          premium: true,
          options: [
            {
              id: '24.1',
              title: 'Diário',
              path: '/caixa',
              premium: true,
              permission: !permissions?.cashier && type !== 'barbershop' ? 'blocked' : permissions?.cashier,
            },
            {
              id: '24.2',
              title: 'Listagem Geral',
              path: '/relatorio-de-caixas',
              premium: true,
              permission: !permissions?.cashier && type !== 'barbershop' ? 'blocked' : permissions?.cashier,
            },
          ],
        },
        {
          id: '21',
          title: 'Dashboard',
          icon: 'ReportChart',
          path: '/financeiro',
          premium: true,
          permission: permissions?.finance,
        },
        {
          id: '24',
          title: 'EXTRACT',
          icon: 'ExtractArrows',
          path: '/extrato-simplificado',
          premium: true,
          options: [
            {
              id: '1',
              title: 'Simples',
              path: '/extrato-simplificado',
              premium: true,
              permission: permissions?.finance,
            },
            {
              id: '2',
              title: 'Detalhado',
              path: '/extrato-detalhado',
              premium: true,
              permission: permissions?.finance,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      section: 'RELATÓRIOS',
      permission: permissions?.cashier !== 'blocked' || permissions?.finance !== 'blocked' ? 'edit' : 'blocked',
      options: [
        {
          id: '31',
          title: 'COMMISSIONS',
          icon: 'SvgBarbers',
          path: '/relatorio-barbeiros',
          premium: true,
          permission: permissions?.finance,
        },
        {
          id: '33',
          title: 'Produtos e Serviços',
          icon: 'Scissors',
          path: '/relatorio-servicos',
          premium: true,
          permission: permissions?.finance,
        },
        {
          id: '32',
          title: 'CLIENTS',
          icon: 'People',
          path: 'clients',
          premium: true,
          options: [
            {
              id: '1',
              title: 'Geral',
              path: '/relatorio-clientes',
              premium: true,
              permission: permissions?.finance,
            },
            {
              id: '2',
              title: 'Frequência',
              path: '/relatorio-frequencia-clientes',
              premium: true,
              permission: permissions?.finance,
            },
            {
              id: '3',
              title: 'Novos clientes',
              path: '/relatorio-novos-clientes',
              premium: true,
              permission: permissions?.finance,
            },
            {
              id: '4',
              title: 'Primeiro agendamento',
              path: '/relatorio-clientes-primeiro-agendamento',
              premium: true,
              permission: permissions?.finance,
            },
            {
              id: '5',
              title: 'Último agendamento',
              path: '/relatorio-clientes-ultimo-agendamento',
              premium: true,
              permission: permissions?.finance,
            },
          ],
        },
        {
          id: '34',
          title: 'Formas de Pagamento',
          icon: 'CreditCard',
          path: '/relatorio-pagamentos',
          premium: true,
          permission: permissions?.finance,
        },
      ],
    },
    {
      id: 2,
      section: 'INDICADORES',
      permission: permissions?.finance !== 'blocked' ? 'edit' : 'blocked',
      options: [
        {
          id: '31',
          title: 'Taxa de ocupação',
          icon: 'Report',
          path: '/taxa-ocupacao',
          permission: permissions?.finance,
        },
        {
          id: '33',
          title: 'Ticket médio',
          icon: 'Money',
          path: '/ticket-medio',
          permission: permissions?.finance,
        },
        {
          id: '34',
          title: 'Metas da equipe',
          icon: 'ReportChart',
          path: 'goals',
          permission: permissions?.finance,
          options: [
            {
              id: '1',
              title: 'Resumo',
              path: '/metas-resumo-v2',
              premium: true,
              permission: permissions?.finance,
            },
            {
              id: '1',
              title: 'Gerenciamento',
              path: '/metas-barbeiro-v2',
              premium: true,
              permission: permissions?.finance,
            },
          ],
        },
      ],
    },
    {
      id: 1,
      section: 'Cadastros',
      options: [
        {
          id: '11',
          title: 'CLIENTS',
          icon: 'People',
          path: '/clientes',
          permission: permissions?.clients,
        },
        {
          id: '12',
          title: 'TEAM',
          icon: 'PersonBarber',
          path: '/equipe',
          permission: permissions?.team,
        },
        {
          id: '13',
          title: 'SERVICES',
          icon: 'Scissors',
          path: '/servicos',
          permission: permissions?.services,
        },
        {
          id: '14',
          title: 'Pacote de serviços',
          icon: 'Scissors',
          path: '/pacote-de-servicos',
          permission: permissions?.services,
        },
        {
          id: '15',
          title: 'ADDITIONAL_PRODUCTS',
          icon: 'Shampoo',
          path: '/produtos-adicionais',
          premium: true,
          permission: permissions?.additional_products,
        },
        {
          id: '17',
          title: 'Formas de Pagamento',
          icon: 'CreditCard',
          path: '/meios-de-pagamento',
          permission: permissions?.finance,
        },
        {
          id: '16',
          title: 'BLOCKED_HOURS',
          icon: 'Historic',
          path: '/horarios-bloqueados',
          permission: permissions?.blocked_hours,
        },
      ],
    },
    {
      id: 1,
      section: 'Gerenciar',
      options: [
        ...(type === 'barbershop' || permissions?.configurations
          ? [
              {
                id: '22',
                title: 'Perfil da Barbearia',
                icon: 'Shop',
                path: '/perfil/dados',
                permission: 'edit',
              },
            ]
          : []),
        {
          id: '18',
          title: 'UNITYS',
          icon: 'Shop',
          path: '/unidades',
          premium: true,
          permission: permissions?.unitys,
        },
        {
          id: '25',
          title: 'Configurações',
          icon: 'Gear',
          path: '/configuracoes/tempo-cancelamento',
          premium: true,
          permission: permissions?.configurations,
        },
        {
          id: '29',
          title: 'Atualizações e tutoriais',
          icon: 'Youtube',
          path: '/atualizacoes',
        },
        {
          id: '20',
          title: 'RATINGS',
          icon: 'Star',
          path: '/avaliacoes',
          permission: 'edit',
        },
        {
          id: '26',
          title: 'Anúncios',
          icon: 'PremiumCrown',
          path: '/ads',
        },
        {
          id: '21',
          title: 'CONTENTS',
          icon: 'Youtube',
          path: '/conteudos',
        },
        {
          id: '22',
          title: 'LOGOUT',
          icon: 'Logout',
          path: '/perfil/sair',
          permission: 'only_read',
        },
      ],
    },
  ]

  if (type === 'collaborator') {
    options[4]?.options?.splice(4, 0, {
      id: '15',
      title: 'NOTIFICATIONS_TITLE',
      icon: 'Notification',
      path: '/notificacoes',
      premium: true,
      permission: permissions?.notifications,
    })
    options[4]?.options?.splice(5, 0)
  }

  if (barbershop?.nf_module_active) {
    options[3]?.options?.splice(3, 0, {
      id: '12',
      title: 'Nota Fiscal',
      icon: 'Document',
      path: 'nf',
      permission: permissions?.nota_fiscal || permissions?.finance,
      options: [
        {
          id: '40',
          title: 'Nota fiscal - Histórico',
          icon: 'Cashier',
          path: '/nota-fiscal/historico',
          premium: true,
          permission: permissions?.nota_fiscal || permissions?.finance,
        },
        {
          id: '41',
          title: 'Configurações',
          icon: 'ReportChart',
          path: '/nota-fiscal/configuracoes',
          premium: true,
          permission: permissions?.finance,
        },
        {
          id: '42',
          title: 'Dados da Equipe',
          icon: 'Team',
          path: '/nota-fiscal/dados-da-equipe',
          premium: true,
          permission: permissions?.finance,
        },
      ],
    })
  }
  return options
}
