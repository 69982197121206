import React, { useState } from 'react'
import { Text, Icon, TextInput } from 'components/atoms'
import { Box, useTheme, Flex, Spinner } from '@chakra-ui/react'
import { formatPrice } from 'utils/price'
import { useBarbershop } from 'services/hooks'
import { useMutation } from 'react-query'
import { clearCurrency } from 'utils/clearCurrency'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { useAppointmentStore } from 'services/stores'
import { pxToRem } from 'styles/metrics'

let previousValue = null

export const AdditionalProductCard = ({ product, onClick, br, reopened, index, ...props }) => {
  const theme = useTheme()
  const [edit, setEdit] = useState(false)
  const { additionalProductsAppointmentList, setAdditionalProductsAppointmentList } = additionalProductsStore()
  const { updateProduct } = useBarbershop()

  const { isLoadingAdditionalProduct, setLoadingAdditionalProduct } = useAppointmentStore()

  const mutate = useMutation('update-product', (params) => updateProduct(params), {
    onSettled: () => setLoadingAdditionalProduct(false),
  })

  const onChangeValue = (value) => {
    const changed = additionalProductsAppointmentList?.map((item) => {
      if (item?.id === product?.id) {
        return {
          ...item,
          item_value: value,
        }
      }
      return item
    })
    setAdditionalProductsAppointmentList(changed)
  }

  return (
    <Box
      marginBottom={3}
      display="flex"
      flexDir="row"
      w="100%"
      h={theme.pxToRem(56)}
      background="cardBackground"
      alignItems="center"
      justifyContent="flex-start"
      px={theme.pxToRem(16)}
      borderRadius={br ? theme.pxToRem(br) : theme.pxToRem(16)}
      borderWidth={1}
      borderColor="modalBorder"
      {...props}
    >
      <Flex w="100%" flexDir="row" alignItems="center" justifyContent="space-between">
        <Text
          maxW={theme.pxToRem(260)}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          kind="bold"
          fontSize={theme.pxToRem(14)}
        >
          {product?.name}
        </Text>

        <Flex flexDir="row" alignItems="center" justifyContent="flex-end" gap={pxToRem(16)}>
          {!edit ? (
            <Text color={props?.isDiscount && 'red'} kind="bold" fontSize={theme.pxToRem(14)}>
              {formatPrice(Number(product.value || product?.price))}
            </Text>
          ) : (
            <TextInput
              w="25%"
              containerprops={{ h: 'fit-content' }}
              py={0}
              fontSize={pxToRem(14)}
              placeholder="0,00"
              mask="CURRENCY_MASK"
              isReadOnly={false}
              value={additionalProductsAppointmentList[index]?.item_value}
              onChange={(e) => onChangeValue(clearCurrency(e.target.value))}
            />
          )}

          {reopened && (
            <>
              {!isLoadingAdditionalProduct && (
                <Icon
                  mr="6px"
                  name={edit ? 'Check' : 'Pencil'}
                  size={16}
                  color="white"
                  onClick={() => {
                    const currentValue = additionalProductsAppointmentList[index]?.item_value

                    if (!edit) {
                      previousValue = currentValue
                    }

                    if (edit) {
                      if (previousValue !== currentValue) {
                        const params = {
                          id: product?.id,
                          product_value: currentValue,
                        }

                        setLoadingAdditionalProduct(true)
                        mutate.mutate({ params })
                      }
                    }

                    setEdit(!edit)
                  }}
                  cursor="pointer"
                />
              )}

              {isLoadingAdditionalProduct && <Spinner color="white" />}
            </>
          )}

          {onClick && (
            <Icon
              cursor="pointer"
              onClick={onClick}
              name="Trash"
              color={isLoadingAdditionalProduct ? 'white' : 'baseRed'}
              size={16}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
