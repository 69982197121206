import { Button } from 'components/atoms'
import { useRef } from 'react'
import { pxToRem } from 'styles/metrics'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Box,
  Text,
  Flex,
  Avatar,
} from '@chakra-ui/react'
import { formatCurrency } from 'utils/formatCurrency'

export const ConfirmationModal = ({ data, openButtonRender, onConfirm }) => {
  const cancelRef = useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {openButtonRender?.({ isOpen, onOpen, onClose })}

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent bg="background" borderRadius={pxToRem(12)} p={pxToRem(16)}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
              Confira as Informações Antes de Prosseguir
            </AlertDialogHeader>

            <AlertDialogBody color="white">
              <Box
                p={pxToRem(16)}
                border="1px solid border"
                borderRadius={pxToRem(12)}
                bg="background2"
                shadow="0px 0px 8px #bebcb4"
              >
                <Text fontWeight="bold" mb={pxToRem(4)}>
                  Clube De Assinatura
                </Text>

                <Flex align="center" p={pxToRem(8)}>
                  <Avatar src={data?.client?.profile_image_url} />
                  <Box ml={pxToRem(12)}>
                    <Text fontSize={pxToRem(14)} fontWeight="bold">
                      {data?.client?.name}
                    </Text>
                    <Text fontSize={pxToRem(12)}>{data?.plan?.name}</Text>
                    <Text fontSize={pxToRem(12)}>{formatCurrency(data?.plan?.value)}</Text>
                    <Text fontSize={pxToRem(12)}>Status: {data?.status === 'PENDING' ? 'Pendente' : 'Ativo'}</Text>
                  </Box>
                </Flex>

                <Flex w="100%" border="1px solid gray" my={pxToRem(8)} />

                <Flex align="center" justify="space-around" p={pxToRem(8)}>
                  <Box>
                    <Text fontSize={pxToRem(14)} fontWeight="bold">
                      Primeira Cobrança
                    </Text>
                    <Text fontSize={pxToRem(14)}>
                      {data?.dueDate?.split('-')[2]}/{data?.dueDate?.split('-')[1]}/{data?.dueDate?.split('-')[0]}
                    </Text>
                  </Box>

                  <Flex h={pxToRem(44)} border="1px solid gray" />

                  <Box>
                    <Text fontSize={pxToRem(14)} fontWeight="bold">
                      Próximas Cobranças
                    </Text>
                    <Text fontSize={pxToRem(14)}>Todo dia {Number(data?.dueDate?.split('-')[2])} do mês</Text>
                  </Box>
                </Flex>
              </Box>

              <Box mt={pxToRem(24)}>
                <Text fontSize={pxToRem(14)} fontWeight="bold">
                  IMPORTANTE
                </Text>
                <Text fontSize={pxToRem(14)} textAlign="justify">
                  - A primeira cobrança será feita dia {data?.dueDate?.split('-')[2]}/{data?.dueDate?.split('-')[1]}/
                  {data?.dueDate?.split('-')[0]} e as próximas cobranças serão feitas todo dia{' '}
                  {Number(data?.dueDate?.split('-')[2])} do mês.
                </Text>
                <Text fontSize={pxToRem(14)} textAlign="justify" mt="6px">
                  -{' '}
                  {data?.status === 'PENDING'
                    ? 'Devido ao status pendente, cliente só conseguirá agendar depois de realizar primeiro pagamento'
                    : 'Devido ao status Ativo, cliente já conseguirá realizar agendamentos'}
                  .
                </Text>
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onConfirm?.()
                  onClose()
                }}
                ml={3}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
