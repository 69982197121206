import React, { useState, useMemo } from 'react'
import {
  Layout,
  Table,
  Text,
  ClientDetailModal,
  Link,
  TextInput,
  Button,
  SelectFilter,
  SendAdviceModal,
} from 'components'
import { Center, Flex, Spinner, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore, useCustomerStore } from 'services/stores'
import { parseISO, format, startOfMonth, endOfMonth, differenceInDays } from 'date-fns'
import './hideScrollbar.css'
import { formatPhone } from 'utils/phone-formater'
import { useMutation, useQuery } from 'react-query'
import { useClientsReport, usePermissionCollaborator, useTeam } from 'services/hooks'

export const FirstAppointmentClientsReport = (props) => {
  const { userData } = useBarbershopStore()
  const toast = useToast()
  const { t } = useTranslation()
  const { selectedCustomer, setSelectedCustomer } = useCustomerStore()
  const [isCustomerDetailModalOpen, setCustomerDetailModal] = useState(false)
  const permission = usePermissionCollaborator('notifications')
  const clients = usePermissionCollaborator('clients')
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const [needRefetch, setNeedRefetch] = useState(false)
  const { getFirstAppointmentClientsReport, exportFirstAppointmentClientsReport } = useClientsReport()
  const [barberFilter, setBarberFilter] = useState('all')
  const [sendNotificationModalOpen, setSendNotificationModalOpen] = useState(false)
  const [nameFilter, setNameFilter] = useState('')
  const [clientsList, setClientsList] = useState([])
  const [skip, setSkip] = useState(0)

  const { getOnlyBarbersTeam } = useTeam()

  const barbershop_id = useMemo(() => {
    return userData?.barbershop_id || userData?.barbershop?.id || userData?.id
  }, [userData])

  const { refetch, isLoading, isRefetching } = useQuery(
    ['get-first-appointment-clients-report', skip],
    () => getFirstAppointmentClientsReport({ barbershop_id, startDate, endDate, skip }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        let newList = data
        if (skip !== 0) {
          newList = [...clientsList, ...data]
        }
        setClientsList(newList)
        setNeedRefetch(false)
      },
    }
  )

  const { data: barbers } = useQuery('get-only-barbers-team', () => getOnlyBarbersTeam(barbershop_id), {
    refetchOnWindowFocus: false,
  })

  const mutate = useMutation(
    ['export-first-appointment-clients-report-excel'],
    () => exportFirstAppointmentClientsReport({ barbershop_id, startDate, endDate }),
    {
      onSuccess: (value) => {
        const url = URL.createObjectURL(
          new Blob([value], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        window.open(url)
      },
    }
  )

  const columns = [
    {
      key: 'client',
      label: 'DADOS DO CLIENTE',
      width: 220,
      render: (item) => {
        return (
          <Flex flexDir="column">
            <Text noOfLines={1}>{item?.client_name}</Text>
            <Link href={`https://api.whatsapp.com/send?1=pt_BR&phone=${item?.client_phone}`} target="_blank">
              {formatPhone(item?.client_phone, userData)}
            </Link>
          </Flex>
        )
      },
    },
    {
      key: 'created_at',
      label: 'DATA DE CADASTRO',
      width: 140,
      render: (item) => <Text>{format(parseISO(item?.user_created_at), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'first_appointment_date',
      label: '1º AGENDAMENTO',
      width: 140,
      render: (item) => {
        if (!item?.first_appointment_date) {
          return <Text color="grey">Nenhum agendamento</Text>
        } else {
          return <Text>{format(parseISO(item?.first_appointment_date), 'dd/MM/yyyy')}</Text>
        }
      },
    },
    {
      key: 'barber_name',
      label: 'BARBEIRO',
      width: 140,
      render: (item) => <Text>{item?.barber_name}</Text>,
    },
    {
      key: 'services',
      label: 'SERVIÇOS',
      render: (item) => <Text>{item?.services?.map((service) => service?.service_name).join(', ')}</Text>,
    },
  ]

  const handleSelectCustomer = (customer) => {
    if (clients !== 'blocked') {
      setSelectedCustomer(customer)
      setCustomerDetailModal(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const filteredData = useMemo(() => {
    if (barberFilter !== 'all' && !isNaN(parseInt(barberFilter))) {
      const firstFilter = clientsList?.filter((item) => {
        return item?.barber_id === parseInt(barberFilter)
      })
      if (nameFilter?.length > 0) {
        return firstFilter?.filter((item) => item?.client_name.toLowerCase().includes(nameFilter.toLowerCase()))
      }
      return firstFilter
    }
    if (nameFilter?.length > 0) {
      return clientsList?.filter((item) => item?.client_name.toLowerCase().includes(nameFilter.toLowerCase()))
    }
    return clientsList
  }, [clientsList, barberFilter, nameFilter])

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Relatório de primeiro agendamento"
      widthTitle="100%"
      iconColor="black"
      iconSecondActionText={'NotificationSmall'}
      secondActionText={t('SEND_NOTIFICATION')}
      secondActionClick={permission === 'edit' ? () => setSendNotificationModalOpen(true) : null}
      thirtyClick={{
        icon: 'Excel',
        iconColor: 'black',
        onClick: () => mutate.mutate(),
        text: 'Exportar em excel',
        isLoading: mutate.isLoading,
      }}
    >
      <Flex w="100%">
        <Flex>
          <TextInput
            noBorder
            type="date"
            name="start_date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
              setSkip(0)
              setNeedRefetch(true)
            }}
            label={t('START')}
            padding="5%"
            width={'90%'}
          />
          <TextInput
            noBorder
            type="date"
            name="end_date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
              setSkip(0)
              setNeedRefetch(true)
            }}
            label={t('END')}
            padding="5%"
            width={'90%'}
          />
        </Flex>
        <Button
          isLoading={isRefetching || isLoading}
          icon="Search"
          size="small"
          iconColor="black"
          mt="27px"
          onClick={() => {
            if (differenceInDays(parseISO(endDate), parseISO(startDate)) > 30) {
              toast({
                title: 'Período máximo de 1 mês',
                status: 'error',
                duration: 4000,
                isClosable: true,
              })
              return
            }
            refetch()
          }}
        >
          Pesquisar
        </Button>
        <SelectFilter
          ml={4}
          selW={220}
          upLabel="Filtrar por barbeiro"
          options={barbers?.map((barber) => ({ value: barber?.id, name: barber?.name }))}
          value={barberFilter}
          onChange={(e) => {
            setBarberFilter(e)
          }}
        />
        <TextInput
          value={nameFilter}
          label="Filtrar por nome do cliente"
          placeholder="Nome do cliente"
          type="text"
          onChange={(e) => setNameFilter(e.target.value)}
          onWheel={(e) => e.target.blur()}
          padding={2}
        />
      </Flex>
      {isLoading && filteredData?.length === 0 && (
        <Center w="100%">
          <Spinner mt={12} color="textLight" />
        </Center>
      )}
      {needRefetch && (
        <Center w="100%">
          <Text mt={12} mb={12}>
            Clique novamente em Pesquisar
          </Text>
        </Center>
      )}
      {!isLoading && filteredData?.length === 0 && !needRefetch && (
        <Center w="100%">
          <Text mt={12}>Nenhum resultado encontrado</Text>
        </Center>
      )}
      {filteredData?.length > 0 && !needRefetch && (
        <Flex w="100%" flexDir="column" mt={2}>
          <Table
            data={filteredData}
            columns={columns}
            isSelectableRow
            onSelect={(item) => handleSelectCustomer(item)}
          />
          {filteredData?.length > 40 && (
            <Button
              isLoading={isRefetching || isLoading}
              size="small"
              kind="outline-primary"
              onClick={() => setSkip(skip + 50)}
              mb={12}
            >
              Carregar mais dados da lista
            </Button>
          )}
        </Flex>
      )}
      {isCustomerDetailModalOpen && (
        <ClientDetailModal
          isModalOpen={isCustomerDetailModalOpen}
          onClose={() => {
            setCustomerDetailModal(false)
          }}
          customerData={selectedCustomer}
        />
      )}
      {sendNotificationModalOpen && (
        <SendAdviceModal
          isModalOpen={sendNotificationModalOpen}
          onClose={() => {
            setSendNotificationModalOpen(false)
          }}
          clients={filteredData}
        />
      )}
    </Layout>
  )
}

FirstAppointmentClientsReport.path = '/relatorio-clientes-primeiro-agendamento'
FirstAppointmentClientsReport.title = 'Relatório Clientes Primeiro Agendamento'
FirstAppointmentClientsReport.secured = true
