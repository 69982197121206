import create from 'zustand'
import { t } from 'services/translation'
import { useAppointmentDetailStore } from './appointmentDetail'
import { additionalProductsStore } from './additionalProducts'

export const useAppointmentStore = create((set, get) => ({
  isLoadingService: false,
  setLoadingService: (isLoadingService) => set({ isLoadingService }),

  isLoadingAdditionalProduct: false,
  setLoadingAdditionalProduct: (isLoadingAdditionalProduct) => set({ isLoadingAdditionalProduct }),

  selectedAppointment: null,
  setSelectedAppointment: (selectedAppointment) => set({ selectedAppointment }),
  signature: false,
  setSignature: (signature) => set({ signature }),

  packageService: false,
  setPackageService: (packageService) => set({ packageService }),

  packageSell: false,
  setPackageSell: (packageSell) => set({ packageSell }),
  signatureId: false,
  setSignatureId: (signatureId) => set({ signatureId }),

  type: 'normal',
  setType: (type) => set({ type }),

  scheduleType: 'normal',
  setScheduleType: (scheduleType) => set({ scheduleType }),

  barberSell: false,
  setBarberSell: (barberSell) => set({ barberSell }),

  costPrice: false,
  setCostPrice: (costPrice) => set({ costPrice }),

  client: null,
  setClient: (client) => set({ client }),

  barber: null,
  setBarber: (barber) => set({ barber }),

  service: null,
  setService: (service) => set({ service }),

  serviceArray: [],
  setServiceArray: (serviceArray) => set({ serviceArray }),

  date: null,
  setDate: (date) => set({ date }),

  simpleDate: null,
  setSimpleDate: (simpleDate) => set({ simpleDate }),

  time: null,
  setTime: (time) => set({ time }),

  finishHour: null,
  setFinishHour: (finishHour) => set({ finishHour }),

  loading: false,
  setLoading: (loading) => set({ loading }),

  barbersList: [],
  setBarbersList: (barbersList) => set({ barbersList }),

  clientsList: [],
  setClientsList: (clientsList) => set({ clientsList }),

  availableTimesList: [],
  setAvailableTimesList: (availableTimesList) => set({ availableTimesList }),

  appointmentsList: [],
  setAppointmentsList: (appointmentsList) => set({ appointmentsList }),

  serviceList: [],
  setServiceList: (serviceList) => set({ serviceList }),

  productsList: [],
  setProductsList: (productsList) => set({ productsList }),

  appointmentType: 'normal',
  setAppointmentType: (appointmentType) => set({ appointmentType }),

  onReopen: () => {
    set({
      serviceArray:
        useAppointmentDetailStore.getState()?.selectedAppointment?.product ||
        useAppointmentDetailStore.getState()?.selectedAppointment?.services,
      productsList: additionalProductsStore.getState()?.additionalProductsAppointmentList,
    })
    useAppointmentDetailStore.setState({
      paymentMethod: useAppointmentDetailStore.getState()?.selectedAppointment?.payment_method_choosed,
    })
    useAppointmentDetailStore.setState({
      discount: {
        discount: useAppointmentDetailStore.getState()?.selectedAppointment?.discount,
        discount_observation: useAppointmentDetailStore.getState()?.selectedAppointment?.discount_observation,
        discount_from_barber:
          useAppointmentDetailStore.getState()?.selectedAppointment?.discount_discounted_from_barber,
      },
    })
    useAppointmentDetailStore.setState({
      selectedAppointment: {
        ...useAppointmentDetailStore.getState()?.selectedAppointment,
        status: 'reopened',
      },
    })
  },

  sourcesList: [
    { id: 0, name: t('CLIENT_MOBILE_APP'), value: 'client_mobile_app' },
    {
      id: 1,
      name: t('BARBER_MOBILE_APP'),
      value: 'barber_mobile_app',
    },
    {
      id: 2,
      name: t('BARBERSHOP_MOBILE_APP'),
      value: 'barbershop_mobile_app',
    },
    {
      id: 3,
      name: t('BARBERSHOP_MOBILE_APP_WPP'),
      value: 'barbershop_mobile_app_wpp',
    },
    {
      id: 4,
      name: t('BARBERSHOP_MOBILE_APP_PRESENTIAL'),
      value: 'barbershop_mobile_app_presential',
    },
    {
      id: 3,
      name: t('BARBERSHOP_MOBILE_APP_PHONE_CALL'),
      value: 'barbershop_mobile_app_phone_call',
    },
  ],
  setSourceList: (sourcesList) => set({ sourcesList }),

  source: null,
  setSource: (source) => set({ source }),
  clearState: () =>
    set({
      type: 'normal',
      client: null,
      barber: null,
      service: null,
      date: null,
      time: null,
      finishHour: null,
      serviceArray: [],
      discount: null,
    }),
}))
