import axios from 'axios'
import { getData } from 'services/storage'

const new_api = axios.create({
  // baseURL: 'http://localhost:3001',
  baseURL: 'https://bestbarbers-api-braba-production-5915.up.railway.app',
})

new_api.interceptors.request.use(
  async (config) => {
    const new_api_token = await getData('@BestBarbers:new_token')
    if (new_api_token) {
      config.headers.Authorization = `Bearer ${new_api_token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export { new_api }
